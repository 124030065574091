import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { format } from "date-fns";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import payout_request from "../../../../rest_api/payout_request";
import { useNavigate } from "react-router-dom";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";

export default function StandardPayoutConfirmationPage(props) {
  const {
    beneficiary,
    setView,
    setBeneficiary,
    open_snackbar,
    snackbar_severity,
    snackbar_message,
  } = props;
  const [txnType, setTxnType] = React.useState(
    beneficiary.accountType === "UPI" ||
      (beneficiary.upi_id && beneficiary.upi_id.length > 0)
      ? "UPI"
      : "IMPS"
  );
  const [amount, setAmount] = React.useState("");
  const [mpin, setMpin] = React.useState("");
  const [error, setError] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const processTransaction = async (event) => {
    event.preventDefault();

    if (
      amount &&
      amount !== "" &&
      mpin &&
      mpin !== "" &&
      parseInt(amount) > 99 &&
      (txnType !== "RTGS" || parseInt(amount) > 199999) &&
      mpin.length === 4 &&
      beneficiary.account_name.length > 2 &&
      ((txnType === "UPI" &&
        beneficiary.upi_id &&
        beneficiary.upi_id.length > 5) ||
        (txnType !== "UPI" &&
          beneficiary.account_number &&
          beneficiary.account_number.length > 7 &&
          beneficiary.bank_name.length > 2 &&
          beneficiary.ifsc.length === 11))
    ) {
      try {
        setLoading(true);
        const data = {
          amount: amount,
          beneId: beneficiary.id,
          upi_id: beneficiary.upi_id,
          upi_name: beneficiary.account_name,
          service_type: txnType,
          mpin: mpin,
        };
        const res = await payout_request.payoutTransaction(data);
        setLoading(false);

        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              navigate("/portal/receipt", {
                replace: true,
                state: {
                  type: "Payout",
                  tid: res.tid,
                  data: [
                    { label: "Amount", value: amount },
                    { label: "Status", value: 1 },
                    { label: "UTR No.", value: res.utr_no },
                    { label: "TID", value: res.tid },
                    { label: "Mode", value: res.mode },
                    {
                      label: "Account/UPI",
                      value:
                        res.mode === "UPI"
                          ? beneficiary.upi_id
                          : beneficiary.account_number,
                    },
                    {
                      label: "Account Name",
                      value: res.account_name,
                    },
                    {
                      label: "Message",
                      value: res.message,
                    },
                  ],
                },
              });
            } else {
              open_snackbar(true);
              snackbar_severity("error");
              snackbar_message(res.message);
            }
          } else {
            open_snackbar(true);
            snackbar_severity("error");
            snackbar_message(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          open_snackbar(true);
          snackbar_severity("error");
          snackbar_message("No response key");
        }
      } catch (e) {
        setLoading(false);
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message(e.message);
      }
    } else if (
      amount === null ||
      amount === "" ||
      amount === undefined ||
      parseInt(amount) < 100 ||
      (txnType === "RTGS" && parseInt(amount) < 200000)
    ) {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message("Please enter valid amount.");
      setError("amount");
    } else if (
      mpin === null ||
      mpin === "" ||
      mpin === undefined ||
      mpin.length !== 4
    ) {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message("Please enter valid 4 digit transaction PIN.");
      setError("MPIN");
    } else if (
      txnType === null ||
      txnType === "" ||
      txnType === undefined ||
      (txnType === "UPI" &&
        (beneficiary.upi_id === "" || beneficiary.upi_id === undefined)) ||
      (txnType !== "UPI" &&
        (beneficiary.account_number === "" ||
          beneficiary.account_number === undefined))
    ) {
      setError("");
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message("Please select valid transaction type.");
    } else {
      setError("");
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message("Please provide valid details.");
    }
  };

  return (
    <Box p={2}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => {
            setView("");
            setBeneficiary("");
          }}
        >
          <ArrowBackIcon />
        </IconButton>{" "}
        <Typography sx={{ fontWeight: 500 }}>
          {" "}
          Confirm Payout Account Details
        </Typography>
      </Box>
      <Box
        mt={2}
        px={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <BenekeyValue keyName={"Name"} value={beneficiary.account_name} />
        <BenekeyValue
          align={"right"}
          keyName={"Account/UPI Id"}
          value={
            beneficiary.account_number && beneficiary.account_number !== ""
              ? beneficiary.account_number
              : beneficiary.upi_id
          }
        />
      </Box>
      {beneficiary.bank_name && beneficiary.bank_name !== "" && (
        <Box
          mt={1}
          px={1}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <BenekeyValue keyName={"Bank Name"} value={beneficiary.bank_name} />
          <BenekeyValue
            keyName={"IFSC Code"}
            value={beneficiary.ifsc}
            align={"right"}
          />
        </Box>
      )}
      <Box
        mt={1}
        px={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <BenekeyValue
          keyName={"Created At"}
          value={format(new Date(beneficiary.createdAt), "dd-MM-yyyy, hh:mm a")}
        />
        <BenekeyValue
          keyName={"Status"}
          value={beneficiary.status === "1" ? "Active" : "Inactive"}
          align={"right"}
        />
      </Box>
      <Box mt={2} px={2}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          Transaction Type
        </Typography>
        <Box mt={1} sx={{ display: "flex" }}>
          {beneficiary.accountType === "UPI" ||
          (beneficiary.upi_id && beneficiary.upi_id.length > 0) ? (
            <StandardStyledButton
              variant="contained"
              size="small"
              onClick={() => setTxnType("UPI")}
              disabled={loading}
              customcolor={
                txnType === "UPI"
                  ? props.appData.buttonColor
                  : props.appData.buttonFontColor
              }
              customfontcolor={
                txnType === "UPI"
                  ? props.appData.buttonFontColor
                  : props.appData.buttonColor
              }
            >
              UPI
            </StandardStyledButton>
          ) : (
            <>
              <StandardStyledButton
                variant={txnType === "IMPS" ? "contained" : "text"}
                size="small"
                onClick={() => setTxnType("IMPS")}
                disabled={loading}
                customcolor={
                  txnType === "IMPS"
                    ? props.appData.buttonColor
                    : props.appData.buttonFontColor
                }
                customfontcolor={
                  txnType === "IMPS"
                    ? props.appData.buttonFontColor
                    : props.appData.buttonColor
                }
              >
                IMPS
              </StandardStyledButton>
              <StandardStyledButton
                variant={txnType === "NEFT" ? "contained" : "text"}
                size="small"
                sx={{mx:2}}
                onClick={() => setTxnType("NEFT")}
                disabled={loading}
                customcolor={
                  txnType === "NEFT"
                    ? props.appData.buttonColor
                    : props.appData.buttonFontColor
                }
                customfontcolor={
                  txnType === "NEFT"
                    ? props.appData.buttonFontColor
                    : props.appData.buttonColor
                }
              >
                NEFT
              </StandardStyledButton>
              <StandardStyledButton
                variant={txnType === "RTGS" ? "contained" : "text"}
                size="small"
                onClick={() => setTxnType("RTGS")}
                disabled={loading}
                customcolor={
                  txnType === "RTGS"
                    ? props.appData.buttonColor
                    : props.appData.buttonFontColor
                }
                customfontcolor={
                  txnType === "RTGS"
                    ? props.appData.buttonFontColor
                    : props.appData.buttonColor
                }
              >
                RTGS
              </StandardStyledButton>
            </>
          )}
        </Box>
        <Box mt={3} component={"form"}>
          <Box mt={1}>
            <StandardStyledTextfield
              onChange={(e) =>
                e.target.value.length <= 7 && setAmount(e.target.value)
              }
              value={amount}
              error={error && error === "amount"}
              type="number"
              fullWidth
              label="Amount"
            />
          </Box>
          <Box mt={2}>
            <StandardStyledTextfield
              onChange={(e) =>
                e.target.value.length <= 4 && setMpin(e.target.value)
              }
              value={mpin}
              error={error && error === "MPIN"}
              type="password"
              fullWidth
              label="Transction PIN"
            />
          </Box>
          <Box mt={1} sx={{ display: "flex", justifyContent: "end" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <StandardStyledButton
                onClick={processTransaction}
                p={"15px 16px"}
                variant="contained"
                disabled={loading}
                customcolor={props.appData.buttonColor}
                customfontcolor={props.appData.buttonFontColor}
                type={"submit"}
              >
                Confirm
              </StandardStyledButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function BenekeyValue(props) {
  const { keyName, value, align } = props;
  return (
    <Box flexGrow={1} p={1}>
      <Box sx={{ fontSize: "14px", fontWeight: "500", textAlign: align }}>
        {keyName}
      </Box>
      <Box sx={{ textAlign: align }}>{value}</Box>
    </Box>
  );
}
