import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { Chip, CircularProgress, IconButton } from "@mui/material";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import { useNavigate } from "react-router-dom";
import dmt_request from "../../../../../../rest_api/dmt_request";

export default function StandardBeneficiaryTable(props) {
  const navigate = useNavigate();

  const {
    rows,
    walletDetails,
    reloadBeneList,
    setReloadBeneList,
    open_snackbar,
    snackbar_severity,
    snackbar_message,
  } = props;

  const [selectBeneId, setSelectedBene] = React.useState("");
  const [isloading, setLoading] = React.useState(false);

  const delete_bene_handler = async (beneId) => {
    if (beneId) {
      const token = localStorage.getItem("token");
      const payload = {
        beneId: beneId,
        wallet: localStorage.getItem("wallet"),
      };
      setLoading(true);
      setSelectedBene(beneId);
      const res = await dmt_request.delete_beneficiary(payload, token);
      setLoading(false);
      setSelectedBene("");
      if (res) {
        if (res.isLoggedIn) {
          if (res.status === 1 && res.wallet.status === 1) {
            setReloadBeneList(!reloadBeneList);
            snackbar_message(res.wallet.message);
            open_snackbar(true);
            snackbar_severity("info");
          } else {
            snackbar_message(res.message);
            open_snackbar(true);
            snackbar_severity("error");
          }
        } else {
          snackbar_message(res.message);
          open_snackbar(true);
          snackbar_severity("error");
          localStorage.clear();
          navigate("/login");
        }
      } else {
        snackbar_message("Network error");
        open_snackbar(true);
        snackbar_severity("error");
      }
    } else {
      snackbar_message("Unable to delete beneficiary something went wrong");
      open_snackbar(true);
      snackbar_severity("error");
      setReloadBeneList(!reloadBeneList);
    }
  };

  const verificationHandler = async (
    beneId,
    bank,
    account,
    ifsc,
    bene_name
  ) => {
    if (beneId && bank && account && ifsc && bene_name) {
      try {
        const token = localStorage.getItem("token");
        const wallet = localStorage.getItem("wallet");
        const payload = {
          beneId: beneId,
          bank: bank,
          account: account,
          wallet: wallet,
          ifsc: ifsc,
          bene_name: bene_name,
        };
        setLoading(true);
        setSelectedBene(beneId);
        const res = await dmt_request.verify_beneficiary(payload, token);
        setLoading(false);
        setSelectedBene("");
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              if (res.payload.txn_status === "success") {
                snackbar_severity("info");
                snackbar_message(
                  res.payload.response +
                    " | Account Name: " +
                    res.payload.account_holder_name
                );
                open_snackbar(true);
                setReloadBeneList(!reloadBeneList);
              } else if (res.payload.txn_status === "pending") {
                snackbar_severity("warning");
                snackbar_message(res.payload.response);
                open_snackbar(true);
                setReloadBeneList(!reloadBeneList);
              } else {
                snackbar_severity("error");
                snackbar_message(res.payload.response);
                open_snackbar(true);
              }
            } else {
              snackbar_severity("error");
              snackbar_message(res.message);
              open_snackbar(true);
            }
          } else {
            snackbar_severity("error");
            snackbar_message(res.message);
            open_snackbar(true);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          snackbar_severity("error");
          snackbar_message(
            "No response. Please transaction history for status of this transaction"
          );
          open_snackbar(true);
        }
      } catch (e) {
        snackbar_severity("error");
        snackbar_message("An error occured {" + e.message + "}");
        open_snackbar(true);
      }
    } else if (beneId === null) {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message("Something went wrong. Please go back and try again.");
    }
  };

  return (
    <TableContainer>
      <Table aria-label="Standard Basic table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>NAME</TableCell>
            <TableCell>ACCOUNT</TableCell>
            <TableCell>BANK</TableCell>
            <TableCell>IFSC</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell>ACTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              hover
              key={"txn" + index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {isloading && selectBeneId === row.id ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    onClick={() => !isloading && delete_bene_handler(row.id)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ fontWeight: "600" }}>
                {row.is_verified && row.account_name && row.account_name !== ""
                  ? row.account_name
                  : row.bene_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.bene_account}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.bene_bank}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.bene_ifsc}
              </TableCell>
              <TableCell component="th" scope="row">
                {!row.is_verified ? (
                  selectBeneId === rows.id ? (
                    <CircularProgress />
                  ) : (
                    <Chip
                      clickable
                      sx={{
                        boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.2)",
                        bgcolor: "#ff9800 !important",
                        "& .MuiButtonBase-root:hover": {
                          backgroundColor: "#ff9800 !important",
                        },
                      }}
                      label="Unverified"
                      size="small"
                      onClick={() => {
                        const conf = window.confirm(
                          "Are you sure want to very this beneficiary?"
                        );

                        conf &&
                          verificationHandler(
                            row.id,
                            row.bene_bank,
                            row.bene_account,
                            row.bene_ifsc,
                            row.bene_name
                          );
                      }}
                    />
                  )
                ) : (
                  <Chip color="primary" label="Verified" size="small" />
                )}
              </TableCell>
              <TableCell component="th" scope="row">
                <StandardStyledButton
                  disabled={isloading}
                  customcolor={props.appData.buttonColor}
                  customfontcolor={props.appData.buttonFontColor}
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate("/portal/dmt/confirm", {
                      state: { ...row, ...walletDetails },
                    });
                  }}
                >
                  Pay
                </StandardStyledButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
