import { Box, Paper, Typography, Avatar } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function StandardFastagReceipt(props) {
  const { appData } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const payload = location.state.res;
  const billdetails = location.state.billdetails;

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Transaction Receipt
      </Typography>
      <Box mt={1}>
        <Paper
          sx={{
            flexGrow: 1,
            width: "100%",
            maxWidth: `${payload ? "480px" : "100%"}`,
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          {payload ? (
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  pt: 5,
                  pb: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: `${
                      payload.txn_status === "Success"
                        ? "#4a6ecc"
                        : payload.txn_status === "Failure"
                        ? "#b14e07"
                        : "#ee8031"
                    }`,
                  }}
                >
                  {payload.txn_status === "Success" ? (
                    <CheckIcon sx={{ fontSize: "40px" }} />
                  ) : payload.txn_status === "Failure" ? (
                    <CloseIcon sx={{ fontSize: "40px" }} />
                  ) : (
                    <InfoOutlinedIcon sx={{ fontSize: "40px" }} />
                  )}
                </Avatar>
                <Typography variant={"h5"} mt={2}>
                  ₹{payload.amount}
                </Typography>
                <Typography variant={"h6"} mt={2}>
                  {payload.txn_status === "Success"
                    ? "Successful"
                    : payload.txn_status}
                </Typography>
              </Box>
              <Box
                component={"div"}
                sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
              />
              <Box
                py={1}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>Service</Box>
                <Box sx={{ textAlign: "right" }}>{location.state.category}</Box>
              </Box>
              <Box
                component={"div"}
                sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
              />
              <Box
                py={1}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>Operator</Box>
                <Box sx={{ textAlign: "right" }}>{location.state.operator}</Box>
              </Box>
              <Box
                component={"div"}
                sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
              />
              {billdetails.customerName && (
                <>
                  <Box
                    py={1}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>Customer Name</Box>
                    <Box sx={{ textAlign: "right" }}>
                      {billdetails.customerName}
                    </Box>
                  </Box>
                  <Box
                    component={"div"}
                    sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
                  />
                </>
              )}

              <Box
                py={1}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>{"Vehicale Number"}</Box>
                <Box sx={{ textAlign: "right" }}>
                  {billdetails.vehicaleNumber}
                </Box>
              </Box>
              <Box
                component={"div"}
                sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
              />
  

              <Box
                py={1}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>{"Mobile Number"}</Box>
                <Box sx={{ textAlign: "right" }}>
                  {billdetails.number}
                </Box>
              </Box>
              <Box
                component={"div"}
                sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
              />
  
              <Box
                py={1}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>Operator Txn Id.</Box>
                <Box sx={{ textAlign: "right" }}>{payload.operatorTxnId}</Box>
              </Box>
              <Box
                component={"div"}
                sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
              />
              <Box
                py={1}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>Transaction Id</Box>
                <Box sx={{ textAlign: "right" }}>{payload.txnId}</Box>
              </Box>
              <Box
                component={"div"}
                sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
              />
              <Box
                py={1}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>Response</Box>
                <Box sx={{ textAlign: "right" }}>{payload.message}</Box>
              </Box>
              <Box
                component={"div"}
                sx={{ width: "100%", borderTop: "1px dashed #ddd" }}
              />
              <Box my={2} sx={{ display: "flex", justifyContent: "center" }}>
                <StandardStyledButton
                  sx={{ mr: 2 }}
                  variant="contained"
                  customcolor={props.appData.buttonColor}
                  customfontcolor={props.appData.buttonFontColor}
                  p={"15px 16px"}
                  onClick={() =>
                    navigate("/portal/dashboard", { replace: true })
                  }
                >
                  Done
                </StandardStyledButton>
                <StandardStyledButton>Print</StandardStyledButton>
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: 5 }}>
              Something went wrong. Please check transaction status in history.
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
