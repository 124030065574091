import React from "react";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import FingerprintOutlined from "@mui/icons-material/FingerprintOutlined";
import { useNavigate } from "react-router-dom";
import aeps_request from "./../../../../../../rest_api/aeps_request";
import XMLParser from "react-xml-parser";

const bank_list = [
  {
    BankName: "Axis Bank",
    BankNumber: "607153",
    AadhaarPay: true,
  },
  {
    BankName: "Airtel Payments Bank",
    BankNumber: "990288",
    AadhaarPay: true,
  },
  {
    BankName: "AU Small Finance Bank",
    BankNumber: "608088",
    AadhaarPay: true,
  },
  {
    BankName: "Andhra Pradesh Gramin Vikash Bank",
    BankNumber: "607198",
    AadhaarPay: true,
  },
  {
    BankName: "Andhra Pragathi Grameena Bank",
    BankNumber: "607121",
    AadhaarPay: true,
  },
  {
    BankName: "Aryavart Bank",
    BankNumber: "508509",
    AadhaarPay: true,
  },
  {
    BankName: "Assam Gramin Vikash Bank",
    BankNumber: "607064",
    AadhaarPay: true,
  },
  {
    BankName: "Bank Of Baroda",
    BankNumber: "606985",
    AadhaarPay: true,
  },
  {
    BankName: "Bank of India",
    BankNumber: "508505",
    AadhaarPay: true,
  },
  {
    BankName: "Bank of Maharashtra",
    BankNumber: "607387",
    AadhaarPay: false,
  },
  {
    BankName: "Canara Bank",
    BankNumber: "508532",
    AadhaarPay: true,
  },
  {
    BankName: "Central Bank of India",
    BankNumber: "607115",
    AadhaarPay: true,
  },
  {
    BankName: "City Union Bank",
    BankNumber: "607324",
    AadhaarPay: true,
  },
  {
    BankName: "Development Bank Of Singapore",
    BankNumber: "607578",
    AadhaarPay: true,
  },
  {
    BankName: "Federal Bank",
    BankNumber: "607165",
    AadhaarPay: true,
  },
  {
    BankName: "HDFC Bank",
    BankNumber: "607152",
    AadhaarPay: true,
  },
  {
    BankName: "ICICI Bank",
    BankNumber: "508534",
    AadhaarPay: true,
  },
  {
    BankName: "IDBI Bank",
    BankNumber: "607095",
    AadhaarPay: true,
  },
  {
    BankName: "IDFC FIRST Bank",
    BankNumber: "608117",
    AadhaarPay: true,
  },
  {
    BankName: "Indian Bank",
    BankNumber: "607105",
    AadhaarPay: true,
  },
  {
    BankName: "Indian Overseas Bank",
    BankNumber: "508541",
    AadhaarPay: true,
  },
  {
    BankName: "IndusInd Bank",
    BankNumber: "607189",
    AadhaarPay: true,
  },
  {
    BankName: "Jammu and Kashmir bank",
    BankNumber: "607440",
    AadhaarPay: true,
  },
  {
    BankName: "Karnataka Bank Limited",
    BankNumber: "607270",
    AadhaarPay: true,
  },
  {
    BankName: "Karur Vysya Bank",
    BankNumber: "607100",
    AadhaarPay: true,
  },
  {
    BankName: "Kotak Mahindra Bank",
    BankNumber: "607420",
    AadhaarPay: true,
  },
  {
    BankName: "Punjab & Sind Bank",
    BankNumber: "607087",
    AadhaarPay: true,
  },
  {
    BankName: "Punjab National Bank",
    BankNumber: "508568",
    AadhaarPay: true,
  },
  {
    BankName: "RBL",
    BankNumber: "607393",
    AadhaarPay: true,
  },
  {
    BankName: "SBM Bank India Ltd",
    BankNumber: "607395",
    AadhaarPay: true,
  },
  {
    BankName: "South Indian Bank",
    BankNumber: "607167",
    AadhaarPay: true,
  },
  {
    BankName: "State Bank of India",
    BankNumber: "508548",
    AadhaarPay: true,
  },
  {
    BankName: "Tamilnad Mercantile bank",
    BankNumber: "607187",
    AadhaarPay: true,
  },
  {
    BankName: "UCO Bank",
    BankNumber: "607066",
    AadhaarPay: true,
  },
  {
    BankName: "Union Bank of India",
    BankNumber: "508500",
    AadhaarPay: true,
  },
  {
    BankName: "Yes Bank",
    BankNumber: "607223",
    AadhaarPay: true,
  },

  {
    BankName: "Bangiya Gramin Vikash Bank",
    BankNumber: "607063",
    AadhaarPay: false,
  },
  {
    BankName: "Baroda Gujarat Gramin Bank",
    BankNumber: "606995",
    AadhaarPay: true,
  },
  {
    BankName: "Baroda Rajasthan Kshetriya Gramin Bank",
    BankNumber: "607280",
    AadhaarPay: true,
  },
  {
    BankName: "Baroda Uttar Pradesh Gramin Bank",
    BankNumber: "606993",
    AadhaarPay: true,
  },
  {
    BankName: "Chaitanya Godavari Grameena Bank",
    BankNumber: "607080",
    AadhaarPay: true,
  },
  {
    BankName: "Chhattisgharh Rajya Gramin Bank",
    BankNumber: "607214",
    AadhaarPay: false,
  },
  {
    BankName: "Madhya Bihar Gramin Bank",
    BankNumber: "607136",
    AadhaarPay: true,
  },
  {
    BankName: "Ellaquai Dehati Bank",
    BankNumber: "607218",
    AadhaarPay: true,
  },
  {
    BankName: "Himachal Pradesh Gramin Bank",
    BankNumber: "607140",
    AadhaarPay: true,
  },
  {
    BankName: "J&K Grameen Bank",
    BankNumber: "607808",
    AadhaarPay: false,
  },
  {
    BankName: "Jharkhand Rajya Gramin Bank",
    BankNumber: "607210",
    AadhaarPay: true,
  },
  {
    BankName: "Karnataka Gramin Bank",
    BankNumber: "607389",
    AadhaarPay: true,
  },
  {
    BankName: "Karnataka Vikas Grameena Bank",
    BankNumber: "607122",
    AadhaarPay: true,
  },
  {
    BankName: "Kerala Gramin Bank",
    BankNumber: "607476",
    AadhaarPay: true,
  },
  {
    BankName: "Madhya Pradesh Gramin Bank",
    BankNumber: "508515",
    AadhaarPay: true,
  },
  {
    BankName: "Madhyanchal Gramin Bank",
    BankNumber: "607232",
    AadhaarPay: true,
  },
  {
    BankName: "Maharashtra Gramin Bank",
    BankNumber: "607000",
    AadhaarPay: false,
  },
  {
    BankName: "Manipur Rural Bank",
    BankNumber: "607062",
    AadhaarPay: false,
  },
  {
    BankName: "Meghalaya Rural Bank",
    BankNumber: "607206",
    AadhaarPay: false,
  },
  {
    BankName: "Odisha Gramya Bank",
    BankNumber: "607060",
    AadhaarPay: true,
  },
  {
    BankName: "Paschim Banga Gramin Bank",
    BankNumber: "607079",
    AadhaarPay: false,
  },
  {
    BankName: "Prathama UP Gramin Bank",
    BankNumber: "607135",
    AadhaarPay: true,
  },
  {
    BankName: "Puduvai Bharathiar Grama Bank",
    BankNumber: "607054",
    AadhaarPay: true,
  },
  {
    BankName: "Punjab Gramin Bank",
    BankNumber: "607138",
    AadhaarPay: true,
  },
  {
    BankName: "Rajasthan Marudhara Gramin Bank",
    BankNumber: "607509",
    AadhaarPay: true,
  },
  {
    BankName: "Saptagiri Grameena Bank",
    BankNumber: "607053",
    AadhaarPay: true,
  },
  {
    BankName: "Sarva Haryana Gramin Bank",
    BankNumber: "607139",
    AadhaarPay: true,
  },
  {
    BankName: "Saurashtra Gramin Bank",
    BankNumber: "607200",
    AadhaarPay: true,
  },
  {
    BankName: "Pallavan Grama Bank",
    BankNumber: "607052",
    AadhaarPay: true,
  },
  {
    BankName: "Telangana Grameena Bank",
    BankNumber: "607195",
    AadhaarPay: true,
  },
  {
    BankName: "Tripura Gramin Bank",
    BankNumber: "607065",
    AadhaarPay: false,
  },
  {
    BankName: "Utkal Gramin Bank",
    BankNumber: "607234",
    AadhaarPay: false,
  },
  {
    BankName: "Uttar Banga Kshetriya Gramin Bank",
    BankNumber: "607073",
    AadhaarPay: true,
  },
  {
    BankName: "Uttar Bihar Gramin Bank",
    BankNumber: "607069",
    AadhaarPay: true,
  },
  {
    BankName: "Uttarakhand Gramin Bank",
    BankNumber: "607197",
    AadhaarPay: true,
  },
  {
    BankName: "Vidharbha Konkan Gramin Bank",
    BankNumber: "508516",
    AadhaarPay: true,
  },
  {
    BankName: "Arunachal Pradesh Rural Bank",
    BankNumber: "607216",
    AadhaarPay: true,
  },

  {
    BankName: "The Berhampore Co Operative Central Bank Ltd",
    BankNumber: "508759",
    AadhaarPay: false,
  },
  {
    BankName: "The Aska Cooperative Central Bank Ltd Aska",
    BankNumber: "508773",
    AadhaarPay: false,
  },
  {
    BankName: "The Keonjhar Central Cooperative Bank Ltd.",
    BankNumber: "508760",
    AadhaarPay: false,
  },
  {
    BankName: "Dharmapuri District Central Co op Bank Ltd",
    BankNumber: "508658",
    AadhaarPay: false,
  },
  {
    BankName: "Dindigul Central Co-operative Bank Ltd",
    BankNumber: "508659",
    AadhaarPay: false,
  },
  {
    BankName: "Erode Disctrict Central Co-operative Bank Ltd",
    BankNumber: "508654",
    AadhaarPay: false,
  },
  {
    BankName: "Kancheepuram Central Co-operative Bank Ltd.",
    BankNumber: "508734",
    AadhaarPay: false,
  },
  {
    BankName: "Maharashtra State Co-operative Bank Ltd",
    BankNumber: "607315",
    AadhaarPay: false,
  },
  {
    BankName: "Pudukottai District Central Cooperative Bank Ltd",
    BankNumber: "508656",
    AadhaarPay: false,
  },
  {
    BankName: "Tamilnadu State Apex Co-operative Bank",
    BankNumber: "607131",
    AadhaarPay: true,
  },
  {
    BankName: "Thanjavur Central Co op Bank Ltd",
    BankNumber: "508665",
    AadhaarPay: false,
  },
  {
    BankName: "The Coimbatore District Central Co-op Bank Limited",
    BankNumber: "508646",
    AadhaarPay: false,
  },
  {
    BankName: "The Cosmos Co- operative Bank Ltd",
    BankNumber: "607090",
    AadhaarPay: true,
  },
  {
    BankName: "The Cuddalore District Central Co-operative Bank Ltd.",
    BankNumber: "508647",
    AadhaarPay: false,
  },
  {
    BankName: "THE GAYATRI COOPERATIVE URBAN BANK LTD",
    BankNumber: "607157",
    AadhaarPay: true,
  },
  {
    BankName: "The Kanyakumari District Central Cooperative Bank",
    BankNumber: "508655",
    AadhaarPay: false,
  },
  {
    BankName: "The Kumbakonam Central Co-operative Bank Ltd",
    BankNumber: "508720",
    AadhaarPay: false,
  },
  {
    BankName: "The Madurai District Central Cooperative Bank Ltd",
    BankNumber: "508681",
    AadhaarPay: false,
  },
  {
    BankName: "The Nilgiris District Central Coop Bank Ltd",
    BankNumber: "508660",
    AadhaarPay: false,
  },
  {
    BankName: "The Ramanathapuram District Central Co op Bank Ltd",
    BankNumber: "508676",
    AadhaarPay: false,
  },
  {
    BankName: "The Salem District Central Co-operative Bank",
    BankNumber: "508648",
    AadhaarPay: false,
  },
  {
    BankName: "THE SIVAGANGAI DISTRICT CENTRAL COOP BANK LTD",
    BankNumber: "508649",
    AadhaarPay: false,
  },

  {
    BankName: "The Thoothukudi District Central Coop Bank Ltd",
    BankNumber: "508678",
    AadhaarPay: false,
  },
  {
    BankName: "THE TIRUCHIRAPALLI DIST. CENT COOPERATIVE BANK LTD",
    BankNumber: "508680",
    AadhaarPay: false,
  },
  {
    BankName: "The Tirunelveli District Central Co-op Bank Ltd",
    BankNumber: "508677",
    AadhaarPay: false,
  },
  {
    BankName: "THE THIRUVANNAMALAI DISTRICT CENTRAL COOP BANK LTD",
    BankNumber: "508657",
    AadhaarPay: false,
  },
  {
    BankName: "The Vellore District Central Co-operative Bank Ltd.",
    BankNumber: "508679",
    AadhaarPay: false,
  },
  {
    BankName: "The Villupuram District Central Co-operative Bank Ltd",
    BankNumber: "508737",
    AadhaarPay: false,
  },
  {
    BankName: "The Virudhunagar District Central Co-op Bank Ltd",
    BankNumber: "508732",
    AadhaarPay: false,
  },
  {
    BankName: "Tripura State Co-operative Bank Limited",
    BankNumber: "607978",
    AadhaarPay: false,
  },
  {
    BankName: "UTTAR PRADESH CO-OPERATIVE BANK LTD",
    BankNumber: "607523",
    AadhaarPay: false,
  },
  {
    BankName: "THE SOLAPUR DISTRICT CENTRAL CO-OP. BANK LTD",
    BankNumber: "607943",
    AadhaarPay: false,
  },
  {
    BankName: "The Bhandara District Central Co-op Bank",
    BankNumber: "607847",
    AadhaarPay: false,
  },
  {
    BankName: "The Mayurbhanj Central Co-operative Bank Ltd.",
    BankNumber: "508756",
    AadhaarPay: false,
  },
  {
    BankName: "CHENNAI CENTRAL CO-OPERATIVE BANK LTD.",
    BankNumber: "508664",
    AadhaarPay: false,
  },
  {
    BankName: "Cuttack Central Co-operative bank Ltd",
    BankNumber: "508776",
    AadhaarPay: false,
  },
  {
    BankName: "The Koraput Central Co-operative Bank Ltd",
    BankNumber: "508755",
    AadhaarPay: false,
  },
  {
    BankName: "Balangir District Central Cooperative Bank Ltd.",
    BankNumber: "508774",
    AadhaarPay: true,
  },
  {
    BankName: "Bhawanipatna Central Cooperative Bank Ltd.",
    BankNumber: "508778",
    AadhaarPay: true,
  },
  {
    BankName: "Nayagarh District Central Cooperative Bank Ltd",
    BankNumber: "508772",
    AadhaarPay: true,
  },
  {
    BankName: "The Sambalpur District Cooperative Central Bank Ltd",
    BankNumber: "508777",
    AadhaarPay: true,
  },
  {
    BankName: "KERALA STATE CO-OPERATIVE BANK LTD",
    BankNumber: "608165",
    AadhaarPay: true,
  },

  {
    BankName: "Equitas Small Finance Bank",
    BankNumber: "508998",
    AadhaarPay: true,
  },
  {
    BankName: "ESAF Small Finance Bank",
    BankNumber: "508992",
    AadhaarPay: true,
  },
  {
    BankName: "Fincare Small Finance Bank",
    BankNumber: "608304",
    AadhaarPay: true,
  },
  {
    BankName: "Fino Payments Bank",
    BankNumber: "608001",
    AadhaarPay: true,
  },
  {
    BankName: "INDIA POST PAYMENTS BANK LIMITED",
    BankNumber: "508528",
    AadhaarPay: true,
  },
  {
    BankName: "Jana Small Finance Bank",
    BankNumber: "652268",
    AadhaarPay: true,
  },
  {
    BankName: "Jio Payments Bank",
    BankNumber: "607884",
    AadhaarPay: false,
  },
  {
    BankName: "NSDL Payments Bank",
    BankNumber: "990326",
    AadhaarPay: false,
  },
  {
    BankName: "Paytm Payments Bank",
    BankNumber: "608032",
    AadhaarPay: false,
  },
  {
    BankName: "Shivalik Small Finance Bank",
    BankNumber: "607119",
    AadhaarPay: false,
  },
  {
    BankName: "Suryoday Small Finance Bank Ltd.",
    BankNumber: "608022",
    AadhaarPay: true,
  },
  {
    BankName: "Ujjivan Small Finance Bank",
    BankNumber: "508991",
    AadhaarPay: true,
  },
  {
    BankName: "Utkarsh Small Finance Bank Ltd",
    BankNumber: "608014",
    AadhaarPay: false,
  },
];

export default function StandardAepsForm(props) {
  const {
    serviceType,
    error,
    setServiceType,
    bankName,
    setbankName,
    isDeviceReady,
    captureFinger,
    isProcessing,
    setProcessing,
    setAmount,
    amount,
    setAadhaarNo,
    aadhaarNo,
    AgentAadhaarNo,
    CustomerMobile,
    setCustomerMobile,
    latitude,
    longitude,
    open_snackbar,
    snackbar_severity,
    snackbar_message,
    setError,
    auth_session_id,
    setAuth_session_id,
    setAeps_twofactorauth,
    setTxnPayload,
    setIs_aadhar_required,
  } = props;

  const navigate = useNavigate();

  const perform_aeps_request = async () => {
    setTxnPayload("");
    if (
      CustomerMobile &&
      AgentAadhaarNo &&
      CustomerMobile.length === 10 &&
      AgentAadhaarNo.length === 12 &&
      aadhaarNo &&
      aadhaarNo.length === 12 &&
      bankName &&
      serviceType &&
      ((serviceType === "Cash Withdrawal" &&
        amount !== "" &&
        parseInt(amount) > 99 &&
        parseInt(amount) <= 10000 &&
        parseInt(amount) % 50 === 0) ||
        serviceType !== "Cash Withdrawal")
    ) {
      try {
        setError("");
        setProcessing(true);
        const res = await captureFinger("Capture");

        var xml = new XMLParser().parseFromString(res.data);

        //console.log("Captured response: " + JSON.stringify(xml));

        if (
          xml.children[0].attributes.errInfo === "Success." ||
          xml.children[0].attributes.errCode === "0"
        ) {
          const bankNumber = bank_list.filter((bank) => {
            return bank.BankName === bankName ? bank.BankNumber : "";
          });

          //console.log(bankNumber[0].BankNumber);

          const device_data =
            xml.children[1] === undefined &&
            xml.children[0].children[0].attributes
              ? xml.children[0].children[0]
              : xml.children[1];

          const post_data = {
            pid_data: res.data,
            agentAadhaarNo: AgentAadhaarNo,
            latitude: latitude,
            longitude: longitude,
            customer_mobile: CustomerMobile,
            auth_session_id: auth_session_id,
            bankName: bankName,
            bankNumber: bankNumber[0].BankNumber,
            serviceType: serviceType,
            transAmount: amount,
            CustomerAadhaarNo: aadhaarNo,
            biometric_srno:
              device_data.children[0].children[0].attributes.value,
            biometric_rdsVer: device_data.attributes.rdsVer,
            biometric_rdsId: device_data.attributes.rdsId,
            biometric_dpId: device_data.attributes.dpId,
            biometric_mi: device_data.attributes.mi,
          };

          const txn_res = await aeps_request.aeps_txn(post_data);
          console.log("txn_res");
          console.log(txn_res);
          setProcessing(false);
          if (txn_res) {
            if (txn_res.isLoggedIn) {
              setAuth_session_id("");
              setAeps_twofactorauth(true);
              setIs_aadhar_required(false);
              setTxnPayload(txn_res.response ? txn_res : "");
              setCustomerMobile("");
              setAadhaarNo("");
              setAmount("");
              setbankName("State Bank of India");
              setServiceType("Min Statement");

              

              if (txn_res.status !== 1) {
                open_snackbar(false);
                snackbar_severity("error");
                snackbar_message(txn_res.message);
                open_snackbar(true);
                txn_res.response === undefined && setError(txn_res.message);
              } else {
                open_snackbar(false);
                snackbar_severity("info");
                snackbar_message(
                  txn_res.message === "Approved"
                    ? "Transaction was successful"
                    : txn_res.message
                );
                open_snackbar(true);
              }
            } else {
              open_snackbar(false);
              snackbar_severity("error");
              snackbar_message(txn_res.message);
              open_snackbar(true);
              localStorage.clear();
              navigate("/login");
            }
          } else {
            open_snackbar(false);
            snackbar_severity("error");
            snackbar_message("No response from the server");
            open_snackbar(true);
          }
        } else {
          setProcessing(false);
          setError(xml.children[0].attributes.errInfo);
          open_snackbar(false);
          snackbar_severity("error");
          snackbar_message(xml.children[0].attributes.errInfo);
          open_snackbar(true);
        }
      } catch (e) {
        setProcessing(false);
        open_snackbar(false);
        snackbar_severity("error");
        snackbar_message(e.message);
        open_snackbar(true);
      }
    } else if (parseInt(amount) < 100) {
      setProcessing(false);
      open_snackbar(false);
      snackbar_severity("error");
      snackbar_message("Please enter amount between 100-10000 INR.");
      open_snackbar(true);
    } else if (parseInt(amount) % 50 !== 0) {
      setProcessing(false);
      open_snackbar(false);
      snackbar_severity("error");
      snackbar_message("Please enter the amount in the multiple of 50 INR.");
      open_snackbar(true);
    } else {
      setProcessing(false);
      open_snackbar(false);
      snackbar_severity("error");
      snackbar_message("Please fill the form correctly.");
      open_snackbar(true);
    }
  };

  return (
    <Box
      px={3}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Box width={"100%"}>
        <Typography
          variant="caption"
          sx={{ fontWeight: "600" }}
          component={"div"}
          px={1}
          pt={1}
        >
          Customer Mobile Number
        </Typography>
        <StandardStyledTextfield
          placeholder="Mobile Number"
          fullWidth
          value={CustomerMobile}
          onChange={(e) =>
            e.target.value.length <= 10 ? setCustomerMobile(e.target.value) : {}
          }
        />
      </Box>
      <Box mt={1} width={"100%"}>
        <Typography
          variant="caption"
          sx={{ fontWeight: "600" }}
          component={"div"}
          px={1}
          pt={1}
        >
          Transaction Type
        </Typography>
        <FormControl fullWidth>
          <Select
            id="standard-service-select"
            value={serviceType}
            error={error.indexOf("serviceType") >= 0}
            input={
              <OutlinedInput
                placeholder="Type"
                sx={{
                  "& fieldset": {
                    borderRadius: "16px",
                    borderColor:
                      error.indexOf("serviceType") >= 0
                        ? "error"
                        : "#e9e9e9 !important",
                    borderWidth: "1px",
                  },
                }}
                onChange={(e) => setServiceType(e.target.value)}
              />
            }
          >
            <MenuItem value="Min Statement">Min Statement</MenuItem>
            <MenuItem value="Cash Withdrawal">Cash Withdrawal</MenuItem>
            <MenuItem value="Balance Enquiry">Balance Enquiry</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt={1} width={"100%"}>
        <Typography
          variant="caption"
          sx={{ fontWeight: "600" }}
          component={"div"}
          pt={1}
          px={1}
        >
          Select Bank
        </Typography>
        <FormControl fullWidth>
          <Select
            id="standard-bankName-select"
            value={bankName}
            error={error.indexOf("bankName") >= 0}
            input={
              <OutlinedInput
                placeholder="Bank Name"
                onChange={(e) => setbankName(e.target.value)}
                sx={{
                  "& fieldset": {
                    borderRadius: "16px",
                    borderColor:
                      error.indexOf("bankName") >= 0
                        ? "error"
                        : "#e9e9e9 !important",
                    borderWidth: "1px",
                  },
                }}
              />
            }
          >
            {bank_list.map((bank, index) => (
              <MenuItem key={"bankname" + index} value={bank.BankName}>
                {bank.BankName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            mt: 1,
            width: { xs: "calc(100% - 16px)", lg: "calc(50% - 16px)" },
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontWeight: "600" }}
            component={"div"}
            px={1}
            pt={1}
          >
            Customer Aadhaar Number
          </Typography>
          <StandardStyledTextfield
            placeholder="Aadhaar Number"
            fullWidth
            value={aadhaarNo}
            onChange={(e) =>
              e.target.value.length <= 12 ? setAadhaarNo(e.target.value) : {}
            }
          />
        </Box>
        {serviceType === "Cash Withdrawal" ? (
          <>
            <Box
              sx={{ width: "16px", display: { xs: "none", lg: "block" } }}
            ></Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 1,
                width: { xs: "calc(100% - 16px)", lg: "calc(50% - 16px)" },
              }}
            >
              <Typography
                variant="caption"
                sx={{ fontWeight: "600" }}
                component={"div"}
                px={1}
                pt={1}
              >
                Enter Amount
              </Typography>
              <StandardStyledTextfield
                placeholder="Amount"
                fullWidth
                value={amount}
                onChange={(e) =>
                  e.target.value === "" ||
                  (e.target.value !== "" && parseInt(e.target.value) <= 10000)
                    ? setAmount(e.target.value)
                    : {}
                }
              />
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>

      <Box sx={{ mt: 1 }}>
        {isProcessing ? (
          <CircularProgress />
        ) : (
          <StandardStyledButton
            variant="contained"
            customcolor={isDeviceReady && !isProcessing ? "#27751d" : "#999999"}
            customfontcolor={props.appData.buttonFontColor}
            p={"15px 16px"}
            startIcon={<FingerprintOutlined />}
            onClick={() =>
              isDeviceReady && !isProcessing ? perform_aeps_request() : {}
            }
          >
            Scan Finger & Proceed
          </StandardStyledButton>
        )}
      </Box>
    </Box>
  );
}
