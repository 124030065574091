import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



export function StandardLineChart(props) {
    const { data, options } = props;

   

    const datasets = data && data.datasets && data.datasets.filter((d)=> d !== false);

    const chart_data = {labels: data.labels, datasets: datasets};

    

    return chart_data && <Line options={options} data={chart_data} />;
}
