import _utlis from "../utils/netwk_utils";

const getPayoutBeneficiaryList = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
      ? "/payout/beneficiary/list"
      : "/api/payout/beneficiary/list";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const payoutTransaction = async (data) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/payout/transaction"
        : "/api/payout/transaction";

    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const addPayoutBeneficiary = async (data) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/payout/beneficiary/new"
        : "/api/payout/beneficiary/new";

    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const payout_request = {
  payoutTransaction,
  addPayoutBeneficiary,
  getPayoutBeneficiaryList,
};

export default payout_request;
