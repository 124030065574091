import React from "react";

import { Box } from "@mui/material";

import { format } from "date-fns";

export default React.forwardRef(function StandardTransactionDetails(
  props,
  ref
) {
  const { payload, KeyValue } = props;

  console.log(payload);

  return (
    <Box sx={{ my: 2, mx: 1 }} ref={ref}>
      <KeyValue
        keyName={"Status"}
        value={
          payload.status === "1"
            ? "Success"
            : payload.status === "0"
            ? "Pending"
            : payload.status === "3" &&
              payload.credit &&
              payload.credit !== "" &&
              payload.credit !== 0 &&
              payload.credit !== "0"
            ? "Refund Received"
            : payload.status === "3" &&
              payload.debit &&
              payload.debit !== "" &&
              payload.debit !== 0 &&
              payload.debit !== "0"
            ? "Refunded"
            : payload.status === "4"
            ? "Hold"
            : payload.status === "5"
            ? "Initiated"
            : payload.status === "6"
            ? "Refund Pending"
            : "Failure"
        }
      />
      <KeyValue keyName={"Transaction"} value={payload.type} />
      {payload.service_type && payload.service_type.length > 0 && (
        <KeyValue keyName={"Type"} value={payload.service_type} />
      )}
      {payload.circle && payload.circle.length > 0 && (
        <KeyValue keyName={"Circle/Zone"} value={payload.circle} />
      )}
      <KeyValue
        keyName={"Amount"}
        value={"₹" + parseFloat(payload.amount).toFixed(2)}
      />
      {payload.commission &&
        payload.commission !== "" &&
        payload.commission !== 0 &&
        payload.commission !== "0" && (
          <KeyValue
            keyName={"Commission"}
            value={"₹" + parseFloat(payload.commission).toFixed(2)}
          />
        )}
      {payload.charge &&
        payload.charge !== "" &&
        payload.charge !== 0 &&
        payload.charge !== "0" && (
          <KeyValue
            keyName={"Charge"}
            value={"₹" + parseFloat(payload.charge).toFixed(2)}
          />
        )}
      {payload.gst &&
        payload.gst !== "" &&
        payload.gst !== 0 &&
        payload.gst !== "0" && (
          <KeyValue
            keyName={"GST"}
            value={"₹" + parseFloat(payload.gst).toFixed(2)}
          />
        )}
      {payload.tds &&
        payload.tds !== "" &&
        payload.tds !== 0 &&
        payload.tds !== "0" && (
          <KeyValue
            keyName={"TDS"}
            value={"₹" + parseFloat(payload.tds).toFixed(2)}
          />
        )}
      {payload.credit &&
        payload.credit !== "" &&
        payload.credit !== 0 &&
        payload.credit !== "0" && (
          <KeyValue
            keyName={"Credit"}
            value={"₹" + parseFloat(payload.credit).toFixed(2)}
          />
        )}
      {payload.debit &&
        payload.debit !== "" &&
        payload.debit !== 0 &&
        payload.debit !== "0" && (
          <KeyValue
            keyName={"Debit"}
            value={"₹" + parseFloat(payload.debit).toFixed(2)}
          />
        )}
      <KeyValue
        keyName={"Balance"}
        value={"₹" + parseFloat(payload.balance).toFixed(2)}
      />
      <KeyValue keyName={"TID"} value={payload.id} />
      {payload.utr_no && payload.utr_no.length > 0 && (
        <KeyValue keyName={"UTR No."} value={payload.utr_no} />
      )}
      {payload.operatorId && payload.operatorId.length > 0 && (
        <KeyValue keyName={"Operator Id"} value={payload.operatorId} />
      )}
      {payload.clientId && payload.clientId.length > 0 && (
        <KeyValue keyName={"Client Id"} value={payload.clientId} />
      )}
      <KeyValue keyName={"Customer's Mobile"} value={payload.number} />
      {payload.account && payload.account.length > 0 && (
        <KeyValue
          keyName={"Recipient Account Number"}
          value={payload.account}
        />
      )}
      {payload.account_holder_name &&
        payload.account_holder_name.length > 0 && (
          <KeyValue
            keyName={"Recipient Name"}
            value={payload.account_holder_name}
          />
        )}
      {payload.serviceAccount && payload.serviceAccount.length > 0 && (
        <KeyValue keyName={"Service Account"} value={payload.serviceAccount} />
      )}
      {payload.operator && payload.operator.length > 0 && (
        <KeyValue
          keyName={
            payload.type === "DMT" ||
            payload.type === "AEPS" ||
            payload.type === "Aadhaar Pay" ||
            payload.type === "Payout" ||
            payload.type === "TopUp" ||
            payload.type === "Micro ATM" ||
            payload.type === "MPOS" ||
            payload.type === "Cash@Pos"
              ? "Bank"
              : "Operator"
          }
          value={payload.operator}
        />
      )}
      {payload.createdAt && payload.createdAt.length > 0 && (
        <KeyValue
          keyName={"Transaction Date"}
          value={format(new Date(payload.createdAt), "dd-MM-yyyy, hh:mm:ss a")}
        />
      )}
      {payload.refundAt && payload.refundAt.length > 0 && (
        <KeyValue
          keyName={"Refund Date"}
          value={format(new Date(payload.refundAt), "dd-MM-yyyy, hh:mm:ss a")}
        />
      )}
      {payload.remark && payload.remark.length > 0 && (
        <KeyValue keyName={"Remark"} value={payload.remark} />
      )}
    </Box>
  );
});
