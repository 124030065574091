import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";

export default function StandardOfferTable(props) {
  const { rows, userType } = props;
  const columns = [
    "S.N",
    "Operator",
    "Service Type",
    "Min. Amt",
    "Max. Amt",
    "Circle",
    "Slab",
    "Slab Type",
    "Tax",
    "TDS",
    "Daily Limit",
    "Opt. Code",
  ];

  return rows && rows.length > 0 ? (
    <TableContainer>
      <Table aria-label="offer table">
        <TableHead>
          <TableRow>
            {columns.map((clm, index) =>
              userType === "API" || clm !== "Opt. Code" ? (
                <TableCell key={index + "" + clm}>{clm}</TableCell>
              ) : (
                ""
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <Row key={row.id} row={row} index={index} userType={userType} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box p={4}>No offer/package available</Box>
  );
}

function Row(props) {
  const { row, index, userType } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell>{row.operator ? row.operator : "-"}</TableCell>
        <TableCell>{row.service_type}</TableCell>
        <TableCell>₹{row.minimum_cap}</TableCell>
        <TableCell>₹{row.maximum_cap}</TableCell>
        <TableCell>{row.circle}</TableCell>
        <TableCell>
          {row.slab_calculation === "Percentage"
            ? row.slab + "%"
            : "₹" + row.slab}
        </TableCell>
        <TableCell>
          {row.slab_type.charAt(0).toUpperCase() + "" + row.slab_type.slice(1)}
        </TableCell>
        <TableCell>
          {row.tax_type +
            " " +
            (row.tax_type === "GST" && row.gst_applicable === "included"
              ? "Included"
              : row.tax_type === "GST"
              ? "Excluded"
              : "")}
        </TableCell>
        <TableCell>{row.tds_applicable.toUpperCase()}</TableCell>
        <TableCell>₹{row.daily_limit}</TableCell>
        {userType === "API" && <TableCell>{row.optCode}</TableCell>}
      </TableRow>
    </React.Fragment>
  );
}
