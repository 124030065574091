import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Chip,
  IconButton,
  TableRow,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dt_user_request from "../../../../rest_api/dt_user_request";
import { format } from "date-fns";

export default function StandardAgentList(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;
  const navigate = useNavigate();

  const [isloading, setLoading] = React.useState(true);
  const [balanceLoading, setBalanceLoading] = React.useState(false);

  const [error, setError] = React.useState("");
  const [selectedAgent, setSelectedAgent] = React.useState("");

  const [agentList, setAgentList] = React.useState([]);

  const view_agent_details = (agent) => {
    navigate("/portal/agent/details", { state: { agent: agent } });
  };

  const get_balance = async (agent_id) => {
    setBalanceLoading(true);
    setSelectedAgent(agent_id);
    try {
      const res = await dt_user_request.get_agent_balance(agent_id);
      setBalanceLoading(false);
      if (res) {

        if (res.isLoggedIn) {

          if (res.status === 1) {

            snackbar_severity("success");
            snackbar_message("Agent ("+agent_id+") Balance: ₹"+parseFloat(res.balance).toFixed(2));
            open_snackbar(true);
            
          } else {
            setError(res.message);
          }

        } else {

          setError(res.message);
          localStorage.clear();
          navigate("/login")

        }

      } else {
        setError("No response key");
      }

    } catch (e) {
      setBalanceLoading(false);
      setError(e.message);
    }
  };

  useEffect(() => {
    if (error && error !== "") {
      
      snackbar_message(error);
      snackbar_severity("error");
      open_snackbar(true);

      if (error === "Session Expire") {
        localStorage.clear();
        navigate("/");
      }
    }
  }, [error, open_snackbar, snackbar_message, snackbar_severity, navigate]);

  useEffect(() => {
    const getAgentList = async () => {
      try {
        setLoading(true);
        const res = await dt_user_request.get_agents_list();
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            res.status === 1 ? setError("") : setError(res.message);
            setAgentList(res.agents);
          } else {
            setError(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError("No response key");
        }
      } catch (e) {
        setLoading(false);
        setError(e.message);
      }
    };

    getAgentList();
  }, [navigate]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
          Agent List
        </Typography>
        <Box>
          <Tooltip title="Filter Agent List">
            <IconButton>
              <TuneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Search Agent">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box mt={1}>
        <Paper
          sx={{
            flexGrow: 1,
            width: "100%",
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${appData !== undefined && appData.cardRadius !== null
              ? appData.cardRadius
              : "6px"
              }`,
          }}
        >
          {isloading ? (
            <Box p={2}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer>
              <Table aria-label="Standard Basic table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.N</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>MOBILE</TableCell>
                    <TableCell>CITY</TableCell>
                    <TableCell>STATE</TableCell>
                    <TableCell>STATUS</TableCell>
                    <TableCell>CREATED AT</TableCell>
                    <TableCell>BALANCE</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agentList &&
                    agentList.map((row, index) => (
                      <TableRow
                        hover
                        key={"txn" + index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontWeight: "600" }}
                        >
                          {row.fname + " " + row.lname}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.mobile}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.city}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.statename}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.account_status === "0" ? (
                            <Chip
                              color="warning"
                              label="Inactive"
                              size="small"
                            />
                          ) : (
                            <Chip
                              color="primary"
                              label="Verified"
                              size="small"
                            />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>
                            {row.createdAt &&
                              format(new Date(row.createdAt), "dd-MM-yyyy")}
                          </Typography>{" "}
                          {row.createdAt
                            ? format(new Date(row.createdAt), "hh:mm:ss a")
                            : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {balanceLoading && selectedAgent === row.mobile ? (
                            <CircularProgress size={"24px"} />
                          ) : (
                            <Chip
                              clickable
                              color="info"
                              label="Check"
                              size="small"
                              disabled={balanceLoading}
                              onClick={() => {
                                get_balance(row.mobile);
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <IconButton
                            disabled={balanceLoading}
                            onClick={() =>
                              !balanceLoading && view_agent_details(row)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
