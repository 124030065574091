import React from "react";
import { sub } from "date-fns";
import { useLocation } from "react-router-dom";
import Loading from "./global_component/Loading";
import RoutesComponent from "./Routes/RoutesComponent";
import ApplicationErrorMessage from "./global_component/ApplicationErrorMessage";
import _utils from "./utils/netwk_utils";

const handleFavicon = (url) => {
  const favicon = document.getElementById("favicon"); // Accessing favicon element
  favicon.href = url;
};

function App() {
  const location = useLocation();
  const [aplicationErrorMessage, setApplicationErrorMessage] =
    React.useState("");
  const [appData, setAppData] = React.useState("");
  const [isAppLoading, setAppLoading] = React.useState(true);

  React.useEffect(() => {
    //generate timestamp for recheck cache;
    const timestamp = Date.now();

    //Expire cache in 5 minutes and re-check for new data
    const expirey_timestamp = sub(Date.now(), { minutes: 5 }).getTime();

    //configure application settings partner wise.
    var partnerData = localStorage.getItem("appData");

    //get partner data from server
    async function getPartnerAccount() {
      if (partnerData == null) {
        setAppLoading(true);
      }
      try {
        const url =
          process.env.REACT_APP_NODE_ENV === "development"
            ? "/partner/details"
            : "/api/partner/details";
        const response = await _utils._get(url);
        process.env.REACT_APP_NODE_ENV === "development" &&
          console.log(response);
        if (response.status === 1 && response.data != null) {
          const res = response.data;

          //check whether host is valid or not
          let dev_host = window.location.hostname + ":3000";

          if (
            res != null &&
            res.hostname !== "" &&
            res.accountstatus === true &&
            (window.location.hostname === res.hostname ||
              window.location.hostname === res.baseDomain ||
              dev_host === res.hostname)
          ) {
            localStorage.setItem("appData", JSON.stringify(res));

            setAppData(res);
            document.title = res.title;
            handleFavicon(res.appIcon);
            setAppLoading(false);
            localStorage.setItem("timestamp", timestamp);
          } else if (res != null && res.accountstatus !== true) {
            setApplicationErrorMessage(
              "Service account is inactive. Please contact your service provider."
            );
            setAppLoading(false);
          } else if (
            res != null &&
            res.hostname !== window.location.hostname &&
            dev_host !== res.hostname
          ) {
            setApplicationErrorMessage("Unknown host! Something went wrong.");
            setAppLoading(false);
          } else {
            setApplicationErrorMessage(response.message);
            setAppLoading(false);
          }
        } else {
          setApplicationErrorMessage(response.message);
          setAppLoading(false);
        }
      } catch (e) {
        console.log(e.message);
        setApplicationErrorMessage(
          "Network Failed! Seems like having trouble in connecting to the server" +
            e.message
        );
        setAppLoading(false);
      }
    }

    if (partnerData != null) {
      const data = JSON.parse(partnerData);
      const cache_timestamp = parseInt(
        localStorage.getItem("timestamp") != null
          ? localStorage.getItem("timestamp")
          : 0
      );

      if (
        data.hostname !== "" &&
        data.accountstatus === true &&
        (window.location.hostname === data.hostname ||
        window.location.hostname === data.baseDomain ||
          window.location.hostname + ":3000" === data.hostname) &&
        expirey_timestamp < cache_timestamp
      ) {
        setAppData(data);
        document.title = data.title;
        handleFavicon(data.appIcon);
        setAppLoading(false);
        //localStorage.setItem('timestamp', timestamp);
      } else {
        getPartnerAccount();
      }
    } else {
      getPartnerAccount();
    }
  }, [location]);

  return (isAppLoading || appData === "" || appData === null) &&
    aplicationErrorMessage === "" ? (
    <Loading />
  ) : aplicationErrorMessage !== "" ? (
    <ApplicationErrorMessage key={aplicationErrorMessage}>
      {aplicationErrorMessage}
    </ApplicationErrorMessage>
  ) : (
    <RoutesComponent appData={appData} />
  );
}

export default App;
