import {
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";
import dt_user_request from "../../../../rest_api/dt_user_request";

export default function StandardCreateAgent(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message, user } =
    props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [firmName, setFirmName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [aadhaarNo, setAadhaarNo] = useState("");
  const [pancard, setPancard] = useState("");
  const [gstNumber, setGSTNumber] = useState("");
  const [txnpin, setTxnpin] = useState("");
  const [temp_pin, setTempPin] = useState("");
  const [password, setPassword] = useState("");
  const [isloading, setLoading] = useState(false);
  const [createAgentError, setCreateAgentError] = useState("");

  const navigate = useNavigate();

  const createAgentHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (
        firstName !== "" &&
        lastName !== "" &&
        mobile !== "" &&
        email !== "" &&
        dob !== "" &&
        firmName !== "" &&
        address !== "" &&
        city !== "" &&
        state !== "" &&
        pincode &&
        pincode.length === 6 &&
        txnpin &&
        txnpin.length === 4 &&
        temp_pin &&
        temp_pin.length === 4 &&
        password &&
        password.length > 7
      ) {
        if (user && user.account_type === "DT") {
          const data = {
            fname: firstName,
            lname: lastName,
            firm_name: firmName,
            agent_mobile: mobile,
            agent_email: email,
            dob: dob,
            address: address,
            city: city,
            state: state,
            pincode: pincode,
            pancard: pancard !== null ? pancard : "",
            aadhaarNo: aadhaarNo !== null ? aadhaarNo : "",
            gstNo: gstNumber !== null ? gstNumber : "",
            agent_txn_pin: temp_pin,
            agent_password: password,
          };

          const res = await dt_user_request.create_agent(data, txnpin);
          console.log(res);
          setLoading(false);
          if (res && res.isLoggedIn) {
            if (res.status === "1" || res.status === 1) {
              snackbar_message(res.message);
              open_snackbar(true);
              snackbar_severity("info");
              setCreateAgentError("");
              setFirstName("")
              setLastName("")
              setFirmName("")
              setMobile("")
              setEmail("")
              setDob("")
              setAddress("")
              setCity("")
              setState("")
              setPincode("")
              setAadhaarNo("")
              setPancard("")
              setGSTNumber("")
              setTxnpin("")
              setTempPin("")
              setPassword("")
            } else {
              setCreateAgentError(res.message);
              snackbar_message(res.message);
              open_snackbar(true);
              snackbar_severity("error");
            }
          } else {
            snackbar_message("Session Expire");
            open_snackbar(true);
            snackbar_severity("error");
            localStorage.clear();
            navigate("/login");
          }
        } else {
          snackbar_message("Session Expire");
          open_snackbar(true);
          snackbar_severity("error");
          localStorage.clear();
          navigate("/login");
          setLoading(false);
        }
      } else {
        alert(pincode.length);
        snackbar_message("Please fill empty fields");
        open_snackbar(true);
        snackbar_severity("error");
        setCreateAgentError("Please fill empty fields");
        setLoading(false);
      }
    } catch (e) {
      setCreateAgentError(e.message);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Create Agent
      </Typography>
      <Box mt={2}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          {createAgentError !== "" && createAgentError !== null && (
            <Box width={"100%"} px={3} pt={2} variant="h6" color="#f61606">
              <Typography>Error: {createAgentError}</Typography>
            </Box>
          )}
          <Box p={2} sx={{ display: "flex", flexWrap: "wrap" }}>
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="First Name"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Last Name"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={firmName}
              onChange={(event) => setFirmName(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Firm Name"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={mobile}
              type="number"
              onChange={(event) => setMobile(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Mobile Number"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Email ID"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={dob}
              helperText="Date of Birth"
              type="date"
              onChange={(event) => setDob(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Date of Birth"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Address"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={city}
              onChange={(event) => setCity(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="City"
            />
            <StandardStyledTextfield
              id="outlined-select-accountType"
              select
              label={state === "" ? "Select State" : ""}
              value={state}
              error={createAgentError !== ""}
              onChange={(event) => setState(event.target.value)}
              InputLabelProps={{ shrink: false }}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={" "}>Select State</MenuItem>
              <MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
              <MenuItem value="Assam">Assam</MenuItem>
              <MenuItem value="Bihar Jharkhand">Bihar Jharkhand</MenuItem>
              <MenuItem value="Chhattisgarh">Chhattisgarh</MenuItem>
              <MenuItem value="Delhi">Delhi</MenuItem>
              <MenuItem value="Gujarat">Gujarat</MenuItem>
              <MenuItem value="Haryana">Haryana</MenuItem>
              <MenuItem value="Himachal Pradesh">Himachal Pradesh</MenuItem>
              <MenuItem value="Jammu Kashmir">Jammu Kashmir</MenuItem>
              <MenuItem value="Karnataka">Karnataka</MenuItem>
              <MenuItem value="Kerala">Kerala</MenuItem>
              <MenuItem value="Kolkata">Kolkata</MenuItem>
              <MenuItem value="Madhya Pradesh">Madhya Pradesh</MenuItem>
              <MenuItem value="Maharashtra">Maharashtra</MenuItem>
              <MenuItem value="Mumbai">Mumbai</MenuItem>
              <MenuItem value="Orissa">Orissa</MenuItem>
              <MenuItem value="Punjab">Punjab</MenuItem>
              <MenuItem value="Rajasthan">Rajasthan</MenuItem>
              <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
              <MenuItem value="Uttar Pradesh">Uttar Pradesh</MenuItem>
              <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
              <MenuItem value="West Bengal">West Bengal</MenuItem>
            </StandardStyledTextfield>

            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={pincode}
              onChange={(event) => setPincode(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Pincode"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={aadhaarNo}
              type="number"
              onChange={(event) => setAadhaarNo(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Aadhaar Number"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={pancard}
              onChange={(event) => setPancard(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Pancard Number"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={gstNumber}
              onChange={(event) => setGSTNumber(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="GST Number"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={temp_pin}
              onChange={(event) => setTempPin(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Create Transaction Pin"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                mx: 1,
                mt: 1,
              }}
              placeholder="Create Password"
            />
            <StandardStyledTextfield
              variant="outlined"
              error={createAgentError !== ""}
              value={txnpin}
              onChange={(event) => setTxnpin(event.target.value)}
              sx={{
                borderColor: "#f0f0f0",
                width: "100%",
                maxWidth: { xs: "100%", sm: "230px", md: "230px" },
                m: 1,
              }}
              placeholder="Your Transaction Pin"
            />

          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", sm: "230px", md: "230px" },
              px: 3,
              alignSelf: "center",
            }}
          >
            {isloading ? (
              <CircularProgress />
            ) : (
              <StandardStyledButton
                customcolor={props.appData.buttonColor}
                customfontcolor={props.appData.buttonFontColor}
                p={"15px 16px"}
                onClick={createAgentHandler}
              >
                Submit
              </StandardStyledButton>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
