import _utlis from "../utils/netwk_utils";

const get_agent_stats = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/dt/agent/statistics"
        : "/api/dt/agent/statistics";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const get_agent_balance = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/dt/agent/balance/" + id
        : "/api/dt/agent/balance/" + id;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const create_agent = async (data, txnpin) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/dt/agent/create"
        : "/api/dt/agent/create";

    const res = await _utlis._post(url, data, {
      token: token,
      txn_pin: txnpin,
    });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const get_agents_list = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/dt/agent/list"
        : "/api/dt/agent/list";
    const res = await _utlis._get(url, { token: token });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const get_agent_package = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/dt/agent/package/" + id
        : "/api/dt/agent/package/" + id;
    const res = await _utlis._get(url, { token: token });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const agentFundTransfer = async (data, txn_pin) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/dt/agent/fund/transfer"
        : "/api/dt/agent/fund/transfer";
    const res = await _utlis._post(url, data, {
      token: token,
      txn_pin: txn_pin,
    });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const dt_user_request = {
  get_agent_stats,
  get_agent_balance,
  create_agent,
  get_agents_list,
  get_agent_package,
  agentFundTransfer,
};

export default dt_user_request;
