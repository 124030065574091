import { Box, CircularProgress, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import user_request from '../../../../rest_api/user_request';


export default function StandardNotificationPage(props) {
    const { appData } = props;

    const [notification, setNotification] = useState([]);
    const [isloading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {

        async function get_notifications() {
            const token = localStorage.getItem("token");
            const res = await user_request.get_notifications(token, 'one');

            if (res) {

                if (res.isLoggedIn) {
                    setLoading(false);
                    setNotification(res.notifications.length > 0 ? res.notifications : [])
                } else {
                    setNotification([]);
                    localStorage.clear();
                    navigate("/login")
                }
            }
        }

        get_notifications();

    }, [navigate])

    return (
        <Box >

            <Typography variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Notifications</Typography>
            <Box mt={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Paper sx={{ flexGrow: 1, overflow: 'hidden', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                    {
                        isloading ? <Box px={2} py={3}><CircularProgress /></Box> :
                            notification && notification.length > 0 ? <List>
                                {notification.map((note, index) => <ListItem divider={note.length > index + 1 ? true : false} key={note.id}>
                                    <ListItemText primary={note.message} secondary={'Date: ' + format(new Date(note.createdAt), "dd-MM-yyyy hh:mm:ss")} />
                                </ListItem>)}
                            </List> :
                                <Box px={2} py={3}>
                                    <Typography>You have no notification available. Please come back after some time.</Typography>
                                </Box>
                    }
                </Paper>
            </Box>
        </Box>
    )
}
