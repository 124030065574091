import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreditCardOutlined from '@mui/icons-material/CreditCardOutlined';
import FingerprintOutlined from '@mui/icons-material/FingerprintOutlined';
import PhoneIphoneOutlined from '@mui/icons-material/PhoneIphoneOutlined';
import TouchAppOutlined from '@mui/icons-material/TouchAppOutlined';
import { Avatar, Box, Divider, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import BBPSIcon from '../../../../global_component/BBPSIcon';
import TollIcon from '../../../../global_component/TollIcon';
import { useEffect } from 'react';
import { useState } from 'react';
import user_request from '../../../../rest_api/user_request';
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons"


function ServiceTab(props) {
    const { index, service, IconRef, appData, service_status } = props;
    const navigate = useNavigate();


    return <Grid key={'serviceKey' + index} item xs={2} sm={3} md={3} xlg={2}>
        <Paper sx={{ overflow: 'hidden', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, border: '1px solid #e9e9e9' }}>
            <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }} component="a" onClick={() => service_status && navigate(`/portal/${(service.replaceAll(" ", "-").toLowerCase())}`)}>
                <Avatar sx={{ bgcolor: appData.serviceIconBgColor !== undefined && appData.serviceIconBgColor !== null ? appData.serviceIconBgColor : '#8088f1', width: 60, height: 60 }}>{IconRef}</Avatar>
                <Typography align='center' mt={1} sx={{ fontWeight: '500' }}>{service}</Typography>
            </Box>
            <Divider />
            <Box p={2}>
                {

                    <Typography variant='body2' align="center">Service Status : {service_status ? <strong style={{ color: 'blue' }}>Active</strong> : !service_status ? <strong style={{ color: 'red' }}>Inactive</strong> : "Loading..."}</Typography>

                }
            </Box>
        </Paper>
    </Grid>;

}


export default function StandardEstore(props) {

    const { appData } = props;

    const [services, setServices] = useState();

    console.log(appData.services);
    const navigate = useNavigate();

    useEffect(() => {

        async function getServices() {

            const token = localStorage.getItem('token');

            if (token) {

                const res = await user_request.get_services(token);

                if (res.status === 1) {

                    // console.log(res);

                    setServices(res.services);
                    delete res.services.id;
                    localStorage.setItem('user_services', JSON.stringify(res.services));

                } else {

                    // snackbar_message(res.message);
                    // snackbar_severity("error");
                    // open_snackbar(true);

                    if (!res.isLoggedIn) {
                        localStorage.clear();
                        navigate('/login')
                    }

                }

            } else {
                localStorage.clear();
                navigate('/login')

            }
        }
        getServices();

    }, [navigate])


    return (
        <Box >
            <Typography variant='h6' component={'div'} mb={2} sx={{ color: `${appData.mainHeadingColor}` }}>E-Store</Typography>



            {/* Check whether services active or not */}

            {appData !== undefined && appData.services !== undefined && appData.services !== null && (appData.services.recharge || appData.services.dmr || appData.services.aeps || appData.services.matm || appData.services.fastag || appData.services.bbps) ?
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 9, md: 12, xlg: 12 }} sx={appData !== undefined && appData.services !== undefined && appData.services !== null ? {} : { justifyContent: 'start', alignItems: 'center' }}>
                    {
                        appData.services.recharge &&
                        <ServiceTab key={'serviceTabRecharge'} appData={appData} service_status={services && services.isRecharge === '1'} service={'Mobile Recharge'} index={0} IconRef={<PhoneIphoneOutlined fontSize="large" sx={{ color: appData !== undefined && appData !== null ? appData.serviceIconColor : 'white' }} />} />
                    }
                    {
                        appData.services.recharge && <ServiceTab key={'serviceTabDTHRecharge'} appData={appData} service_status={services && services.isRecharge === '1'} service={'DTH Recharge'} index={1} IconRef={<FontAwesomeIcon icon={solid('satellite-dish')} color={appData !== undefined && appData !== null ? appData.serviceIconColor : 'white'} />} />
                    }
                    {
                        appData.services.dmr && <ServiceTab key={'serviceTabdmt'} appData={appData} service_status={services && services.isDMR === '1'} service={'DMT'} index={2} IconRef={<FontAwesomeIcon icon={solid('paper-plane')} color={appData !== undefined && appData !== null ? appData.serviceIconColor : 'white'} />} />
                    }
                    {
                        appData.services.aeps && <ServiceTab key={'serviceTabAEPS'} appData={appData} service_status={services && services.isAEPS === '1'} service={'AEPS'} index={3} IconRef={<FingerprintOutlined fontSize="large" sx={{ color: appData !== undefined && appData !== null ? appData.serviceIconColor : 'white' }} />} />
                    }
                    {
                        appData.services.aadhaar_pay && <ServiceTab key={'serviceTabAadhaarPay'} appData={appData} service_status={services && services.isAadhaarPay === '1'} service={"Aadhaar Pay"} index={4} IconRef={<TouchAppOutlined fontSize="large" sx={{ color: appData !== undefined && appData !== null ? appData.serviceIconColor : 'white' }} />} />
                    }
                    {appData.services.bbps && <ServiceTab key={'serviceTabBBPS'} appData={appData} service_status={services && services.isBBPS === '1'} service={'BBPS'} index={5} IconRef={<BBPSIcon sx={{ color: appData !== undefined && appData !== null ? appData.serviceIconColor : 'white' }} />} />
                    }
                    {appData.services.bbps && <ServiceTab key={'serviceTabGooglePlay'} appData={appData} service_status={services && services.isBBPS === '1'} service={'Google Play Recharge'} index={5} IconRef={<FontAwesomeIcon icon={faGooglePlay} color={appData !== undefined && appData !== null ? appData.serviceIconColor : 'white'} />} />
                    }
                    {appData.services.fastag && <ServiceTab key={'serviceTabFAStag'} appData={appData} service_status={services && services.isFastag === '1'} service={'FAStag'} index={6} IconRef={<TollIcon sx={{ color: appData !== undefined && appData !== null ? appData.serviceIconColor : 'white' }} />} />
                    }
                    {appData.services.credit_card_bill && <ServiceTab key={'serviceTabCreditCardBill'} appData={appData} service_status={services && services.isCreditCardBill === '1'} service={'Credit Card Bill'} index={7} IconRef={<CreditCardOutlined sx={{ color: appData !== undefined && appData !== null ? appData.serviceIconColor : 'white' }} />} />
                    }
                    {appData.services.matm && <ServiceTab key={'serviceTabMATM'} appData={appData} service_status={services && services.isMATM === '1'} service={'MATM'} index={8} IconRef={<FontAwesomeIcon icon={solid('calculator')} color={appData !== undefined && appData !== null ? appData.serviceIconColor : 'white'} />} />
                    }
                    {
                        appData.services.pancard && <ServiceTab key={'serviceTabPANCard'} appData={appData} service_status={services && services.isPancard === '1'} service={'PAN Card'} index={9} IconRef={<FontAwesomeIcon icon={solid('id-card')} color={appData !== undefined && appData !== null ? appData.serviceIconColor : 'white'} />} />
                    }

                </Grid>
                :
                <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, border: '1px solid #e9e9e9' }}>
                    <Typography component={'div'} py={5} align='center'>No service available :  {JSON.stringify(appData.services.recharge)}</Typography>
                </Paper>
            }


        </Box>
    )
}
