import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import StandardStyledButton from '../../../../StandardCustomComponent/StandardStyledButton';


export default function StandardPancardPage(props) {
    const { appData } = props;
    // const [mobile, setMobile] = React.useState("")
    // const [error, setError] = React.useState([])
    // const [amount, setAmount] = React.useState("")
    // const [operator, setOperator] = React.useState(" ")
    const isAvailable = false;
    return (
        <Box >
            <Typography variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Create PAN Card</Typography>
            <Box mt={1} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Paper sx={{ flexGrow: 1, my: 1, py: 2, overflow: 'hidden', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                    {isAvailable ? <Box p={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <Typography align='center' variant='body1' sx={{ fontWeight: '500' }}>Generate PAN Card Creation Link</Typography>
                        <Box sx={{mx: 'auto', my: '20px'}}>
                            <StandardStyledButton>Generate Link</StandardStyledButton>
                        </Box>
                    </Box> : <Box sx={{p:2}}>Comming Soon!</Box>}
                </Paper>
            </Box>
        </Box>
    )
}
