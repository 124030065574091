import { Box, FormControl, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import React, { useState } from 'react'
import StandardStyledButton from '../../../../../StandardCustomComponent/StandardStyledButton';
import StandardStyledTextfield from '../../../../../StandardCustomComponent/StandardStyledTextfield'

export default function StandardMobilePostpaid(props) {
   // const {appData} = props;
    const [error, setError] = useState([]);
    const [operator, setOperator] = useState(" ");

    return (
        <Box p={2}>
            <Typography mb={2} sx={{ fontWeight: 500 }}>Mobile Postpaid</Typography>
            <Box sx={{ maxWidth: '600px', width: '100%' }} component={'form'} onSubmit={() => { }}>

                <Box>
                    <StandardStyledTextfield fullWidth placeholder='Mobile Number' />
                </Box>
                <Box mt={2}>
                    <FormControl fullWidth>
                        <Select id="standard-operator-select"
                            value={operator}
                            error={error.indexOf('operator') >= 0}
                            input={<OutlinedInput placeholder='Type' onChange={(e) => setOperator(e.target.value)} sx={{
                                '& fieldset': {
                                    borderRadius: '16px',
                                    borderColor: error.indexOf('operator') >= 0 ? 'error' : '#e9e9e9 !important',
                                    borderWidth: '1px'
                                }
                            }} />}>
                            <MenuItem value=" ">Select Operator</MenuItem>
                            <MenuItem value="Airtel">Airtel</MenuItem>
                            <MenuItem value="BSNL">BSNL</MenuItem>
                            <MenuItem value="Jio">Jio</MenuItem>
                            <MenuItem value="VI">VI</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <StandardStyledTextfield fullWidth placeholder='Amount' />
                </Box>
                <Box mt={2}>
                    <StandardStyledButton variant='outlined' customfontcolor={props.appData.buttonColor} customcolor={props.appData.buttonFontColor} p={"15px 16px"} onClick={(e) => setError('error')}>Fetch Bill</StandardStyledButton>
                </Box>
            </Box>
        </Box>
    )
}
