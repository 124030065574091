import { Avatar, Typography } from "@mui/material";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import TollIcon from "../../../../global_component/TollIcon";
import BBPSIcon from "../../../../global_component/BBPSIcon";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons"

const ServiceTab = (props) => {
  const { IconRef, title, link, bgcolor } = props;
  const navigate = useNavigate();

  return (
    <Box
      m={3}
      sx={{ cursor: "pointer", mr: { xs: 3, md: 3, lg: "3.0%", xl: "3.0%" } }}
      onClick={() => navigate(link)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "60px",
        }}
      >
        <Box mb={1}>
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor:
                bgcolor !== undefined && bgcolor !== null ? bgcolor : "#8088f1",
            }}
          >
            {IconRef}
          </Avatar>
        </Box>
        <Typography
          component={"div"}
          align="center"
          variant="caption"
          color="#000000"
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default function StandardEStoreServices(props) {
  const { appData, activeServices } = props;


  return (
    <Box py={1} px={1} sx={{ display: 'flex', alignItems: 'start', justifyContent: { xs: 'space-between', md: 'start' }, flexWrap: 'wrap' }}>
      {appData.services.recharge && activeServices.filter((service) => service === 'Recharge').length > 0 && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <PhoneIphoneOutlinedIcon
              sx={{
                color:
                  appData !== undefined && appData !== null
                    ? appData.serviceIconColor
                    : "white",
              }}
            />
          }
          title={"Mobile Recharge"}
          link={"/portal/mobile-recharge"}
        />
      )}
      {appData.services.recharge && activeServices.filter((service) => service === 'Recharge').length > 0  && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <FontAwesomeIcon
              icon={solid("satellite-dish")}
              color={
                appData !== undefined && appData !== null
                  ? appData.serviceIconColor
                  : "white"
              }
            />
          }
          title={"DTH Recharge"}
          link={"/portal/dth-recharge"}
        />
      )}
      {appData.services.dmr && activeServices.filter((service) => service === 'DMT').length > 0  && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <FontAwesomeIcon
              icon={solid("paper-plane")}
              color={
                appData !== undefined && appData !== null
                  ? appData.serviceIconColor
                  : "white"
              }
            />
          }
          title={"DMT"}
          link={"/portal/dmt"}
        />
      )}
      {appData.services.aeps && activeServices.filter((service) => service === 'AEPS').length > 0  && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <FingerprintOutlinedIcon
              sx={{
                color:
                  appData !== undefined && appData !== null
                    ? appData.serviceIconColor
                    : "white",
              }}
            />
          }
          title={"AEPS"}
          link={"/portal/aeps"}
        />
      )}
      {appData.services.aadhaar_pay && activeServices.filter((service) => service === 'Aadhaar Pay').length > 0 && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <TouchAppOutlinedIcon
              sx={{
                color:
                  appData !== undefined && appData !== null
                    ? appData.serviceIconColor
                    : "white",
              }}
            />
          }
          title={"Aadhaar Pay"}
          link={"/portal/aadhaar-pay"}
        />
      )}
      {appData.services.bbps && activeServices.filter((service) => service === 'BBPS').length > 0  && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <BBPSIcon
              sx={{
                color:
                  appData !== undefined && appData !== null
                    ? appData.serviceIconColor
                    : "white",
              }}
            />
          }
          title={"BBPS"}
          link={"/portal/bbps"}
        />
      )}
      {appData.services.bbps && activeServices.filter((service) => service === 'BBPS').length > 0  && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
         
            <FontAwesomeIcon icon={faGooglePlay} color={appData !== undefined && appData !== null ? appData.serviceIconColor : 'white'} />
          }
          title={"Google Play Recharge"}
          link={"/portal/google-play-recharge"}
        />
      )}
      {appData.services.fastag && activeServices.filter((service) => service === 'FAStag').length > 0 && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <TollIcon
              sx={{
                color:
                  appData !== undefined && appData !== null
                    ? appData.serviceIconColor
                    : "white",
              }}
            />
          }
          title={"FAStag Recharge"}
          link={"/portal/fastag"}
        />
      )}
      {appData.services.credit_card_bill && activeServices.filter((service) => service === 'CC Bill').length > 0 && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <CreditCardOutlinedIcon
              sx={{
                color:
                  appData !== undefined && appData !== null
                    ? appData.serviceIconColor
                    : "white",
              }}
            />
          }
          title={"Credit Card Bill"}
          link={"/portal/credit-card-bill"}
        />
      )}

      {appData.services.matm && activeServices.filter((service) => service === 'MATM').length > 0  && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <FontAwesomeIcon
              icon={solid("calculator")}
              color={
                appData !== undefined && appData !== null
                  ? appData.serviceIconColor
                  : "white"
              }
            />
          }
          title={"MATM"}
          link={"/portal/matm"}
        />
      )}

      {appData.services.matm && activeServices.filter((service) => service === 'PAN Card').length > 0  && (
        <ServiceTab
          bgcolor={appData.serviceIconBgColor}
          IconRef={
            <BadgeOutlinedIcon
            sx={{
              color:
                appData !== undefined && appData !== null
                  ? appData.serviceIconColor
                  : "white",
            }}
          />
          }
          title={"PAN Card"}
          link={"/portal/pan-card"}
        />
      )}

      <ServiceTab
        bgcolor={appData.serviceIconBgColor}
        IconRef={
          <MoreHorizOutlinedIcon
            sx={{
              color:
                appData !== undefined && appData !== null
                  ? appData.serviceIconColor
                  : "white",
            }}
          />
        }
        title={"View More"}
        link={"/portal/e-store"}
      />
    </Box>
  );
}
