import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import StandardTabPanel from "../AddMoney/StandardTabPanel";
import StandardKycDetails from "./StandardKycDetails";
import StandardKycDocuments from "./StandardKycDocuments";

const handlerTabs = (index) => {
  return {
    id: `standard-tab-${index}`,
    "aria-controls": `standard-tabpanel-${index}`,
  };
};

export default function StandardKYCPage(props) {
  const { appData, user } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const formHandler = (event) => {
  //     event.preventDefault();
  // }

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        KYC
      </Typography>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Paper sx={{ display: "grid" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="add money tabs"
            >
              <Tab label="KYC Details" {...handlerTabs(0)} />
              <Tab label="Documents" {...handlerTabs(1)} />
            </Tabs>
          </Paper>
          <Box p={2}>
            <StandardTabPanel value={value} index={0}>
              <StandardKycDetails user={user} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={1}>
              <StandardKycDocuments user={user} />
            </StandardTabPanel>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
