import React from "react";
import { Box, Typography } from "@mui/material";
import { format } from 'date-fns';

export default function StandardKycDetails(props) {
  const { user } = props;
  return (
    <>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">First Name</Typography>
          <Typography>{user.fname}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Last Name</Typography>
          <Typography>{user.lname}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Firm Name</Typography>
          <Typography>{user.firmName}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Account Type</Typography>
          <Typography>
            {user.account_type === "RT"
              ? "Retailer"
              : user.account_type === "DT"
              ? "Distributor"
              : user.account_type === "API"
              ? "API Partner"
              : user.account_type}
          </Typography>
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Email</Typography>
          <Typography>{user.email ? user.email : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Mobile Number</Typography>
          <Typography>{user.mobile ? user.mobile : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">DOB</Typography>
          <Typography>{user.dob ? format(new Date(user.dob), 'dd-MM-yyyy') : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Distributor</Typography>
          <Typography>{user.distributor_id ? user.distributor_id : "-"}</Typography>
        </Box>
      </Box>

      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Address</Typography>
          <Typography>{user.address ? user.address : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">City</Typography>
          <Typography>{user.city ? user.city : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">State</Typography>
          <Typography>{user.statename ? user.statename : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Pincode</Typography>
          <Typography>{user.pincode ? user.pincode : "-"}</Typography>
        </Box>
      </Box>

      <Box
        px={2}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">
            {user.address_proof_type && user.address_proof_type !== ""
              ? user.address_proof_type
              : "Aadhaar Card"}
          </Typography>
          <Typography>{user.aadhaar ? user.aadhaar : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">PAN Card</Typography>
          <Typography>{user.pancard ? user.pancard : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">GST Number</Typography>
          <Typography>{user.gst_no ? user.gst_no : "-"}</Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">Account Status</Typography>
          <Typography>{user.account_status === "1" ? "Active" : "Inactive"}</Typography>
        </Box>
      </Box>
    </>
  );
}
