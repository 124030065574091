import React from 'react'
import { Route, Routes } from "react-router-dom";
import StandardAddMoney from '../layouts/standard/private/AddMoney/StandardAddMoney';
import StandardDashboard from '../layouts/standard/private/Dashboard/StandardDashboard';
import StandardEstore from '../layouts/standard/private/eStore/StandardEstore';
import StandardDmtConfirmationPage from '../layouts/standard/private/eStore/StoreServices/dmt/StandardDmtConfirmationPage';
import StandardConfirmRechargeScreen from '../layouts/standard/private/eStore/StoreServices/recharge/StandardConfirmRechargeScreen';
import StandardDmtPage from '../layouts/standard/private/eStore/StoreServices/dmt/StandardDmtPage';
import StandardDthRecharge from '../layouts/standard/private/eStore/StoreServices/recharge/StandardDthRecharge';
import StandardMobileRecharge from '../layouts/standard/private/eStore/StoreServices/recharge/StandardMobileRecharge';
import StandardRequestEvalue from '../layouts/standard/private/RequestEvalue/StandardRequestEvalue';
import StandardAuthGuard from '../layouts/standard/private/StandardAuthGuard';
import PageNotFound from '../layouts/standard/public/PageNotFound';
import StandardHomePage from '../layouts/standard/public/StandardHomePage';
import StandardLoginPage from '../layouts/standard/public/StandardLoginPage';
import StandardResetPassword from '../layouts/standard/public/StandardResetPassword';
import StandardAepsPage from '../layouts/standard/private/eStore/StoreServices/aeps/StandardAepsPage';
import StandardAadhaarPayPage from '../layouts/standard/private/eStore/StoreServices/aeps/StandardAadhaarPayPage';
import StandardBBPSPage from '../layouts/standard/private/eStore/StoreServices/bbps/StandardBBPSPage';
import StandardMatmPage from '../layouts/standard/private/eStore/StoreServices/matm/StandardMatmPage';
import StandardFastagPage from '../layouts/standard/private/eStore/StoreServices/Fastag/StandardFastagPage';
import StandardCreditCardBill from '../layouts/standard/private/eStore/StoreServices/CreditCardBill/StandardCreditCardBill';
import StandardOffersPage from '../layouts/standard/private/Offers/StandardOffersPage';
import StandardKYCPage from '../layouts/standard/private/KYC/StandardKYCPage';
import StandardPayoutPage from '../layouts/standard/private/Payout/StandardPayoutPage';
import StandardHistoryPage from '../layouts/standard/private/History/StandardHistoryPage';
import StandardGstInvoicePage from '../layouts/standard/private/GST/StandardGstInvoicePage';
import StandardHelpPage from '../layouts/standard/private/Help/StandardHelpPage';
import StandardNotificationPage from '../layouts/standard/private/Notification/StandardNotificationPage';
import StandardAddFundingAccount from '../layouts/standard/private/AddMoney/StandardAddFundingAccount';
import StandardPancardPage from '../layouts/standard/private/eStore/StoreServices/Pancard/StandardPancardPage';
import StandardRechargeReceipt from '../layouts/standard/private/eStore/StoreServices/recharge/StandardRechargeReceipt';
import StandardDmtTransactionResponse from './../layouts/standard/private/eStore/StoreServices/dmt/StandardDmtTransactionResponse';
import StandardAgentList from '../layouts/standard/private/Agent/StandardAgentList';
import StandardCreateAgent from '../layouts/standard/private/Agent/StandardCreateAgent';
import StandardBBPSConfirmBillPage from '../layouts/standard/private/eStore/StoreServices/bbps/StandardBBPSConfirmBillPage';
import StandardBBPSBillReceipt from '../layouts/standard/private/eStore/StoreServices/bbps/StandardBBPSBillReceipt';
import StandardGooglePlayRechargePage from '../layouts/standard/private/eStore/StoreServices/bbps/StandardGooglePlayRechargePage';
import StandardFastagReceipt from '../layouts/standard/private/eStore/StoreServices/Fastag/StandardFastagReceipt';
import StandardReceiptPage from '../layouts/standard/private/History/StandardReceiptPage';
import StandardChangePassword from '../layouts/standard/private/Settings/StandardChangePassword';
import StandardChangePinPage from '../layouts/standard/private/Settings/StandardChangePinPage';
import StandardAgentDetails from '../layouts/standard/private/Agent/StandardAgentDetails';

export default function StandardLayoutRoutes(props) {
    return (
        <Routes>

            <Route exact path="/portal/dashboard" element={<StandardAuthGuard Page={StandardDashboard} access_type={"All"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/request-e-value" element={<StandardAuthGuard Page={StandardRequestEvalue} access_type={"All"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/add-money" element={<StandardAuthGuard Page={StandardAddMoney} access_type={"All"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/e-store" element={<StandardAuthGuard Page={StandardEstore} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/mobile-recharge" element={<StandardAuthGuard Page={StandardMobileRecharge}  access_type={"RT"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/dth-recharge" element={<StandardAuthGuard Page={StandardDthRecharge} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/dmt" element={<StandardAuthGuard Page={StandardDmtPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/dmt/confirm" element={<StandardAuthGuard Page={StandardDmtConfirmationPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/dmt/receipt" element={<StandardAuthGuard Page={StandardDmtTransactionResponse} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/recharge/confirm" element={<StandardAuthGuard Page={StandardConfirmRechargeScreen} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/recharge/receipt" element={<StandardAuthGuard Page={StandardRechargeReceipt} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/aeps" element={<StandardAuthGuard Page={StandardAepsPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/aadhaar-pay" element={<StandardAuthGuard Page={StandardAadhaarPayPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/google-play-recharge" element={<StandardAuthGuard Page={StandardGooglePlayRechargePage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/bbps" element={<StandardAuthGuard Page={StandardBBPSPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/bbps/confirm/bill" element={<StandardAuthGuard Page={StandardBBPSConfirmBillPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/bbps/bill/receipt" element={<StandardAuthGuard Page={StandardBBPSBillReceipt} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/matm" element={<StandardAuthGuard Page={StandardMatmPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/fastag" element={<StandardAuthGuard Page={StandardFastagPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/fastag/receipt" element={<StandardAuthGuard Page={StandardFastagReceipt} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/credit-card-bill" element={<StandardAuthGuard Page={StandardCreditCardBill} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/pan-card" element={<StandardAuthGuard Page={StandardPancardPage} access_type={"RT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/my-offers" element={<StandardAuthGuard Page={StandardOffersPage} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/kyc" element={<StandardAuthGuard Page={StandardKYCPage} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/payout" element={<StandardAuthGuard Page={StandardPayoutPage} access_type={"RT-API"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/history" element={<StandardAuthGuard Page={StandardHistoryPage} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/gst-invoice" element={<StandardAuthGuard Page={StandardGstInvoicePage} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/help" element={<StandardAuthGuard Page={StandardHelpPage} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/notifications" element={<StandardAuthGuard Page={StandardNotificationPage} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/add_funding_account" element={<StandardAuthGuard Page={StandardAddFundingAccount} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/receipt" element={<StandardAuthGuard Page={StandardReceiptPage} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/change_password" element={<StandardAuthGuard Page={StandardChangePassword} access_type={"All"} {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/change_pin" element={<StandardAuthGuard Page={StandardChangePinPage} access_type={"All"} {...props}></StandardAuthGuard>} />
           
            {/* Distributors Route */}
            <Route exact path="/portal/agent/list" element={<StandardAuthGuard Page={StandardAgentList} access_type={"DT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/agent/details" element={<StandardAuthGuard Page={StandardAgentDetails} access_type={"DT"}  {...props}></StandardAuthGuard>} />
            <Route exact path="/portal/agent/new" element={<StandardAuthGuard Page={StandardCreateAgent} access_type={"DT"}  {...props}></StandardAuthGuard>} />

            {/*Public Routes*/}
            <Route exact path="/login" element={<StandardLoginPage {...props} />} />
            <Route exact path="/reset_password" element={<StandardResetPassword {...props} />} />
            <Route exact path="/" element={<StandardHomePage {...props} />} />
            <Route exact path="*" element={<PageNotFound {...props} />} />
        </Routes>
    )
}
