import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";
import dmt_request from "../../../../rest_api/dmt_request";
import { useNavigate } from "react-router-dom";
import payout_request from "../../../../rest_api/payout_request";

export default function StandardAddPayoutAccount(props) {
  const {
    setView,
    setBeneficiary,
    open_snackbar,
    snackbar_severity,
    snackbar_message,
  } = props;

  const navigate = useNavigate();

  const [bankList, setBankList] = React.useState([" "]);

  const [isloading, setLoading] = React.useState(false);
  const [accountType, setAccType] = React.useState("Saving");
  const [account, setAccount] = React.useState("");
  const [bank, setBank] = React.useState(" ");
  const [ifsc, setIfsc] = React.useState("");
  const [upi_id, setUPI_id] = React.useState("");
  const [error, setError] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [mpin, setMPIN] = React.useState("");

  React.useEffect(() => {
    async function getBanklist() {
      try {
        const token = localStorage.getItem("token");
        setLoading(true);
        const res = await dmt_request.getBankList(token);
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              setError([]);
              const list = res.list;
              list.unshift(" ");
              var bankList = [...new Set(list)];
              setBankList(bankList);
            } else {
              setErrorMsg(res.message);
            }
          } else {
            setErrorMsg(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setLoading(false);
          setErrorMsg("Network Error");
        }
      } catch (e) {
        setLoading(false);
        setErrorMsg(e.message);
      }
    }

    getBanklist();
  }, [navigate]);

  React.useEffect(() => {
    if (errorMsg !== "") {
      open_snackbar(true);
      snackbar_message(errorMsg);
      snackbar_severity("error");
    }
  }, [errorMsg, open_snackbar, snackbar_message, snackbar_severity]);

  const add_beneficiary_handler = async () => {
    try {
      if (
        ((accountType === "UPI" && upi_id && upi_id.length > 5) ||
          (accountType !== "UPI" &&
            account &&
            ifsc &&
            bank &&
            account.length > 7 &&
            ifsc.length === 11 &&
            bank.length > 2)) &&
        mpin &&
        mpin.length === 4
      ) {
        setError([]);
        var data = {};
        if (accountType !== "UPI") {
          data = {
            account: account,
            bank: bank,
            ifsc: ifsc,
            accountType: accountType,
            mpin: mpin,
          };
        } else {
          data = {
            upi_id: "test@upi",
            mpin: "1234",
          };
        }
        setLoading(true);
        const res = await payout_request.addPayoutBeneficiary(data);
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              snackbar_severity("info");
            } else {
              snackbar_severity("error");
            }
            open_snackbar(true);
            snackbar_message(res.message);

            if (res.status === 1) {
              window.location.reload();
            }
          } else {
            setErrorMsg(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setErrorMsg("No Response Key");
        }
      } else {
        var errorObj = [];
        if (
          accountType === "UPI" &&
          (upi_id === null || upi_id === undefined || upi_id.length < 5)
        ) {
          alert(upi_id);
          errorObj.push("UPI ID");
        }

        if (mpin === null || mpin === undefined || mpin.length !== 4) {
          errorObj.push("mpin");
        }

        if (
          accountType !== "UPI" &&
          (account === null || account === undefined || account.length < 8)
        ) {
          errorObj.push("Account");
        }
        if (
          accountType !== "UPI" &&
          (ifsc === null || ifsc === undefined || ifsc.length !== 11)
        ) {
          errorObj.push("IFSC");
        }

        if (
          accountType !== "UPI" &&
          (bank === null || bank === undefined || bank.length < 3)
        ) {
          errorObj.push("Bank");
        }

        setError(errorObj);
      }
    } catch (e) {
      setLoading(false);
      setErrorMsg(e.message);
    }
  };

  return (
    <Box p={2}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => {
            setView("");
            setBeneficiary("");
          }}
        >
          <ArrowBackIcon />
        </IconButton>{" "}
        <Typography sx={{ fontWeight: 500 }}> Add Account</Typography>
      </Box>
      <Box px={1} pt={1} pb={2}>
        <Typography variant="caption" sx={{ fontWeight: 500 }}>
          Note:-
        </Typography>
        <Typography variant="caption">
          {" "}
          Your account name should be matched with your profile. We will
          validate it via bank before adding into payout account list. There
          will be minimal charges for validating account from bank side.
        </Typography>
      </Box>

      <Box px={1} component={"form"}>
        <Box>
          <Typography variant="caption">Select Account Type</Typography>
          <Box pt={1}>
            <StandardStyledButton
              onClick={() => setAccType("Saving")}
              size="small"
              variant={accountType === "Saving" ? "contained" : "outlined"}
              disabled={isloading}
              customcolor={
                accountType === "Saving"
                  ? props.appData.buttonColor
                  : props.appData.buttonFontColor
              }
              customfontcolor={
                accountType === "Saving"
                  ? props.appData.buttonFontColor
                  : props.appData.buttonColor
              }
            >
              Saving
            </StandardStyledButton>
            <StandardStyledButton
              size="small"
              onClick={() => setAccType("Current")}
              disabled={isloading}
              customcolor={
                accountType === "Current"
                  ? props.appData.buttonColor
                  : props.appData.buttonFontColor
              }
              customfontcolor={
                accountType === "Current"
                  ? props.appData.buttonFontColor
                  : props.appData.buttonColor
              }
              sx={{ mx: 1 }}
              variant={accountType === "Current" ? "contained" : "outlined"}
            >
              Current
            </StandardStyledButton>
            <StandardStyledButton
              onClick={() => setAccType("UPI")}
              size="small"
              disabled={isloading}
              customcolor={
                accountType === "UPI"
                  ? props.appData.buttonColor
                  : props.appData.buttonFontColor
              }
              customfontcolor={
                accountType === "UPI"
                  ? props.appData.buttonFontColor
                  : props.appData.buttonColor
              }
              variant={accountType === "UPI" ? "contained" : "outlined"}
            >
              UPI
            </StandardStyledButton>
          </Box>
        </Box>
        {accountType !== "UPI" && (
          <>
            <Box py={2}>
              <StandardStyledTextfield
                type={"number"}
                fullWidth
                error={error.indexOf("Account") >= 0}
                label={"Account Number"}
                value={account}
                onChange={(e) =>
                  e.target.value.length <= 20 && setAccount(e.target.value)
                }
              />
            </Box>
            <Box py={1}>
              <FormControl fullWidth>
                <Select
                  id="standard-bankName-select"
                  value={bank}
                  error={error.indexOf("Bank") >= 0}
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          borderRadius: "16px",
                          borderColor:
                            error.indexOf("Bank") >= 0
                              ? "error"
                              : "#e9e9e9 !important",
                          borderWidth: "1px",
                        },
                      }}
                    />
                  }
                  onChange={(e) => setBank(e.target.value)}
                >
                  {bankList.map((opt) =>
                    opt === " " ? (
                      <MenuItem key={"selectBank"} value={" "}>
                        Select Bank
                      </MenuItem>
                    ) : (
                      <MenuItem key={"bank" + opt} value={opt}>
                        {opt}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box py={2}>
              <StandardStyledTextfield
                fullWidth
                label={"IFSC Code"}
                error={error.indexOf("IFSC") >= 0}
                value={ifsc}
                onChange={(e) =>
                  e.target.value.length <= 11 &&
                  setIfsc(e.target.value.toLocaleUpperCase())
                }
              />
            </Box>
          </>
        )}
        {accountType === "UPI" && (
          <Box py={2}>
            <StandardStyledTextfield
              fullWidth
              label={"UPI ID"}
              error={error.indexOf("UPI ID") >= 0}
              value={upi_id}
              onChange={(e) => setUPI_id(e.target.value)}
            />
          </Box>
        )}
        <Box py={1}>
          <StandardStyledTextfield
            fullWidth
            label={"MPIN (4 Digit)"}
            value={mpin}
            error={error.indexOf("mpin") >= 0}
            type={"number"}
            onChange={(e) =>
              e.target.value.length <= 4 && setMPIN(e.target.value)
            }
          />
        </Box>
        {isloading ? (
          <CircularProgress />
        ) : (
          <StandardStyledButton
            p={"15px 16px"}
            disabled={isloading}
            customcolor={props.appData.buttonColor}
            customfontcolor={props.appData.buttonFontColor}
            variant={"contained"}
            onClick={add_beneficiary_handler}
          >
            Submit
          </StandardStyledButton>
        )}
      </Box>
    </Box>
  );
}
