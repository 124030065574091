import React from 'react'
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
    Divider,
    Box,
    Typography,
} from "@mui/material";

import StandardBeneficiaryTable from "../StandardBeneficiaryTable";
import StandardStyledButton from '../../../../../StandardCustomComponent/StandardStyledButton';

export default function StandardBeneficiaryList(props) {

    const { showNewBeneComponent, appData, beneList,  walletDetails } = props;

    const tableColumn = ["", "NAME", "ACCOUNT", "BANK", "IFSC", "STATUS", "ACTION"];
    const beneColumn = ["id", "bene_name", "bene_bank", "bene_ifsc", "bene_account", "is_verified", "action"];

    return (
        <Box mt={1}>
            <Box
                px={3}
                mb={1}
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <Typography sx={{ fontWeight: "600" }}>
                    Beneficiary List
                </Typography>
                <StandardStyledButton
                    customcolor={appData.buttonFontColor}
                    customfontcolor={appData.buttonColor}
                    startIcon={<AddOutlinedIcon />}
                    sx={{ ml: 1 }}
                    variant="outlined"
                    p={"8px 10px"}
                    onClick={() => showNewBeneComponent(true)}
                >
                    {" "}
                    <Typography sx={{ lineHeight: "1.0" }} variant="caption">
                        {" "}
                        Beneficiary
                    </Typography>
                </StandardStyledButton>
            </Box>
            {beneList !== null && beneList.length > 0 ? (
                <Box sx={{ display: "grid" }}>
                    <Divider />
                    <StandardBeneficiaryTable
                        table={"beneficiary"}
                        tableColumn={tableColumn}
                        rows={beneList}
                        column={beneColumn}
                        walletDetails={walletDetails}
                        {...props}
                    />
                </Box>
            ) : (
                <Box
                    py={5}
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography align="center" mb={2}>
                        No Beneficiary Added
                    </Typography>
                    <StandardStyledButton
                        startIcon={<AddOutlinedIcon />}
                        customcolor={appData.buttonFontColor}
                        customfontcolor={appData.buttonColor}
                        variant="outlined"
                        p={"4px 10px"}
                        onClick={() => showNewBeneComponent(true)}
                    >
                        Create New Beneficiary
                    </StandardStyledButton>
                </Box>
            )}
        </Box>
    )
}
