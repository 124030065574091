import { SvgIcon } from '@mui/material';
import React from 'react'

const TollIcon = (props) => {

    return <SvgIcon {...props} version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000"
        preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
            stroke="none">
            <path d="M217 233 c-4 -3 -7 -13 -7 -21 0 -12 3 -12 15 -2 8 7 15 16 15 21 0
    11 -14 12 -23 2z"/>
            <path d="M177 190 c-24 -29 -21 -48 4 -22 11 13 19 27 17 33 -2 5 -11 0 -21
    -11z"/>
            <path d="M11 117 c1 -29 -1 -59 -5 -65 -5 -8 4 -12 28 -12 l36 0 0 65 0 65
    -31 0 -30 0 2 -53z m37 36 c-9 -2 -19 -12 -21 -21 -3 -12 -5 -10 -6 6 -1 17 4
    22 22 21 15 -1 17 -3 5 -6z"/>
            <path d="M135 151 c-5 -10 -5 -16 3 -19 6 -2 8 -10 5 -18 -9 -25 17 -45 57
    -42 32 1 37 5 35 23 -4 37 -15 47 -51 43 -24 -3 -34 0 -36 12 -3 13 -5 13 -13
    1z m85 -36 c0 -10 -10 -15 -30 -15 -20 0 -30 5 -30 15 0 10 10 15 30 15 20 0
    30 -5 30 -15z"/>
            <path d="M178 113 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M97 110 c-18 -22 -23 -70 -7 -70 6 0 10 13 10 29 0 16 5 31 12 33 6
    2 9 10 7 16 -3 8 -10 6 -22 -8z"/>
            <path d="M0 15 c0 -12 20 -15 120 -15 100 0 120 3 120 15 0 13 -20 15 -120 15
    -100 0 -120 -2 -120 -15z"/>
        </g></SvgIcon>

}


export default TollIcon;
