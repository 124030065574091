import ArrowBack from "@mui/icons-material/ArrowBack";
import {
    FormControl,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    Box,
    Typography,
    CircularProgress
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dmt_request from "../../../../../../../rest_api/dmt_request";
import StandardStyledButton from "../../../../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../../../../StandardCustomComponent/StandardStyledTextfield";

export default function StandardAddBeneficiary(props) {

    const { showNewBeneComponent, reloadBeneList, setReloadBeneList, appData, open_snackbar, snackbar_severity, snackbar_message } = props;

    const navigate = useNavigate();
    const [isloading, setLoading] = React.useState(false);
    const [beneName, setBeneName] = React.useState("");
    const [account, setAccount] = React.useState("");
    const [ifsc, setIFSC] = React.useState("");
    const [mpin, setMPIN] = React.useState("");
    const [beneMobile, setBeneMobile] = React.useState("");
    const [bank, setBankName] = React.useState(" ");
    const [error, setError] = React.useState([]);
    const [bankList, setBankList] = React.useState([" "]);

    useEffect(() => {

        async function getBanklist() {
            const token = localStorage.getItem("token");

            const res = await dmt_request.getBankList(token);

            if (res) {

                if (res.isLoggedIn) {

                    if (res.status === 1) {
                        setError([]);
                        const list = res.list;
                        list.unshift(" ");
                        var bankList = [...new Set(list)];
                        setBankList(bankList);


                    } else {

                        setError(['bank']);

                    }


                } else {
                    localStorage.clear();
                    navigate('/login');

                }

            } else {

                setError(['Network Error']);

            }

        }

        getBanklist();

    }, [navigate])

    useEffect(() => {

        async function getbankifsc() {
            setIFSC("");
            const token = localStorage.getItem("token");

            const data = { "bank": bank };

            const res = await dmt_request.getBankIfsc(data, token);

            console.log(res);

            if (res) {

                if (res.isLoggedIn) {

                    if (res.status === 1) {

                        setIFSC(res.ifsc);

                    } else {

                        setError(res['message']);

                    }


                } else {
                    localStorage.clear();
                    navigate('/login');

                }

            } else {

                setError(['Network Error']);

            }

        }

        getbankifsc();

    }, [bank, navigate])


    const add_beneficiary = async () => {


        if (beneName && account && ifsc && mpin && bank && beneName.length > 2 && account.length > 7 && ifsc.length === 11 && mpin.length === 4) {

            const token = localStorage.getItem("token");
            const wallet = localStorage.getItem("wallet");
            const data = { name: beneName, wallet: wallet, account: account, ifsc: ifsc, bank: bank, mpin: mpin, bene_mobile: beneMobile };
            setLoading(true);
            const res = await dmt_request.add_new_beneficiary(data, token);
            setLoading(false);
            if (res) {

                if (res.isLoggedIn) {

                    if (res.status === 1 && res.wallet.status === 1) {
                        showNewBeneComponent(false);
                        setReloadBeneList(!reloadBeneList);
                        snackbar_message(res.wallet.message);
                        open_snackbar(true);
                        snackbar_severity("info");
                    } else {
                        snackbar_message(res.wallet.message);
                        open_snackbar(true);
                        snackbar_severity("error");
                    }

                } else {
                    snackbar_message(res.message);
                    open_snackbar(true);
                    snackbar_severity("error");
                    localStorage.clear();

                    navigate('/login');

                }


            } else {
                setError("Network Error:- Something went wrong");
            }


        } else {
            setError("Please fill the valid beneficiary details.");
        }


    }


    return (
        <Box px={3} pb={3} mt={1}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <IconButton
                    sx={{
                        mr: 1,
                        backgroundColor:
                            appData.buttonColor !== null
                                ? appData.buttonColor + "22"
                                : "primary22",
                    }}
                    onClick={() => showNewBeneComponent(false)}
                >
                    <ArrowBack />
                </IconButton>
                <Typography sx={{ fontWeight: "600" }}>
                    Create Beneficiary
                </Typography>
            </Box>
            <Box
                sx={{
                    px: { xs: 0, sm: 1 },
                    mt: 3,
                    maxWidth: "480px",
                    width: "100%",
                }}
                component={"form"}
            >
                {error}
                <Box component={"div"}>
                    <StandardStyledTextfield
                        fullWidth
                        value={beneName}
                        onChange={(e) => setBeneName(e.target.value)}
                        placeholder="Beneficiary Name"
                    />
                </Box>
                <Box my={2} component={"div"}>
                    <StandardStyledTextfield
                        fullWidth
                        value={account}
                        onChange={(e) => setAccount(e.target.value)}
                        placeholder="Account Number"
                    />
                </Box>
                <Box my={2} component={"div"}>
                    <FormControl fullWidth>
                        <Select
                            id="standard-bankName-select"
                            value={bank}
                            error={error.indexOf("bank") >= 0}
                            input={
                                <OutlinedInput
                                    sx={{
                                        "& fieldset": {
                                            borderRadius: "16px",
                                            borderColor:
                                                error.indexOf("bank") >= 0
                                                    ? "error"
                                                    : "#e9e9e9 !important",
                                            borderWidth: "1px",
                                        },
                                    }}
                                />
                            }
                            onChange={(e) => setBankName(e.target.value)}
                        >
                            {bankList.map((opt) =>
                                opt === " " ? (
                                    <MenuItem key={"selectBank"} value={" "}>
                                        Select Bank
                                    </MenuItem>
                                ) : (
                                    <MenuItem key={'bank' + opt} value={opt}>
                                        {opt}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box my={2} component={"div"}>
                    <StandardStyledTextfield
                        fullWidth
                        value={ifsc}
                        onChange={(e) => e.target.value.length <= 11 && setIFSC(e.target.value)}
                        placeholder="IFSC Code"
                    />
                </Box>
                <Box my={2} component={"div"}>
                    <StandardStyledTextfield
                        fullWidth
                        value={beneMobile}
                        onChange={(e) => e.target.value.length <= 10 && setBeneMobile(e.target.value)}
                        placeholder="Beneficiary Mobile Number (Optional)"
                    />
                </Box>
                <Box my={2} component={"div"}>
                    <StandardStyledTextfield
                        fullWidth
                        value={mpin}
                        type="password"
                        onChange={(e) => e.target.value.length <= 4 && setMPIN(e.target.value)}
                        placeholder="Enter wallet MPIN"
                    />
                </Box>
                {isloading ? <Box><CircularProgress /></Box> : <StandardStyledButton
                    variant="contained"
                    customcolor={props.appData.buttonColor}
                    customfontcolor={props.appData.buttonFontColor}
                    p={"15px 16px"}
                    onClick={add_beneficiary}
                >
                    Add Beneficiary
                </StandardStyledButton>
                }
            </Box>
        </Box>
    )
}
