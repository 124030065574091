import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";
import { useNavigate } from "react-router-dom";
import mobile_recharge_request from "../../../../../../rest_api/mobile_recharge_request";
import StandardPlansList from "./StandardPlansList";

export default function StandardMobileRecharge(props) {
  const { appData } = props;

  const [planLoading, setPlanLoading] = React.useState(false);
  const [PlansList, setPlansList] = React.useState([]);
  const [PlansKey, setPlansKey] = React.useState([]);
  const [mobile, setMobile] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [operator, setOperator] = React.useState(" ");
  const [circle, setCircle] = React.useState(" ");
  const optList = [" ", "Airtel", "BSNL", "Jio", "VI"];
  const circleList = [
    " ",
    "Andhra Pradesh",
    "Assam",
    "Bihar Jharkhand",
    "Delhi NCR",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu Kashmir",
    "Karnataka",
    "Kerala",
    "Kolkata",
    "Madhya Pradesh",
    "Chhattisgarh",
    "Maharashtra",
    "Mumbai",
    "North East",
    "Orissa",
    "Punjab",
    "Rajasthan",
    "Tamil Nadu",
    "UP East",
    "UP West",
    "West Bengal",
  ];
  const [error, setError] = React.useState([]);
  const [serviceType, setServiceType] = React.useState("Prepaid");

  const navigate = useNavigate();

  const SubmitRechargeForm = () => {
    if (
      mobile.length === 10 &&
      circle.length > 2 &&
      operator.length > 1 &&
      amount !== "" &&
      parseInt(amount) > 9
    ) {
      navigate("/portal/recharge/confirm", {
        state: {
          number: mobile,
          operator: operator,
          circle: circle,
          serviceType: serviceType,
          amount: amount,
        },
      });
      setError([]);
    } else {
      var newError = [];

      if (mobile.length !== 10) {
        newError.push("mobile");
      }

      if (amount === "" || parseInt(amount) < 10) {
        newError.push("amount");
      }

      if (circle.length < 2) {
        newError.push("circle");
      }

      if (operator.length < 2) {
        newError.push("operator");
      }

      setError(newError);
    }
  };

  useEffect(() => {
    setCircle(" ");
    setOperator(" ");
    async function get_opt() {
      if (mobile && mobile.length === 10) {
        try {
          const res = await mobile_recharge_request.get_operator(mobile);

          if (res && res.status === 1) {
            setOperator(res.operator);
            setCircle(res.circle);
          } else {
            if (!res.isLoggedIn) {
              localStorage.clear();

              navigate("/login");
            }
          }
        } catch (e) {}
      }
    }

    get_opt();
  }, [mobile, navigate]);

  useEffect(() => {
    async function get_plans() {
      if (
        mobile &&
        mobile.length === 10 &&
        operator &&
        operator !== " " &&
        circle &&
        circle !== " " &&
        serviceType &&
        serviceType === "Prepaid"
      ) {
        setPlanLoading(true);
        try {
          const res = await mobile_recharge_request.get_plans(operator, circle);
          setPlanLoading(false);
          if (res && res.status === 1) {
            setPlansList(res.plans);
            setPlansKey(res.keys);
          } else {
            if (!res.isLoggedIn) {
              localStorage.clear();

              navigate("/login");
            }
          }
        } catch (e) {
          setPlanLoading(false);
        }
      } else {
        setPlanLoading(false);
      }
    }

    get_plans();
  }, [circle, operator, mobile, navigate, serviceType]);

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Mobile Recharge
      </Typography>
      <Box
        mt={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            width: "100%",
            maxWidth: { xs: "100%", md: "48%" },
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box p={3} sx={{ maxHeight: "600px" }}>
            <Box component={"form"} onSubmit={SubmitRechargeForm}>
              <FormControl>
                <FormLabel id="servicType-radio-buttons-group-label">
                  Select Connection Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="servicType-radio-buttons-group-label"
                  name="servicType-radio-buttons-group"
                  value={serviceType}
                  onChange={(e) => setServiceType(e.target.value)}
                >
                  <FormControlLabel
                    value="Prepaid"
                    control={<Radio />}
                    label="Prepaid"
                  />
                  <FormControlLabel
                    value="Postpaid"
                    control={<Radio />}
                    label="Postpaid"
                  />
                </RadioGroup>
              </FormControl>
              <Box py={1}></Box>

              <StandardStyledTextfield
                type="number"
                error={error.indexOf("mobile") >= 0}
                value={mobile}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setMobile(e.target.value);
                  }
                }}
                fullWidth
                placeholder="Mobile Number"
              />
              <Box py={1}></Box>
              <FormControl fullWidth>
                <Select
                  id="standard-payment-mode-select"
                  value={operator}
                  error={error.indexOf("operator") >= 0}
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          borderRadius: "16px",
                          borderColor:
                            error.indexOf("operator") >= 0
                              ? "error"
                              : "#e9e9e9 !important",
                          borderWidth: "1px",
                        },
                      }}
                    />
                  }
                  onChange={(e) => setOperator(e.target.value)}
                >
                  {optList.map((opt) =>
                    opt === " " ? (
                      <MenuItem key={"selectOtp"} value={" "}>
                        Select Operator
                      </MenuItem>
                    ) : (
                      <MenuItem key={opt} value={opt}>
                        {opt}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <Box py={1}></Box>
              <FormControl fullWidth>
                <Select
                  id="standard-payment-mode-select"
                  value={circle}
                  error={error.indexOf("circle") >= 0}
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          borderRadius: "16px",
                          borderColor:
                            error.indexOf("circle") >= 0
                              ? "error"
                              : "#e9e9e9 !important",
                          borderWidth: "1px",
                        },
                      }}
                    />
                  }
                  onChange={(e) => setCircle(e.target.value)}
                >
                  {circleList.map((circle) =>
                    circle === " " ? (
                      <MenuItem key="circle" value={" "}>
                        Select Circle
                      </MenuItem>
                    ) : (
                      <MenuItem key={circle} value={circle}>
                        {circle}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <Box py={1}></Box>
              <StandardStyledTextfield
                type="number"
                error={error.indexOf("amount") >= 0}
                value={amount}
                onChange={(e) => {
                  if (e.target.value.length <= 5) {
                    setAmount(e.target.value);
                  }
                }}
                fullWidth
                placeholder="Amount"
              />
              <Box py={1}></Box>
              <StandardStyledButton
                variant="contained"
                customcolor={props.appData.buttonColor}
                customfontcolor={props.appData.buttonFontColor}
                p={"15px 16px"}
                onClick={SubmitRechargeForm}
              >
                Proceed
              </StandardStyledButton>
            </Box>
          </Box>
        </Paper>

        {/* Plan Details */}
        <Paper
          sx={{
            flexGrow: 1,
            display:
              mobile.length === 10 &&
              operator !== null &&
              operator !== " " &&
              operator !== ""
                ? "flex"
                : "none",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: { xs: "100%", md: "48%" },
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography py={3} align="center" sx={{ fontWeight: "500" }}>
              Browse Plans
            </Typography>
            <Divider />
          </Box>

          <Box sx={{ height: "100%", width: "100%" }}>
            {planLoading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "200px",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : PlansKey && PlansList && PlansKey.length > 0 ? (
              <StandardPlansList
                appData={appData}
                plans={PlansList}
                setAmount={setAmount}
                plankeys={PlansKey}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "200px",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                No plan available
              </Box>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              p: 3,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography variant="caption" align="center">
              Please double check your plan benefit on operators website. We
              will not be responsible for any mis-match in plan's benefit.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
