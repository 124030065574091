import encrypt from './ecrypt';
import decrypt from './decrypt';

import axios from 'axios';
//const decrypt = require('./decrypt');

async function _post(url, data, headers) {

    const appData = JSON.parse(localStorage.getItem('appData') || '{"sessionkey": null}');
    var encryptedPayload = await encrypt(JSON.stringify(data));
    var payload = process.env.REACT_APP_NODE_ENV === "development" ? JSON.parse(encryptedPayload) : { data: encryptedPayload }
    process.env.REACT_APP_NODE_ENV === "development" && console.log(payload);
    return axios.post(url, payload, {
        headers: { ...headers, key: process.env.REACT_APP_APIKEY, sessionkey: appData.session_key }
    }).then((response) => {
        if (response.status === 200) {

            if (response.data !== null || response.data !== undefined || response.data !== "") {
                process.env.REACT_APP_NODE_ENV === "development" && console.log(response.data);
                return process.env.REACT_APP_NODE_ENV === "development" ? response.data : JSON.parse(decrypt(response.data));
            } else {
                return { status: 2, message: "something went wrong" };
            }
        } else {
            return { status: 2, message: 'Internal Server Error.' };
        }
    }).catch((err) => {
        return { status: 2, message: 'Network Error', error: err.message };
    })

}



async function _get(url, headers) {

    const appData = JSON.parse(localStorage.getItem('appData') || '{"sessionkey": null}');
    return axios.get(url, {
        headers: { ...headers, key: process.env.REACT_APP_APIKEY, sessionkey: appData.session_key }
    }).then((response) => {
        process.env.REACT_APP_NODE_ENV === "development"  && console.log(response.data)
        process.env.REACT_APP_NODE_ENV === "development"  && console.log(url)
        if (response.status === 200) {
            if (response.data !== null || response.data !== undefined || response.data !== "") {
                process.env.REACT_APP_NODE_ENV === "development"  && console.log(response.data);
                process.env.REACT_APP_NODE_ENV === "development"  && console.log(url);
                const decrypted_data = process.env.REACT_APP_NODE_ENV === "development" || url === '/api/partner/details' ? response.data : JSON.parse(decrypt(response.data));
                process.env.REACT_APP_NODE_ENV === "development"  && console.log(decrypted_data);
                return decrypted_data;

            } else {
                return { status: 2, message: "something went wrong" };
            }
        } else {
            return { status: 2, message: 'Internal Server Error.' };
        }
    }).catch((err) => {
        process.env.REACT_APP_NODE_ENV === "development"  && console.log(err.message);
        return { status: 2, message: 'Network Error' };
    })

}
const _utlis = { _post, _get }

export default _utlis;
