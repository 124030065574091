import _utlis from "../utils/netwk_utils";

const get_operator = async (mobile) => {

    const token = localStorage.getItem("token");

    if (token) {

        if (mobile && mobile.length === 10) {
            const url = process.env.REACT_APP_NODE_ENV === 'development' ? '/recharge/mobile/operator/' + mobile : '/api/recharge/mobile/operator/' + mobile;

            const res = await _utlis._get(url, { token: token });
            return res;
        } else {
            return { status: 2, message: "Invalid mobile number", isLoggedIn: true };
        }
    } else {
        return { status: 2, message: "Authentication Failed", isLoggedIn: false };
    }
};


const get_plans = async (operator, circle) => {

    const token = localStorage.getItem("token");

    if (token) {

        const url = process.env.REACT_APP_NODE_ENV === 'development' ? '/recharge/mobile/plans/' + operator + '/' + circle : '/api/recharge/mobile/plans/' + operator.replaceAll(" ", "%20") + '/' + circle.replaceAll(" ", "%20");

        const res = await _utlis._get(url, { token: token });

        return res;
    } else {
        return { status: 2, message: "Authentication Failed", isLoggedIn: false };
    }
};


//Whitelist funding account
const transaction = async (data) => {

    const token = localStorage.getItem("token");
    if (token && data) {

        const url = process.env.REACT_APP_NODE_ENV === 'development' ? '/recharge/transaction' : '/api/recharge/transaction';
        const res = await _utlis._post(url, data, { token: token });

        return res;
    } else {
        return { status: 2, message: "Authentication failed", isLoggedIn: false };
    }
};



const mobile_recharge_request = { get_operator, get_plans, transaction };

export default mobile_recharge_request;
