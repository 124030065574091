import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React from "react";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import user_request from "../../../../rest_api/user_request";
import { useNavigate } from "react-router-dom";

export default function StandardChangePassword(props) {
  const { appData, open_snackbar, snackbar_message, snackbar_severity } = props;
  const [isloading, setLoading] = React.useState(false);
  const [current_password, setCurrentPassword] = React.useState("");
  const [new_password, setNewPassword] = React.useState("");
  const [new2_password, setNew2Password] = React.useState("");
  const [error, setError] = React.useState("");

  const navigate = useNavigate();

  const change_password_handler = async () => {
    if (
      current_password &&
      new_password &&
      new2_password &&
      current_password.length > 7 &&
      new_password.length > 7 &&
      new_password === new2_password
    ) {
      try {
        setLoading(true);
        const data = {
          current_password: current_password,
          new_password: new_password,
        };
        const res = await user_request.change_password(data);
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            setError("");
            snackbar_severity(res.status === 1 ? "info" : "error");
            snackbar_message(res.message);
            open_snackbar(true);
            setCurrentPassword("");
            setNewPassword("");
            setNew2Password("");
            navigate("/login");
          } else {
            setError(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError("No response key");
        }
      } catch (e) {
        setLoading(false);
        setError(e.message);
      }
    } else {
      setError(
        "Please fill the details currectly. Minimum 8 characters password required."
      );
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Change Password
      </Typography>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box p={2}>
            <Box sx={{ maxWidth: "400px", width: "100%" }}>
              {error && (
                <Box py={1} sx={{ color: "red" }}>
                  {error}
                </Box>
              )}
              <Box py={1}>
                <StandardStyledTextfield
                  type="password"
                  disabled={isloading}
                  fullWidth
                  label={"Current Password"}
                  value={current_password}
                  onChange={(e) =>
                    e.target.value.length <= 30 &&
                    setCurrentPassword(e.target.value)
                  }
                />
              </Box>
              <Box py={1}>
                <StandardStyledTextfield
                  fullWidth
                  disabled={isloading}
                  label={"Create New Password"}
                  type="password"
                  value={new_password}
                  onChange={(e) =>
                    e.target.value.length <= 30 &&
                    setNewPassword(e.target.value)
                  }
                />
              </Box>
              <Box py={1}>
                <StandardStyledTextfield
                  fullWidth
                  disabled={isloading}
                  label={"Confirm New Password"}
                  value={new2_password}
                  onChange={(e) =>
                    e.target.value.length <= 30 &&
                    setNew2Password(e.target.value)
                  }
                />
              </Box>
              <Box py={1}>
                {isloading ? (
                  <CircularProgress />
                ) : (
                  <StandardStyledButton
                    customcolor={props.appData.buttonColor}
                    customfontcolor={props.appData.buttonFontColor}
                    p={"15px 16px"}
                    onClick={change_password_handler}
                  >
                    Submit
                  </StandardStyledButton>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
