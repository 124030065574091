import styled from '@emotion/styled';
import { Button } from '@mui/material';


const StandardStyledButton = styled(Button)((props) => ({

    borderRadius: '16px',
    textTransform: 'none',
    whiteSpace: 'no-wrap',
    minWidth: 'fit-content',
    marginBottom: 'unset',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.20)',
    padding: props.p === undefined || props.p === null ? '10px 10px' : props.p,
    backgroundColor: props.customcolor != null ? props.customcolor : 'primary',
    color: props.customfontcolor != null ? props.customfontcolor : 'secondary',
    '&:hover': {
        borderColor: '#0062cc',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)',
        color: props.customfontcolor != null ? props.customfontcolor : 'secondary',
        backgroundColor: props.customcolor != null ? props.customcolor : 'primary',
    }

}));



export default StandardStyledButton