import styled from '@emotion/styled';
import { TextField } from '@mui/material';


const StandardStyledTextfield = styled(TextField)((props) => ({
    '& fieldset': {
        borderRadius: '16px',
        borderColor: props.error ? 'error' : '#e9e9e9 !important',
        borderWidth: '1px'
    },
    '& input[type=number]': {
        MozAppearance: 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    }
}));



export default StandardStyledTextfield