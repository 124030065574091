import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import { CircularProgress, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import user_request from '../../../../rest_api/user_request'
import StandardStyledButton from '../../StandardCustomComponent/StandardStyledButton'

export default function StandardWhiteListAccount(props) {

    const [isloading, setLoading] = useState(true);
    const [wa_list, setList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        //fecth user assign virtual account list from database

        const token = localStorage.getItem('token');

        if (token) {
            async function get_whitelisted_account() {
                const res = await user_request.get_wa(token);
                setLoading(false);

                if (res && res.status === 1 && res.wa_list && res.wa_list.length > 0) {
                    
                    setList(res.wa_list);
                 
                } else {
                    
                    setList([]);
                }
            }

            get_whitelisted_account();

        } else {
            localStorage.clear();
            navigate('/login');
        }


    }, [navigate])




    return (
        <Box>
            <Typography px={2} my={2} sx={{ fontWeight: 500 }}>Whitelisted Account List</Typography>

            {
                wa_list && wa_list.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>S.N</TableCell>
                                <TableCell>Bank Name</TableCell>
                                <TableCell>Account Holder</TableCell>
                                <TableCell>Account Number</TableCell>
                                <TableCell>IFSC Code</TableCell>
                                <TableCell>UPI ID</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                wa_list.map((ac, index) => {

                                    return <TableRow key={'wa' + index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{1 + index}</TableCell>
                                        <TableCell>{ac.bank_name === "" ? '-' : ac.bank_name}</TableCell>
                                        <TableCell>{ac.account_name}</TableCell>
                                        <TableCell>{ac.account_number === "" ? '-' : ac.account_number}</TableCell>
                                        <TableCell>{ac.ifsc === "" ? '-' : ac.ifsc}</TableCell>
                                        <TableCell>{ac.upi_id === "" ? '-' : ac.upi_id}</TableCell>
                                        <TableCell align="right">{ac.status === "0" ? <Typography variant={"body2"} color="error">Not verified</Typography> : 'Verified'}</TableCell>
                                    </TableRow>

                                })
                            }
                        </TableBody>
                    </Table> :
                    <Box p={3} pb={5}>{
                        isloading ?
                            <CircularProgress /> :
                            <Typography align='center' my={4} sx={{ fontWeight: 400, fontSize: '14px' }}>You don't have any whitelisted account yet.</Typography>
                    } </Box>

            }

            <Box sx={{ p: 2 }} mt={3}>
                <StandardStyledButton variant='outlined' customcolor={props.appData.buttonFontColor} customfontcolor={props.appData.buttonColor} onClick={() => { navigate('/portal/add_funding_account') }} startIcon={<AddCircleOutline />}> New Account</StandardStyledButton>
            </Box>
            <Box pt={3} pb={2}>
                <Divider />

                <Typography px={2} component={"div"} mt={2} variant='caption'><strong>Note:-</strong> You can only whitelist 20 bank accounts/UPI ID.</Typography>
            </Box>
        </Box>
    )
}
