import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";

export default function StandardCreditCardBill(props) {
  const { appData } = props;
  const [mobile, setMobile] = React.useState("");
  const [error, setError] = React.useState([]);
  const [amount, setAmount] = React.useState("");
  const [operator, setOperator] = React.useState(" ");
  const isAvailable = false;
  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Credit Card Bill Payment
      </Typography>
      <Box mt={1} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            my: 1,
            py: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          {isAvailable ? (
            <Box
              px={3}
              sx={{
                display: "flex",
                maxWidth: "600px",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box width={"100%"}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "600" }}
                  component={"div"}
                  px={1}
                  pt={1}
                >
                  Customer Mobile Number
                </Typography>
                <StandardStyledTextfield
                  placeholder="Mobile Number"
                  fullWidth
                  value={mobile}
                  onChange={(e) =>
                    e.target.value.length <= 10 && setMobile(e.target.value)
                  }
                />
              </Box>
              <Box mt={1} width={"100%"}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "600" }}
                  component={"div"}
                  pt={1}
                  px={1}
                >
                  Select Bank
                </Typography>
                <FormControl fullWidth>
                  <Select
                    id="standard-operator-select"
                    value={operator}
                    error={error.indexOf("operator") >= 0}
                    input={
                      <OutlinedInput
                        placeholder="Type"
                        onChange={(e) => setOperator(e.target.value)}
                        sx={{
                          "& fieldset": {
                            borderRadius: "16px",
                            borderColor:
                              error.indexOf("operator") >= 0
                                ? "error"
                                : "#e9e9e9 !important",
                            borderWidth: "1px",
                          },
                        }}
                      />
                    }
                  >
                    <MenuItem value=" ">Select Operator</MenuItem>
                    <MenuItem value="State Bank of India">
                      State Bank of India
                    </MenuItem>
                    <MenuItem value="ICICI Bank Ltd">ICICI Bank Ltd</MenuItem>
                    <MenuItem value="HDFC Bank Ltd">HDFC Bank Ltd</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box width={"100%"} mt={1}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "600" }}
                  component={"div"}
                  px={1}
                  pt={1}
                >
                  Customer Vehicle Number
                </Typography>
                <StandardStyledTextfield
                  placeholder="Vehicle Number"
                  fullWidth
                />
              </Box>

              <Box width={"100%"} mt={1}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "600" }}
                  component={"div"}
                  px={1}
                  pt={1}
                >
                  Enter Amount
                </Typography>
                <StandardStyledTextfield
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  fullWidth
                />
              </Box>

              <Box sx={{ mt: 1 }}>
                <StandardStyledButton
                  variant="contained"
                  customcolor={props.appData.buttonColor}
                  customfontcolor={props.appData.buttonFontColor}
                  p={"15px 16px"}
                  onClick={() => setError("operator")}
                >
                  Fetch Details
                </StandardStyledButton>
              </Box>
            </Box>
          ) : (
            <Box px={3}>Comming Soon!</Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
