import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  Avatar,
  CircularProgress,
  Link,
  ListItemAvatar,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonAdd from "@mui/icons-material/PersonAdd";

const drawerWidth = 240;

function StandardDrawerAppBar(props) {
  const {
    window,
    appData,
    children,
    balance,
    getBalance,
    user,
    isBalanceLoading,
  } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerCloser = () => {
    setMobileOpen(false);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    handleDrawerCloser();
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutHandler = () => {
    localStorage.clear();

    navigate("/login");
  };

  const drawer = (
    <div>
      <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
        <Box component="div">
          <Link href="/portal/dashboard" underline="none">
            <img
              style={{ height: "28px" }}
              src={appData.logo}
              alt={appData.title}
            />
          </Link>
        </Box>
      </Toolbar>
      <Divider />
      <List>
        {[
          "Dashboard",
          "Add Money",
          "Request E-value",
          "E-Store",
          "Create Agent",
          "Agent List",
        ].map(
          (text, index) =>
            (text === "Dashboard" ||
              text === "Request E-value" ||
              (text === "E-Store" && user.account_type === "RT") ||
              (text === "Create Agent" && user.account_type === "DT") ||
              (text === "Agent List" && user.account_type === "DT") ||
              (text === "Add Money" &&
                appData.services &&
                (appData.services.payment_gateway ||
                  appData.services.virtual_account))) && (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  sx={{ borderRadius: "20px" }}
                  onClick={() => {
                    navigate(
                      text === "Create Agent"
                        ? "/portal/agent/new"
                        : text === "Agent List"
                        ? "/portal/agent/list"
                        : `/portal/${text.replaceAll(" ", "-").toLowerCase()}`
                    );
                    handleDrawerCloser();
                  }}
                >
                  <ListItemIcon>
                    <ListItemAvatar>
                      {text === "Dashboard" ? (
                        <Avatar sx={{ bgcolor: "#ced9fd" }}>
                          <DashboardOutlinedIcon color="primary" />
                        </Avatar>
                      ) : text === "Add Money" ? (
                        <Avatar sx={{ bgcolor: "#f9cba0" }}>
                          <AddCircleOutlineIcon color="success" />
                        </Avatar>
                      ) : text === "Request E-value" ? (
                        <Avatar sx={{ bgcolor: "#2aff33b5" }}>
                          <RequestPageOutlinedIcon color="action" />
                        </Avatar>
                      ) : text === "E-Store" && user.account_type === "RT" ? (
                        <Avatar sx={{ bgcolor: "#8f58ff99" }}>
                          <StorefrontOutlinedIcon color="action" />
                        </Avatar>
                      ) : text === "Create Agent" &&
                        user.account_type === "DT" ? (
                        <Avatar sx={{ bgcolor: "#8f58ff99" }}>
                          <PersonAdd color="action" />
                        </Avatar>
                      ) : text === "Agent List" &&
                        user.account_type === "DT" ? (
                        <Avatar sx={{ bgcolor: "#2aff33b5" }}>
                          <GroupsIcon color="action" />
                        </Avatar>
                      ) : (
                        ""
                      )}
                    </ListItemAvatar>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        fontWeight: "500 !important",
                        color:
                          appData.drawerFontColor != null
                            ? appData.drawerFontColor
                            : "primary",
                      },
                    }}
                    primary={text}
                  />
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
      <Divider />
      <Typography p={1} variant="caption">
        My Account
      </Typography>
      <List>
        {["My Offers", "KYC", "Payout"].map(
          (text, index) =>
            ((text === "Payout" &&
              user.account_type !== "DT" &&
              appData.services &&
              appData.services.payout) ||
              text !== "Payout") && (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  sx={{ borderRadius: "20px" }}
                  onClick={() => {
                    navigate(
                      `/portal/${text.replaceAll(" ", "-").toLowerCase()}`
                    );
                    handleDrawerCloser();
                  }}
                >
                  <ListItemIcon>
                    <ListItemAvatar>
                      {text === "My Offers" ? (
                        <Avatar sx={{ bgcolor: "#00df888f" }}>
                          <LocalOfferOutlinedIcon color="success" />
                        </Avatar>
                      ) : text === "KYC" ? (
                        <Avatar sx={{ bgcolor: "#2aff33b5" }}>
                          <BadgeOutlinedIcon color="success" />
                        </Avatar>
                      ) : text === "Payout" ? (
                        <Avatar sx={{ bgcolor: "#008bb180" }}>
                          <CurrencyExchangeOutlinedIcon color="action" />
                        </Avatar>
                      ) : (
                        ""
                      )}
                    </ListItemAvatar>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        fontWeight: "500 !important",
                        color:
                          appData.drawerFontColor != null
                            ? appData.drawerFontColor
                            : "primary",
                      },
                    }}
                    primary={text}
                  />
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
      <Divider />
      <Typography p={1} variant="caption">
        Reports & Settings
      </Typography>
      <List>
        {["History", "GST Invoice", "Help"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              sx={{ borderRadius: "20px" }}
              onClick={() => {
                navigate(`/portal/${text.replaceAll(" ", "-").toLowerCase()}`);
                handleDrawerCloser();
              }}
            >
              <ListItemIcon>
                <ListItemAvatar>
                  {text === "History" ? (
                    <Avatar sx={{ bgcolor: "#ced9fd" }}>
                      <HistoryOutlinedIcon color="primary" />
                    </Avatar>
                  ) : text === "Add Money" ? (
                    <Avatar sx={{ bgcolor: "#f9cba0" }}>
                      <AddCircleOutlineIcon color="success" />
                    </Avatar>
                  ) : text === "GST Invoice" ? (
                    <Avatar sx={{ bgcolor: "#2aff33b5" }}>
                      <ReceiptLongOutlinedIcon color="action" />
                    </Avatar>
                  ) : text === "Help" ? (
                    <Avatar sx={{ bgcolor: "#bddba6" }}>
                      <HelpOutlinedIcon color="action" />
                    </Avatar>
                  ) : (
                    ""
                  )}
                </ListItemAvatar>
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& .css-10hburv-MuiTypography-root": {
                    fontWeight: "500 !important",
                    color:
                      appData.drawerFontColor != null
                        ? appData.drawerFontColor
                        : "primary",
                  },
                }}
                primary={text}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "6px 0px 10px 0px rgba(0,0,0,0.10)",
          borderBottom: "1px solid #e0e0e0",
          backgroundColor:
            appData.appBarColor != null ? appData.appBarColor : "primary",
          color:
            appData.appBarFontColor != null
              ? appData.appBarFontColor
              : "secondary",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={
              location.pathname === "/portal/dashboard"
                ? handleDrawerToggle
                : () => {
                    navigate(-1);
                  }
            }
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            {location.pathname === "/portal/dashboard" ? (
              <MenuIcon />
            ) : (
              <ArrowBackOutlined />
            )}
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mr: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "medium",
                  fontSize: "14px",
                }}
              >
                <IconButton
                  onClick={getBalance}
                  size="small"
                  color={balance < 20000 ? "error" : "primary"}
                >
                  {isBalanceLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <RefreshIcon />
                  )}
                </IconButton>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  color={balance < 20000 ? "error" : "primary"}
                >
                  ₹{balance.toFixed(2)}
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ mx: 2, display: { xs: "none", sm: "inline-flex" } }}
              />
              <Box
                sx={{
                  fontWeight: "bold",
                  display: { xs: "none", sm: "inline-flex" },
                }}
              >
                {user.fname}
              </Box>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user.fname} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: { xs: "44px", sm: "48px" } }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box px={2} py={1}>
                  <Typography
                    sx={{ fontWeight: 600 }}
                    variant="caption"
                    textAlign="left"
                  >
                    Account Type
                  </Typography>
                  <Typography variant="body2" textAlign="left">
                    {user.account_type === "RT"
                      ? "Retailer"
                      : user.account_type === "DT"
                      ? "Distributor"
                      : user.account_type === "API"
                      ? "API User"
                      : "Unknown"}
                  </Typography>
                  <Typography variant="caption" textAlign="left">
                    User ID:- {user.mobile}
                  </Typography>
                </Box>
                <Divider />

                <Box px={2} py={1}>
                  <Typography
                    sx={{ fontWeight: 600 }}
                    variant="caption"
                    textAlign="left"
                  >
                    Business Contact
                  </Typography>
                  <Typography
                    component={"div"}
                    variant="caption"
                    textAlign="left"
                  >
                    Call:- {appData.supportNumber ? appData.supportNumber : "-"}
                  </Typography>
                  <Typography
                    component={"div"}
                    variant="caption"
                    textAlign="left"
                  >
                    Email:- {appData.supportEmail ? appData.supportEmail : "-"}
                  </Typography>
                </Box>

                <Divider />
                <Typography
                  component={"div"}
                  sx={{ fontWeight: 600, px: 2, pt: 2 }}
                  variant="caption"
                  textAlign="left"
                >
                  Settings
                </Typography>
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/portal/change_password");
                  }}
                >
                  <Typography textAlign="center">Change Password</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/portal/change_pin");
                  }}
                >
                  <Typography textAlign="center">
                    Change Transaction PIN
                  </Typography>
                </MenuItem>
                {user.account_type === "API" && (
                  <MenuItem onClick={() => {}}>
                    <Typography textAlign="center">API Settings</Typography>
                  </MenuItem>
                )}
                {user.account_type === "API" && (
                  <MenuItem onClick={() => {}}>
                    <Typography textAlign="center">
                      API Documentation
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={logoutHandler}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          backgroundColor: {
            sm: appData.appBarColor != null ? appData.appBarColor : "primary",
          },
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="nav folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              px: 2,
              borderRight: "none",
              borderRadius: `${appData.drawerRadius} ${appData.drawerRadius} 0px 0px`,
              boxSizing: "border-box",
              width: drawerWidth,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.10)",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 4 },
          backgroundColor: "#f7f9fb",
          minHeight: "100vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default StandardDrawerAppBar;
