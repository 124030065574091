import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  Box,
  Chip,
} from "@mui/material";
import { format } from "date-fns";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableContainer from "@mui/material/TableContainer";

export default function StandardRequestHistory(props) {
  const { rows, columns } = props;

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map((clm, index) => (
              <TableCell key={index + "" + clm}>{clm}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>
          {row.createdAt
            ? format(new Date(row.createdAt), "dd-MM-yyyy p")
            : "-"}
        </TableCell>
        <TableCell>{row.paymentId}</TableCell>
        <TableCell>{row.bankName}</TableCell>
        <TableCell>{row.accountNumber}</TableCell>
        <TableCell>{row.mode}</TableCell>
        <TableCell>{row.amount}</TableCell>
        <TableCell>{row.remark ? row.remark : "-"}</TableCell>
        <TableCell>
          {row.status === "1" ? (
            <Chip size="small" label="Success" color="primary" />
          ) : row.status === "2" ? (
            <Chip size="small" label="Failure" color="error" />
          ) : (
            <Chip size="small" label="Pending" color="warning" />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            backgroundColor: "#f9f9f9",
            borderBottom: !open && "unset",
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={11}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <KeyValue
                keyName={"Updated At"}
                value={
                  row.updatedAt
                    ? format(new Date(row.updatedAt), "dd-MM-yyyy p")
                    : "-"
                }
              />
              <KeyValue keyName={"Transfer Id"} value={row.transferId} />
              <KeyValue keyName={"Remark"} value={row.remark} />
              <KeyValue keyName={"Response"} value={row.responseMsg} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function KeyValue(props) {
  const { keyName, value } = props;

  return (
    <Box
      p={1}
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          textAlign: "right",
          width: "calc(50% - 9px)",
          fontWeight: 500,
          fontSize: "14px",
        }}
        gutterBottom
        component="div"
      >
        {keyName}
      </Box>
      <Box px={2}>:</Box>
      <Box sx={{ width: "calc(50% - 9px)" }} gutterBottom component="div">
        {value ? value : "-"}
      </Box>
    </Box>
  );
}
