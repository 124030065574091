import {
  Box,
  List,
  Paper,
  ListItem,
  Typography,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Divider,
  Radio,
  CircularProgress,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import bbps_request from "../../../../../../rest_api/bbps_request";

export default function StandardBBPSConfirmBillPage(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [isProcessing, setProcessing] = React.useState(false);
  const [partpay, setPartPay] = React.useState(false);
  const [txnPin, setTxnPin] = React.useState("");
  const [amount, setAmount] = React.useState(
    location.state.billdetails.billAmount
      ? location.state.billdetails.billAmount
      : ""
  );

  const transaction_process = async () => {
    setProcessing(true);
    if (
      txnPin &&
      txnPin.length === 4 &&
      amount !== "" &&
      amount.length > 1 &&
      ((location.state.billdetails.billAmount === amount &&
        location.state.billdetails.partPay === false) ||
        location.state.billdetails.partPay === true)
    ) {
      try {
        const data = {
          category: location.state.category,
          operatorId: location.state.operatorId,
          operator: location.state.operator,
          billNumber: location.state.billdetails.billNumber,
          optNumber: location.state.number2,
          optNumber2: location.state.number3,
          optNumber3: location.state.number4,
          latitude: location.state.latitude,
          longitude: location.state.longitude,
          ad1_name:
            location.state.param[1] && location.state.param[1]["param_name"],
          ad2_name:
            location.state.param[2] && location.state.param[2]["param_name"],
          ad3_name:
            location.state.param[3] && location.state.param[3]["param_name"],
          amount: amount,
          txn_pin: txnPin,
        };

        const res = await bbps_request.transaction(data);
        console.log(res);
        setProcessing(false);
        if (res && !res.isLoggedIn) {
          open_snackbar(true);
          snackbar_severity("error");
          snackbar_message(res.message);
          localStorage.clear();
          navigate("/login");
        } else if (res && res.status === 1 && res.txn_status) {
          navigate("/portal/bbps/bill/receipt", {
            state: {
              res: res,
              billdetails: location.state.billdetails,
              category: location.state.category,
              operator: location.state.operator,
            },
            replace: true,
          });
        } else {
          open_snackbar(true);
          snackbar_severity("error");
          snackbar_message(res.message);
        }
      } catch (e) {
        setProcessing(false);
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message(e.message);
      }
    } else if (
      location.state.billdetails.billAmount !== amount &&
      location.state.billdetails.partPay === false
    ) {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message(
        "Partial Payment is not allowed. Please pay full amount."
      );
    } else {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message("Please enter valid details.");
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Confirm Bill Details
      </Typography>
      <Box mt={1}>
        <Paper
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box p={1}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <IconButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </ListItemAvatar>
                <ListItemText primary={location.state.category} />
              </ListItem>
            </List>
          </Box>

          <Box px={3}>
            <Divider />
            <Box
              py={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Operator</Typography>
              <Box px={2}></Box>
              <Typography align="right">{location.state.operator}</Typography>
            </Box>
            <Divider />
            <Box
              py={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography>{location.state.param[0]["param_label"]}</Typography>
              <Box px={2}></Box>
              <Typography align="right">
                {location.state.billdetails.billNumber}
              </Typography>
            </Box>
            {location.state.billdetails.consumerName && (
              <>
                <Divider />
                <Box
                  py={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Customer Name</Typography>
                  <Box px={2}></Box>
                  <Typography align="right">
                    {location.state.billdetails.consumerName}
                  </Typography>
                </Box>
              </>
            )}
            {location.state.billdetails.billDate && (
              <>
                <Divider />
                <Box
                  py={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Bill Date</Typography>
                  <Box px={2}></Box>
                  <Typography align="right">
                    {location.state.billdetails.billDate}
                  </Typography>
                </Box>
              </>
            )}
            {location.state.billdetails.dueDate && (
              <>
                <Divider />
                <Box
                  py={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Bill Due Date</Typography>
                  <Box px={2}></Box>
                  <Typography align="right">
                    {location.state.billdetails.dueDate}
                  </Typography>
                </Box>
              </>
            )}

            {location.state.billdetails.billAmount && (
              <>
                <Divider />
                <Box
                  py={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Bill Amount</Typography>
                  <Box px={2}></Box>
                  <Typography align="right">
                    ₹{location.state.billdetails.billAmount}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Paper>
        <Box p={1} pt={2}>
          <Typography
            sx={{ fontWeight: 600, color: `${appData.mainHeadingColor}` }}
          >
            Confirm Payment Details
          </Typography>
        </Box>
        <Paper
          sx={{
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box p={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  disabled={isProcessing}
                  checked={partpay === location.state.billdetails.partPay}
                  onChange={() => {
                    setPartPay(false);
                    setAmount(location.state.billdetails.billAmount);
                  }}
                  value={partpay}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "Full Payment" }}
                />
                <Typography>Pay full amount</Typography>
              </Box>
              <Typography sx={{ fontWeight: 600 }} align="right">
                ₹{location.state.billdetails.billAmount}
              </Typography>
            </Box>

            {location.state.billdetails.partPay && (
              <Box
                py={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    disabled={isProcessing}
                    checked={partpay !== location.state.billdetails.partPay}
                    onChange={() => setPartPay(true)}
                    value={partpay}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "partial amount" }}
                  />
                  <Typography>Pay partial amount</Typography>
                </Box>
                <Box p={2}></Box>
                <StandardStyledTextfield
                  value={amount}
                  disabled={!partpay || isProcessing}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter Amount (Min ₹10)"
                />
              </Box>
            )}

            <Box
              pt={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box></Box>
              <StandardStyledTextfield
                disabled={isProcessing}
                value={txnPin}
                onChange={(e) => setTxnPin(e.target.value)}
                placeholder="Enter Transaction PIN"
              />
            </Box>

            <Box
              pt={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box></Box>
              {isProcessing ? (
                <CircularProgress />
              ) : (
                <StandardStyledButton
                  variant="contained"
                  onClick={() => transaction_process()}
                >
                  Confirm & Pay
                </StandardStyledButton>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
