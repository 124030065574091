import { Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useNavigate } from 'react-router-dom';
import user_request from '../../../../rest_api/user_request';

export default function StandardVirtualAccount() {
    const [isloading, setLoading] = useState(true);
    const [va_list, setList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        //fecth user assign virtual account list from database

        const token = localStorage.getItem('token');

        if (token) {
            async function get_virtul_account() {
                const res = await user_request.get_va(token);
                setLoading(false);

                if (res && res.status === 1 && res.va_list && res.va_list.length > 0) {
                    setList(res.va_list);
                } else {
                    setList([]);
                }
            }

            get_virtul_account();

        } else {
            localStorage.clear();
            navigate('/login');
        }


    }, [navigate])


    return (
        <Box>
            <Typography mb={2} sx={{ fontWeight: 500 }}>Virtual Account Details</Typography>
            {va_list && va_list.length > 0  ?
                <>
                    <Box pb={2}>

                        <List sx={{ border: '1px solid #e0e0e0' }}>
                            <ListItem button onClick={() => navigator.clipboard.writeText("Merchant Name")} secondaryAction={<IconButton onClick={() => navigator.clipboard.writeText("Merchant Name")}><ContentCopyOutlinedIcon /> </IconButton>}>
                                <ListItemText primary={"Merchant Name"} secondary={"Name"}></ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => navigator.clipboard.writeText("Yes Bank")} secondaryAction={<IconButton onClick={() => navigator.clipboard.writeText("Yes Bank")}><ContentCopyOutlinedIcon /> </IconButton>}>
                                <ListItemText primary={"Yes Bank"} secondary={"Bank Name"}></ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => navigator.clipboard.writeText("Yes Bank")} secondaryAction={<IconButton onClick={() => navigator.clipboard.writeText("Yes Bank")}><ContentCopyOutlinedIcon /> </IconButton>}>
                                <ListItemText primary={"10100000000548425"} secondary={"Account Number"}></ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => navigator.clipboard.writeText("Yes Bank")} secondaryAction={<IconButton onClick={() => navigator.clipboard.writeText("Yes Bank")}><ContentCopyOutlinedIcon /> </IconButton>}>
                                <ListItemText primary={"YESB0CMSNOC"} secondary={"IFSC Code"}></ListItemText>
                            </ListItem>
                        </List>

                    </Box>
                    <Typography component={"div"} my={1} variant='h6' sx={{ fontSize: '14px' }}>Transfer funds to your virtual account via IMPS / NEFT / RTGS / Intrabank & Get e-value loaded immediately. </Typography>
                    <Divider />
                    <Typography component={"div"} my={1} variant='caption'><strong>Note:-</strong>Transfer fund only from your whitelisted bank account else you will get e-value credited into your bank account. Transferred fund from Unknown source will be refunded back to the source account. </Typography>
                </>
                : <Box p={3} pb={5}>{ isloading ? <CircularProgress /> : <Typography align='center' my={4} sx={{ fontWeight: 400, fontSize: '14px' }}>No virtual account is linked with this user account</Typography>}</Box>}
        </Box>
    )
}
