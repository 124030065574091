import React from 'react';
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import {
    Divider,
    Box,
    Typography,
} from "@mui/material";
import StandardStyledButton from '../../../../../StandardCustomComponent/StandardStyledButton';

export default function StandardWalletDetails(props) {

    const { appData, walletDetails, handleWalletLogout } = props;



    return (
        <Box p={3}>
            <Box
                sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography sx={{ fontWeight: "600" }}>
                    Wallet Details
                </Typography>
                <StandardStyledButton
                    endIcon={<ExitToAppOutlinedIcon />}
                    sx={{ ml: 1 }}
                    customcolor={appData.buttonFontColor}
                    customfontcolor={appData.buttonColor}
                    variant="outlined"
                    p={"4px 10px"}
                    onClick={handleWalletLogout}
                >
                    <Typography variant="caption" sx={{ lineHeight: "1.0" }}>
                        Exit
                    </Typography>
                </StandardStyledButton>
            </Box>
            <Box sx={{ mt: 1, mb: { xs: 1, sm: 3 } }}>
                <Divider />
            </Box>
            {walletDetails ? <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "column" },
                        justifyContent: "space-between",
                    }}
                >
                    <Typography sx={{ fontSize: "14px" }}>Name</Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                        {walletDetails.name}
                    </Typography>
                </Box>
                <Box my={1}>
                    <Divider />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "column" },
                        justifyContent: "space-between",
                    }}
                >
                    <Typography sx={{ fontSize: "14px" }}>
                        Sender Mobile
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {walletDetails.wallet}
                    </Typography>
                </Box>
                <Box my={1}>
                    <Divider />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "column" },
                        justifyContent: "space-between",
                    }}
                >
                    <Typography sx={{ fontSize: "14px" }}>
                        Available Limit
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    ₹{walletDetails.available_limit}
                    </Typography>
                </Box>
                <Box my={1}>
                    <Divider />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "column" },
                        justifyContent: "space-between",
                    }}
                >
                    <Typography sx={{ fontSize: "14px" }}>
                        Monthly Limit
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                        ₹{walletDetails.monthly_limit}
                    </Typography>
                </Box>
            </Box>
                : <Box p={2}>No Wallet Details Found</Box>}
            <Box sx={{ mt: { xs: 1, sm: 3 } }}>
                <Divider />
            </Box>
        </Box>

    )
}
