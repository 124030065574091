import { Link, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export default function ApplicationErrorMessage(props) {
    const { children } = props;
    return (
        <Box p={5}>
            <Paper>
                <Box p={2}>
                    <Box p={2} align="center"><ReportProblemIcon sx={{ fontSize: 48 }} color="error" /></Box>
                    <Typography align='center' variant='h5'>
                        Application Error
                    </Typography>
                    <Box align="center">
                        <Box p={5}>{children}</Box>
                        <Box pb={2}><Link href="" variant='button'  underline='none'>Reload The Page</Link></Box>
                        <Typography py={2} variant="caption" align='center'>Report this problem to your service provider.</Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}
