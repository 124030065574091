import React from "react";
import { Box, Typography } from "@mui/material";

export default function StandardKycDocuments(props) {
  const { user } = props;
  return (
    <>
      <Box
        px={2}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">
            {user.address_proof_type && user.address_proof_type !== ""
              ? user.address_proof_type
              : "Aadhaar Card"}{" "}
            Front
          </Typography>
          <Typography>
            {user.address_proof_front_url ? "Available" : "Not Submitted"}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">
            {user.address_proof_type && user.address_proof_type !== ""
              ? user.address_proof_type
              : "Aadhaar Card"}{" "}
            Back
          </Typography>
          <Typography>
            {user.address_proof_back_url ? "Available" : "Not Submitted"}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography variant="caption">PAN Card</Typography>
          <Typography>
            {user.pan_card_url ? "Available" : "Not Submitted"}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
