import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";
import dt_user_request from "../../../../rest_api/dt_user_request";
import { useNavigate } from "react-router-dom";

export default function StandardAgentFundTransfer(props) {
  const {
    agent,
    processing,
    setProcessing,
    setOpenFundTransfer,
    openFundTransfer,
    setError,
  } = props;
  const navigate = useNavigate();
  const [amount, setAmount] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [txnpin, setTxnPin] = React.useState("");

  const processTransfer = async () => {
    try {
      setProcessing(true);

      const data = {
        amount: amount,
        remark: remark,
        agentId: agent.mobile,
      };

      const res = await dt_user_request.agentFundTransfer(data, txnpin);
      process.env.REACT_APP_NODE_ENV === "development" && console.log(res);
      setProcessing(false);
      if (res) {
        if (res.isLoggedIn) {
          if (res.status === 1) {
            navigate("/portal/receipt", {
              replace: true,
              state: {
                data: [
                  { label: "Status", value: 1 },
                  { label: "Amount", value: "₹"+amount },
                  { label: "Agent Id", value: agent.mobile },
                  {
                    label: "Agent Name",
                    value: agent.fname + " " + agent.lname,
                  },
                  { label: "TID", value: res.transferId },
                  { label: "Agent Transfer Id", value: res.agent_transferId },
                  { label: "Agent Balance", value: "₹"+res.agent_balance },
                  { label: "Your Balance", value: "₹"+res.your_balance },
                  { label: "Message", value: res.message },
                ],
                type: "Fund Transfer",
              },
            });
          } else {

            setError(res.message);
          }
        } else {
          setError(res.message);

          localStorage.clear();
          navigate("/login");
        }
      } else {
        setError("No response key");
      }
    } catch (e) {
      setProcessing(false);
      setError(e.message);
    }
  };

  return (
    <Box p={2} sx={{ maxWidth: "400px", width: "100%" }}>
      <Typography px={1} pb={1} sx={{ fontSize: "16px", fontWeight: 500 }}>
        Fund Transfer to - {agent.fname} ({agent.mobile})
      </Typography>
      <Box>
        <Box p={1}>
          <StandardStyledTextfield
            type={"number"}
            fullWidth
            label={"Amount"}
            value={amount}
            onChange={(e) =>
              e.target.value.length < 8 && setAmount(e.target.value)
            }
          />
        </Box>

        <Box p={1}>
          <StandardStyledTextfield
            fullWidth
            label={"Remark"}
            value={remark}
            onChange={(e) =>
              e.target.value.length < 251 && setRemark(e.target.value)
            }
          />
        </Box>

        <Box p={1}>
          <StandardStyledTextfield
            fullWidth
            label={"Transaction PIN"}
            type={"password"}
            value={txnpin}
            onChange={(e) =>
              e.target.value.length < 5 && setTxnPin(e.target.value)
            }
          />
        </Box>

        <Box display="flex" p={1}>
          {processing ? (
            <CircularProgress />
          ) : (
            <StandardStyledButton
              disabled={
                agent.account_status === 0 ||
                agent.account_status === "0" ||
                processing ||
                !openFundTransfer
              }
              variant={"contained"}
              customcolor={props.appData.buttonColor}
              customfontcolor={props.appData.buttonFontColor}
              p={"15px 16px"}
              onClick={processTransfer}
            >
              Fund Transfer
            </StandardStyledButton>
          )}
          &nbsp;&nbsp;
          <StandardStyledButton
            disabled={
              agent.account_status === 0 ||
              agent.account_status === "0" ||
              processing
            }
            variant={"outlined"}
            customfontcolor={props.appData.buttonColor}
            customcolor={props.appData.buttonFontColor}
            p={"15px 16px"}
            onClick={() => setOpenFundTransfer(false)}
          >
            Back
          </StandardStyledButton>
        </Box>
      </Box>
    </Box>
  );
}
