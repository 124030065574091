import _utlis from "../utils/netwk_utils";

const get_fastag_operators = async () => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/fastag/operators/list"
        : "/api/fastag/operators/list";

    const res = await _utlis._get(url, { token: token });
    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const fetch_bill = async (
  operatorId,
  operator,
  vehicaleNumber,
  number,
  latitude,
  longitude
) => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/fastag/details/fetch"
        : "/api/fastag/details/fetch";

    const res = await _utlis._post(
      url,
      {
        operatorId: operatorId,
        operator: operator,
        vehicaleNumber: vehicaleNumber,
        number: number,
        latitude: latitude,
        longitude: longitude,
      },
      { token: token }
    );

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

//Whitelist funding account
const transaction = async (data) => {
  const token = localStorage.getItem("token");
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/fastag/transaction"
        : "/api/fastag/transaction";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const fastag_request = {
  get_fastag_operators,
  fetch_bill,
  transaction,
};

export default fastag_request;
