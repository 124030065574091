import {
  Box,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import payout_request from "../../../../rest_api/payout_request";
import TableContainer from "@mui/material/TableContainer";

export default function StandardPayoutBeneficiaryList(props) {
  const {
    open_snackbar,
    snackbar_severity,
    snackbar_message,
    setBeneficiary,
    setView,
  } = props;
  const navigate = useNavigate();
  const [isloading, setLoading] = React.useState(true);
  const [beneList, setBeneList] = React.useState([]);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    async function getBeneficiaryList() {
      try {
        setLoading(true);
        const res = await payout_request.getPayoutBeneficiaryList();
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              setBeneList(res.list);
            } else {
              setError(res.message);
            }
          } else {
            setError(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError("No Response Key");
        }
      } catch (e) {
        setError(e.message);
      }
    }
    getBeneficiaryList();
  }, [navigate]);

  React.useEffect(() => {
    if (error && error !== "") {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message(error);
    }
  }, [error, open_snackbar, snackbar_message, snackbar_severity]);

  return isloading ? (
    <Box p={2}>
      <CircularProgress />
    </Box>
  ) : beneList && beneList.length > 0 ? (
    <TableContainer>
      <Table aria-label="Standard Basic table">
        <TableHead>
          <TableRow>
            <TableCell>S.N</TableCell>
            <TableCell>NAME</TableCell>
            <TableCell>UPI ID</TableCell>
            <TableCell>ACCOUNT</TableCell>
            <TableCell>BANK</TableCell>
            <TableCell>IFSC</TableCell>
            <TableCell>ACC TYPE</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell>ACTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {beneList.map((row, index) => (
            <TableRow
              hover
              key={"txn" + index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ fontWeight: "600" }}>
                {row.account_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.upi_id ? row.upi_id : "-"}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.account_number ? row.account_number : "-"}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.bank ? row.bank : "-"}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.ifsc ? row.ifsc : "-"}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.accountType ? row.accountType : "-"}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.status === 0 ? (
                  <Chip
                    clickable
                    sx={{
                      boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.2)",
                      bgcolor: "#ff9800 !important",
                      "& .MuiButtonBase-root:hover": {
                        backgroundColor: "#ff9800 !important",
                      },
                    }}
                    label="Inactive"
                    size="small"
                  />
                ) : (
                  <Chip color="primary" label="Verified" size="small" />
                )}
              </TableCell>
              <TableCell component="th" scope="row">
                <StandardStyledButton
                  variant="contained"
                  color="info"
                  disabled={isloading}
                  customcolor={props.appData.buttonColor}
                  customfontcolor={props.appData.buttonFontColor}
                  onClick={() => {
                    setView("BeneConfirm");
                    setBeneficiary(row);
                  }}
                >
                  Pay
                </StandardStyledButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box p={2}>
      <Typography>No Settlement Account Added</Typography>
    </Box>
  );
}
