import { Paper, Box, Typography, FormControl, OutlinedInput, MenuItem, Select, CircularProgress, Alert } from '@mui/material'
import React from 'react'
import user_request from '../../../../rest_api/user_request';
import StandardStyledButton from '../../StandardCustomComponent/StandardStyledButton';
import StandardStyledTextfield from '../../StandardCustomComponent/StandardStyledTextfield';
import { useNavigate } from 'react-router-dom';

export default function StandardAddFundingAccount(props) {
    const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;
    const [bankName, setBankName] = React.useState(" ");
    const [name, setName] = React.useState("");
    const [account, setAccount] = React.useState("");
    const [upi_id, setUPI_Id] = React.useState("");
    const [ifsc, setIfsc] = React.useState("");
    const [isloading, setLoading] = React.useState(false);
    const [error, setError] = React.useState([]);
    const [response, setResponse] = React.useState();
    const navigate = useNavigate();

    const handlerAddFundingAccount = async (e) => {
        e.preventDefault();

        if (name && ((upi_id && upi_id.length > 5) || (bankName && bankName.length > 2 && account && ifsc && account.length > 7 && account.length < 17 && name.length > 2 && ifsc.length === 11))) {
            setError([]);
            setLoading(true);
            setResponse();
            try {
                const token = localStorage.getItem("token");

                if (token) {
                    const data = upi_id && upi_id.length > 5 ? { name: name, upi_id: upi_id } : { account_number: account, ifsc_code: ifsc, name: name, bank_name: bankName };
                    const res = await user_request.add_wa(data, token,);

                    if (res.status === 1) {

                        setBankName(" ");
                        setName("");
                        setIfsc("");
                        setAccount("");
                        navigate(-1);

                    }

                    setLoading(false);
                    setResponse(res.message);
                    res.status === 2 && setError(["error"]);

                    open_snackbar(true);
                    snackbar_severity(res.status === 2 ? "error" : "success");
                    snackbar_message(res.message);

                    if (!res.isLoggedIn) {
                        localStorage.clear();
                        navigate("/login");
                    }

                } else {
                    open_snackbar(true);
                    snackbar_severity("error");
                    snackbar_message('Session Expired');
                    localStorage.clear();
                    navigate("/login");

                }

            } catch (e) {

                setLoading(false);
                setResponse(e.message);
                setError(["error"]);
                open_snackbar(true);
                snackbar_severity("error");
                snackbar_message(e.message);

            }

        } else {
            var errors = [];
            (upi_id === null || upi_id.length < 6) && errors.push("upi");
            (ifsc === null || ifsc.length !== 11) && errors.push("ifsc");
            (account === null || account.length < 8 || account.length > 16) && errors.push("account");
            (bankName === null || bankName.length < 3) && errors.push("bank");
            (name === null || name.length < 3) && errors.push("name");

            setError(errors);
        }

    }

    return (
        <Box>
            <Typography mx={1} mb={2} variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Whitelist Funding Account</Typography>
            <Paper sx={{ flexGrow: 1, width: '100%', overflow: 'hidden', mt: 1, boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                <Box p={3}>
                    <Typography sx={{ fontWeight: '500' }}>Enter Your Account Details</Typography>
                    {response && response !== "" && <Alert sx={{ mt: 2, borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}` }} severity={Object.values(error).includes("error") ? "error" : "success"}>{response}</Alert>}
                    <Box pt={3} component={'form'} onSubmit={handlerAddFundingAccount}>

                        <FormControl fullWidth >
                            <Select
                                id="standard-banklist-select"
                                value={bankName}
                                input={<OutlinedInput sx={{
                                    '& fieldset': {
                                        borderRadius: '16px',
                                        borderColor: Object.values(error).includes("bank") ? 'red' : '#e9e9e9 !important',
                                        borderWidth: '1px'
                                    }
                                }} />}
                                onChange={(e) => setBankName(e.target.value)}
                            >
                                <MenuItem value={" "}>Select Bank</MenuItem>
                                <MenuItem value={"State Bank of India"}>State Bank of India</MenuItem>
                                <MenuItem value={"Axis Bank Ltd"}>Axis Bank Ltd</MenuItem>
                                <MenuItem value={"ICICI Bank Ltd"}>ICICI Bank Ltd</MenuItem>
                            </Select>
                        </FormControl>
                        <Box py={1}></Box>
                        <StandardStyledTextfield error={Object.values(error).includes("name")} fullWidth placeholder='Account Name' value={name} onChange={(e) => setName(e.target.value)} />
                        <Box py={1}></Box>
                        <StandardStyledTextfield error={Object.values(error).includes("upi")} fullWidth placeholder='UPI ID / VPA' value={upi_id} onChange={(e) => setUPI_Id(e.target.value)} />
                        <Box py={1}></Box>
                        <Typography align='center' variant='h6'>OR</Typography>
                        <Box py={1}></Box>
                        <StandardStyledTextfield error={Object.values(error).includes("account")} fullWidth placeholder='Account Number' value={account} type={'number'} onChange={(e) => { e.target.value.length <= 16 && setAccount(e.target.value) }} />
                        <Box py={1}></Box>
                        <StandardStyledTextfield error={Object.values(error).includes("ifsc")} fullWidth placeholder='IFSC Code' value={ifsc} onChange={(e) => { e.target.value.length <= 11 && setIfsc(e.target.value) }} />
                        <Box py={1}></Box>
                        {
                            isloading ? <CircularProgress /> : <StandardStyledButton variant='contained' type='submit' customcolor={props.appData.buttonColor} customfontcolor={props.appData.buttonFontColor} p={"15px 16px"}>Submit</StandardStyledButton>
                        }
                    </Box>
                </Box>
            </Paper>
        </Box>
    )

}
