import { Alert, Snackbar } from "@mui/material";
import React from "react";

export default function StandardCustomSnackbar(props) {
  const { handleClose, open, severity, message, radius } = props;

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={severity ? severity : "info"}
      sx={{ width: "100%", borderRadius: radius }}
      >
      {message}
    </Alert>
    </Snackbar >
  );
}
