import { CircularProgress } from "@mui/material";
import React from "react";
import { Box, Typography } from "@mui/material";
import StandardStyledButton from "../../../../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../../../../StandardCustomComponent/StandardStyledTextfield";
import dmt_request from "../../../../../../../rest_api/dmt_request";
import { useNavigate } from "react-router-dom";

export default function StandardDmtWalletLogin(props) {
  const {
    walletState,
    setWalletState,
    mobile,
    setMobile,
    open_snackbar,
    snackbar_severity,
    snackbar_message,
    appData,
    setBeneList,
    setWallet,
  } = props;

  const [error, setError] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [customerName, setCustomerName] = React.useState("");
  const [cityName, setCityName] = React.useState("");
  const [mpin, setMpin] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [otp_token, setOtpToken] = React.useState("");
  const navigate = useNavigate();

  const handleWalletLogin = async (e) => {
    e.preventDefault();

    if (mobile && mobile.length === 10) {
      try {
        const token = localStorage.getItem("token");
        setLoading(true);
        const wallet_res = await dmt_request.walletLogin(mobile, token);
        setLoading(false);
        if (wallet_res) {
          if (wallet_res.isLoggedIn) {
            if (wallet_res.status === 1) {
              if (wallet_res.wallet && wallet_res.wallet.status === 1) {
                if (wallet_res.wallet.is_reg) {
                  setError("");
                  setBeneList([]);
                  setWallet(mobile);

                  localStorage.setItem("wallet", mobile);
                } else {
                  setWalletState("newWallet");
                }
              } else if (wallet_res.wallet && wallet_res.wallet.status === 0) {
                if (wallet_res.wallet.is_reg) {
                  setWalletState("verifyWallet");
                  setOtpToken(wallet_res.wallet.otp_token);
                } else {
                  snackbar_message(wallet_res.wallet.message);
                  open_snackbar(true);
                  snackbar_severity("error");
                }
              } else {
                snackbar_message(wallet_res.wallet.message);
                open_snackbar(true);
                snackbar_severity("error");
              }
            } else {
              snackbar_message(wallet_res.message);
              open_snackbar(true);
              snackbar_severity("error");
            }
          } else {
            snackbar_message(wallet_res.message);
            open_snackbar(true);
            snackbar_severity("error");
            localStorage.clear();
            navigate("/login");
          }
        } else {
          snackbar_message("Network error");
          open_snackbar(true);
          snackbar_severity("error");
        }
      } catch (e) {
        snackbar_message(e.message);
        open_snackbar(true);
        snackbar_severity("error");
      }
    } else {
      open_snackbar(true);
      snackbar_message("Please enter customer mobile number.");
      snackbar_severity("error");
    }
  };

  const resendOTPHandler = async (e) => {
    e.preventDefault();

    if (mobile && mobile.length === 10) {
      try {
        const token = localStorage.getItem("token");
        setLoading(true);
        const data = { number: mobile };
        const wallet_res = await dmt_request.resend_wallet_otp(data, token);
        setLoading(false);
        if (wallet_res) {
          if (wallet_res.isLoggedIn) {
            if (wallet_res.status === 1) {
              if (wallet_res.wallet && wallet_res.wallet.status === 1) {
                if (wallet_res.wallet.is_reg) {
                  setError("");
                  setBeneList([]);
                  setWallet(mobile);

                  localStorage.setItem("wallet", mobile);
                } else {
                  setWalletState("newWallet");
                }
              } else if (wallet_res.wallet && wallet_res.wallet.status === 0) {
                if (wallet_res.wallet.is_reg) {
                  setWalletState("verifyWallet");
                  setOtpToken(wallet_res.wallet.otp_token);
                } else {
                  snackbar_message(wallet_res.wallet.message);
                  open_snackbar(true);
                  snackbar_severity("info");
                }
              } else {
                snackbar_message(wallet_res.wallet.message);
                open_snackbar(true);
                snackbar_severity("error");
              }
            } else {
              snackbar_message(wallet_res.message);
              open_snackbar(true);
              snackbar_severity("error");
            }
          } else {
            snackbar_message(wallet_res.message);
            open_snackbar(true);
            snackbar_severity("error");
            localStorage.clear();
            navigate("/login");
          }
        } else {
          snackbar_message("Network error");
          open_snackbar(true);
          snackbar_severity("error");
        }
      } catch (e) {
        snackbar_message(e.message);
        open_snackbar(true);
        snackbar_severity("error");
      }
    } else {
      open_snackbar(true);
      snackbar_message("Please enter customer mobile number.");
      snackbar_severity("error");
    }
  };

  const handleWalletOtp = async () => {
    if (mobile && mobile.length === 10 && otp && otp.length > 2) {
      const data = {
        number: mobile,
        otp_token: otp_token,
        otp: otp,
      };

      const token = localStorage.getItem("token");
      setLoading(true);
      const otp_res = await dmt_request.activate_wallet(data, token);

      setLoading(false);

      if (otp_res) {
        if (otp_res.isLoggedIn) {
          if (otp_res.status === 1) {
            if (
              otp_res.wallet &&
              otp_res.wallet.status === 1 &&
              otp_res.wallet.is_reg
            ) {
              setError("");
              setBeneList([]);
              setWallet(mobile);
              setWalletState("login");
              localStorage.setItem("wallet", mobile);
            } else if (
              otp_res.wallet &&
              otp_res.wallet.status === 1 &&
              !otp_res.wallet.is_reg
            ) {
              setWalletState("newWallet");
            } else {
              open_snackbar(true);
              snackbar_message(otp_res.wallet.message);
              snackbar_severity("error");
            }
          } else {
            open_snackbar(true);
            snackbar_message(otp_res.message);
            snackbar_severity("error");
          }
        } else {
          open_snackbar(true);
          snackbar_message(otp_res.message);
          snackbar_severity("error");
          setWalletState("login");

          localStorage.clear();
          navigate("/login");
        }
      } else {
        open_snackbar(true);
        snackbar_message("No Response");
        snackbar_severity("error");
      }
    } else if (mobile == null || mobile.length !== 10) {
      open_snackbar(true);
      snackbar_message("Please enter customer mobile number.");
      snackbar_severity("error");
      setWalletState("login");
    } else {
      open_snackbar(true);
      snackbar_message("Please enter valid OTP.");
      snackbar_severity("error");
    }
  };

  const handleCreateWallet = async () => {
    if (
      mobile &&
      mobile.length === 10 &&
      customerName &&
      customerName.length > 2 &&
      mpin &&
      mpin.length === 4 &&
      cityName &&
      cityName.length > 2
    ) {
      const data = {
        name: customerName,
        number: mobile,
        city: cityName,
        mpin: mpin,
      };

      const token = localStorage.getItem("token");
      setLoading(true);
      const res = await dmt_request.create_wallet(data, token);

      setLoading(false);

      if (res) {
        if (res.isLoggedIn) {
          if (res.status === 1) {
            if (res.wallet && res.wallet.status === 1 && res.wallet.is_reg) {
              setError("");
              setBeneList([]);
              setWallet(mobile);
              setWalletState("login");
              localStorage.setItem("wallet", mobile);
            } else if (
              res.wallet &&
              res.wallet.status === 0 &&
              res.wallet.is_reg
            ) {
              setWalletState("verifyWallet");
              setOtpToken(res.wallet.otp_token);
            } else if (
              res.wallet &&
              res.wallet.status === 1 &&
              !res.wallet.is_reg
            ) {
              setWalletState("newWallet");
            } else {
              open_snackbar(true);
              snackbar_message(res.wallet.message);
              snackbar_severity("error");
            }
          } else {
            open_snackbar(true);
            snackbar_message(res.message);
            snackbar_severity("error");
          }
        } else {
          open_snackbar(true);
          snackbar_message(res.message);
          snackbar_severity("error");
          setWalletState("login");

          localStorage.clear();
          navigate("/login");
        }
      } else {
        open_snackbar(true);
        snackbar_message("No Response");
        snackbar_severity("error");
      }
    } else if (mobile == null || mobile.length !== 10) {
      open_snackbar(true);
      snackbar_message("Please enter customer mobile number.");
      snackbar_severity("error");
      setWalletState("login");
    } else if (customerName == null || customerName.length < 3) {
      open_snackbar(true);
      snackbar_message("Please enter customer name.");
      snackbar_severity("error");
    } else if (mpin === null || mpin.length !== 4) {
      open_snackbar(true);
      snackbar_message("Please create valid MPIN.");
      snackbar_severity("error");
    } else {
      alert(cityName);
      open_snackbar(true);
      snackbar_message("Please enter valid customer details.");
      snackbar_severity("error");
    }
  };

  return (
    <Box p={3}>
      <Typography mb={2} sx={{ fontWeight: 500 }}>
        {walletState === "newWallet"
          ? "Create New Wallet"
          : walletState === "verifyWallet"
          ? "OTP Verification Pending"
          : "Wallet Login"}
      </Typography>
      {error}
      <Box
        component={"form"}
        onSubmit={
          walletState === "verifyWallet" ? handleWalletOtp : handleWalletLogin
        }
      >
        <StandardStyledTextfield
          sx={{
            mt: { xs: 1, md: 0 },
            width: { xs: "100%", sm: "auto" },
            maxWidth: {
              xs: "auto",
              sm: walletState === "newWallet" ? "480px" : "auto",
            },
            display: walletState === "newWallet" ? "flex" : "inline-flex",
          }}
          placeholder="Mobile Number"
          disabled={walletState !== "login"}
          value={mobile}
          onChange={(e) =>
            e.target.value.length <= 10 && setMobile(e.target.value)
          }
        />

        {walletState === "newWallet" && (
          <>
            <StandardStyledTextfield
              sx={{
                ml: { xs: 0, sm: walletState === "newWallet" ? 0 : 1 },
                mt: { xs: 1, sm: walletState === "newWallet" ? 1 : 0 },
                width: { xs: "100%", sm: "auto" },
                maxWidth: {
                  xs: "auto",
                  sm: walletState === "newWallet" ? "480px" : "auto",
                },
                display: walletState === "newWallet" ? "flex" : "inline-flex",
              }}
              placeholder="Customer Name"
              value={customerName}
              onChange={(e) =>
                e.target.value.length <= 50 && setCustomerName(e.target.value)
              }
            />
            <StandardStyledTextfield
              sx={{
                ml: { xs: 0, sm: walletState === "newWallet" ? 0 : 1 },
                mt: { xs: 1, sm: walletState === "newWallet" ? 1 : 0 },
                width: { xs: "100%", sm: "auto" },
                maxWidth: {
                  xs: "auto",
                  sm: walletState === "newWallet" ? "480px" : "auto",
                },
                display: walletState === "newWallet" ? "flex" : "inline-flex",
              }}
              placeholder="City"
              value={cityName}
              onChange={(e) =>
                e.target.value.length <= 50 && setCityName(e.target.value)
              }
            />
            <StandardStyledTextfield
              sx={{
                ml: { xs: 0, sm: walletState === "newWallet" ? 0 : 1 },
                mt: { xs: 1, sm: walletState === "newWallet" ? 1 : 0 },
                width: { xs: "100%", sm: "auto" },
                maxWidth: {
                  xs: "auto",
                  sm: walletState === "newWallet" ? "480px" : "auto",
                },
                display: walletState === "newWallet" ? "flex" : "inline-flex",
              }}
              placeholder="Create MPIN (4 Digit)"
              value={mpin}
              type={"number"}
              onChange={(e) =>
                e.target.value.length <= 4 && setMpin(e.target.value)
              }
            />
          </>
        )}
        {walletState === "verifyWallet" && (
          <>
            <StandardStyledTextfield
              sx={{
                ml: { xs: 0, sm: 1 },
                mt: { xs: 1, md: 0 },
                width: { xs: "100%", sm: "auto" },
              }}
              placeholder="Enter OTP"
              value={otp}
              type={"number"}
              onChange={(e) =>
                e.target.value.length <= 8 && setOtp(e.target.value)
              }
            />
          </>
        )}
        {loading ? (
          <Box sx={{ display: "inline-block" }} px={2} py={1}>
            <CircularProgress />
          </Box>
        ) : walletState === "login" ? (
          <StandardStyledButton
            sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 1, md: 0 } }}
            variant="contained"
            customcolor={appData.buttonColor}
            customfontcolor={appData.buttonFontColor}
            p={"15px 16px"}
            onClick={handleWalletLogin}
          >
            Login
          </StandardStyledButton>
        ) : loading ? (
          <Box sx={{ display: "inline-block" }} px={2} py={1}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: { xs: "flex", sm: "inline-flex" },
              ml: { xs: 0, md: walletState === "newWallet" ? 0 : 1 },
              mt: { xs: 1, md: walletState === "newWallet" ? 1 : 0 },
            }}
          >
            <StandardStyledButton
              variant="contained"
              customcolor={appData.buttonColor}
              customfontcolor={appData.buttonFontColor}
              p={"15px 16px"}
              onClick={
                walletState === "verifyWallet"
                  ? handleWalletOtp
                  : handleCreateWallet
              }
            >
              Submit
            </StandardStyledButton>

            {walletState === "verifyWallet" && (
              <StandardStyledButton
                sx={{ ml: 1 }}
                variant="outlined"
                customfontcolor={"red"}
                customcolor={appData.buttonFontColor}
                p={"15px 16px"}
                onClick={resendOTPHandler}
              >
                Resend OTP
              </StandardStyledButton>
            )}

            <StandardStyledButton
              sx={{ ml: 1 }}
              variant="text"
              customcolor={appData.buttonFontColor}
              customfontcolor={appData.buttonColor}
              p={"15px 16px"}
              onClick={() => {
                setWalletState("login");
              }}
            >
              Go Back
            </StandardStyledButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}
