import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import StandardTabPanel from "../AddMoney/StandardTabPanel";
import StandardNewTicket from "./StandardNewTicket";
import StandardTicketHistory from "./StandardTicketHistory";

const handlerTabs = (index) => {
  return {
    id: `standard-tab-${index}`,
    "aria-controls": `standard-tabpanel-${index}`,
  };
};

export default function StandardHelpPage(props) {
  const { appData } = props;
  const [value, setValue] = React.useState(0);
  const firstTab = React.useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Help - Raise Query
      </Typography>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Paper sx={{ display: "grid" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="payout tabs"
            >
              <Tab ref={firstTab} label="New Ticket" {...handlerTabs(0)} />
              <Tab label="Recent Tickets" {...handlerTabs(1)} />
            </Tabs>
          </Paper>
          <Box>
            <StandardTabPanel value={value} index={0}>
                <StandardNewTicket {...props}/>
            </StandardTabPanel>
            <StandardTabPanel value={value} index={1}>
                <StandardTicketHistory {...props} />
            </StandardTabPanel>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
