import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import help_request from "../../../../rest_api/help_request";

export default function StandardNewTicket(props) {
  const { open_snackbar, snackbar_severity, snackbar_message } = props;
  const [tid, setTid] = React.useState("");
  const [type, setType] = React.useState(" ");
  const [message, setMessage] = React.useState("");
  const [isloading, setLoading] = React.useState(false);
  const [txnType, setTxnType] = React.useState([" "]);
  const location = useLocation();

  const user_services =
    localStorage.getItem("user_services") &&
    JSON.parse(localStorage.getItem("user_services"));

  React.useEffect(() => {
    if (user_services) {
      setTxnType([
        " ",
        user_services.isRecharge === "1" && "Recharge",
        user_services.isBBPS === "1" && "BBPS",
        user_services.isFastag === "1" && "FAStag",
        user_services.isDMR === "1" && "DMT",
        user_services.isAEPS === "1" && "AEPS",
        user_services.isAadhaarPay === "1" && "Aadhaar Pay",
        user_services.isMATM === "1" && "MATM",
        user_services.isPayout === "1" && "Payout",
        user_services.isPancard === "1" && "Pancard",
        user_services.isPaymentGateway === "1" && "Payment Gateway",
        user_services.isVirtualAccount === "1" && "Virtual Account",
        "Fund Request",
        user_services.isBBPS === "1" && "Google Play Recharge",
        user_services.isCreditCardBill === "1" && "Credit Card Bill",
        user_services.isInvest === "1" && "Invest",
        user_services.isPrepaidCard === "1" && "Prepaid Card",
        "Other",
      ]);
    }
    if (location.state) {
      setTid(location.state.tid);
      setType(location.state.type && location.state.type !== "" ? location.state.type : " ");
    }else{
        setType(" "); 
    }
  }, [location.state, user_services]);

  const raiseTicket = async () => {
    try {
      if (
        type &&
        type !== "" &&
        tid &&
        tid > 0 &&
        message &&
        message.length > 10
      ) {
        setLoading(true);
        const data = {
          trans_id: tid,
          message: message,
          category: type,
        };
        const res = await help_request.generateTicket(data);
        if(res && res.status === 2) {location.state = [];}
        snackbar_severity(res && res.status === 1 ? "info" : "error");
        snackbar_message(res && res.message !== "" ? res.message : "No message");
        open_snackbar(true);
        setLoading(false);
        setTid("");
        setType("");
        setMessage("");
      } else {
        
        snackbar_severity("error");
        snackbar_message("Please fill the details correctly");
        open_snackbar(true);
      }
    } catch (e) {
      snackbar_severity("error");
      snackbar_message(e.message);
      open_snackbar(true);
    }
  };

  return (
    <Box p={3}>
      <Typography sx={{ fontWeight: 500 }}>Generate new ticket</Typography>
      <Box px={1} pt={1} pb={2}>
        <Box py={1}>
          <FormControl fullWidth>
            <Select
              id="standard-bankName-select"
              value={type}
              input={
                <OutlinedInput
                  sx={{
                    "& fieldset": {
                      borderRadius: "16px",
                      borderColor: "#e9e9e9 !important",
                      borderWidth: "1px",
                    },
                  }}
                />
              }
              onChange={(e) => setType(e.target.value)}
            >
              {txnType.map((opt) =>
                opt === " " ? (
                  <MenuItem key={"selectType"} value={" "}>
                    Select Category
                  </MenuItem>
                ) : (
                  opt && (
                    <MenuItem key={"txn_type" + opt} value={opt}>
                      {opt}
                    </MenuItem>
                  )
                )
              )}
            </Select>
          </FormControl>
        </Box>

        <Box py={1}>
          <StandardStyledTextfield
            fullWidth
            label={"TID"}
            disabled={isloading}
            value={tid}
            onChange={(e) =>
              e.target.value.length <= 50 && setTid(e.target.value)
            }
          />
        </Box>

        <Box py={1}>
          <StandardStyledTextfield
            fullWidth
            label={"Message (Max. 250 words)"}
            multiline={true}
            disabled={isloading}
            value={message}
            onChange={(e) =>
              e.target.value.length <= 250 && setMessage(e.target.value)
            }
          />
        </Box>
        {isloading ? <CircularProgress /> : <StandardStyledButton
          onClick={raiseTicket}
          p={"15px 16px"}
          variant="contained"
          disabled={isloading}
          customcolor={props.appData.buttonColor}
          customfontcolor={props.appData.buttonFontColor}
          type={"submit"}
        >
          Confirm
        </StandardStyledButton>}
      </Box>
    </Box>
  );
}
