import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const navItems = ["Home", "About", "Services", "Contact", "Login"];

function StandardAppbar(props) {
  const { children, appData } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const downloadApp = () => {
    const appData = JSON.parse(localStorage.getItem("appData"));
    window.open(appData.appLink, "_blank", "noreferrer");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box variant="h6" sx={{ my: 2 }}>
        <Link
          href="/"
          underline="none"
          color={
            appData.appBarFontColor != null
              ? appData.appBarFontColor
              : "inherit"
          }
        >
          <img height={"24px"} src={appData.logo} alt={appData.title} />
        </Link>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                navigate(`/${item.toLowerCase()}`);
              }}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              downloadApp();
            }}
          >
            <ListItemText primary={"Download App"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        sx={{
          boxShadow: "0px 0px 6px 0px rgb(0 0 0 / 20%)",
          backgroundColor:
            appData.appBarColor != null ? appData.appBarColor : "primary",
          color:
            appData.appBarFontColor != null
              ? appData.appBarFontColor
              : "secondary",
        }}
        component="nav"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
          >
            <Link
              href="/"
              underline="none"
              color={
                appData.appBarFontColor != null
                  ? appData.appBarFontColor
                  : "inherit"
              }
            >
              <img height={"24px"} src={appData.logo} alt={appData.title} />
            </Link>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}
          >
            {navItems.map((item) =>
              item !== "Login" ? (
                <Button
                  key={item}
                  sx={{
                    color:
                      appData.appBarFontColor != null
                        ? appData.appBarFontColor
                        : "inherit",
                    height: "64px",
                    px: 2,
                  }}
                  onClick={() => {
                    navigate(`/${item.toLowerCase()}`);
                  }}
                >
                  {item}
                </Button>
              ) : (
                ""
              )
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              justifyContent: "end",
            }}
          >
            <Button
              key={"login"}
              sx={{
                color:
                  appData.appBarFontColor != null
                    ? appData.appBarFontColor
                    : "inherit",
                height: "64px",
                px: 2,
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#f7f9fb",
          minHeight: "100vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default StandardAppbar;
