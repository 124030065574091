import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { format } from "date-fns";
import StandardAgentFundTransfer from "./StandardAgentFundTransfer";

export default function StandardAgentDetails(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [processing, setProcessing] = React.useState(false);
  const [openFundTransfer, setOpenFundTransfer] = React.useState(false);
  const [agent, setAgent] = React.useState([]);

  React.useEffect(() => {
    const getDetails = async () => {
      try {
        setLoading(true);

        const userAgent = await location.state.agent;

        setLoading(false);
        if (userAgent) {
          setError("");
          setAgent(userAgent);
        } else {
          setError("No details available");
          setAgent([]);
        }
      } catch (e) {
        setLoading(false);
        setError(e.message);
      }
    };
    if (location.state && location.state.agent) {
      getDetails();
    } else {
      setLoading(false);
      setError("No details available");
    }
  }, [location.state]);

  React.useEffect(() => {
    if (error && error !== "") {
      open_snackbar(true);
      snackbar_message(error);
      snackbar_severity("error");
    }
  }, [error, open_snackbar, snackbar_message, snackbar_severity]);

  return (
    <Box>
      <Box
        sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
      >
        <Box>
          <Tooltip title="Back">
            <IconButton onClick={() => openFundTransfer ? setOpenFundTransfer(false) : navigate(-1)}>
              <ArrowBack />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
          {!openFundTransfer ? "Agent Details" : "Fund Transfer"}
        </Typography>
      </Box>
      <Box mt={1}>
        <Paper
          sx={{
            flexGrow: 1,
            width: "100%",
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          {loading ? (
            <Box p={2}>
              <CircularProgress />
            </Box>
          ) : agent && agent !== [] && agent !== "" ? (
            !openFundTransfer && (
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  minWidth: "360px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                p={3}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", color: "#000000" }}>
                    First Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.fname.toUpperCase()}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Account Type
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    RETAILER
                  </Typography>

                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Created At
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.createdAt !== null && agent.createdAt !== ""
                      ? format(new Date(agent.createdAt), "dd-MM-yyyy")
                      : "-"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    GST Number
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.gst_no !== null && agent.gst_no !== ""
                      ? agent.gst_no.toUpperCase()
                      : "-"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Mobile
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.mobile}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Address
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.address}
                  </Typography>

                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    State
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.statename !== null && agent.statename !== ""
                      ? agent.statename.toUpperCase()
                      : "-"}
                  </Typography>

                  <Box display="flex" mt={2}>
                    <StandardStyledButton
                      disabled={
                        agent.account_status === 0 ||
                        agent.account_status === "0" ||
                        processing
                      }
                      variant={"outlined"}
                      customfontcolor={props.appData.buttonColor}
                      customcolor={props.appData.buttonFontColor}
                      p={"15px 16px"}
                      onClick={() => setOpenFundTransfer(!openFundTransfer)}
                    >
                      Fund Transfer
                    </StandardStyledButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", color: "#000000" }}>
                    Last Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.lname.toUpperCase()}
                  </Typography>

                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Firm Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.firmName !== null && agent.firmName !== ""
                      ? agent.firmName.toUpperCase()
                      : "-"}
                  </Typography>

                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Last Login At
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.loginAt !== null && agent.loginAt !== ""
                      ? format(new Date(agent.loginAt), "dd-MM-yyyy")
                      : "-"}
                  </Typography>

                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Account Status
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.account_status === "1" || agent.account_status === 1
                      ? "Active"
                      : "Inactive"}
                  </Typography>

                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Email ID
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.email !== null && agent.email !== ""
                      ? agent.email.toUpperCase()
                      : "-"}
                  </Typography>

                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    City
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.city !== null && agent.city !== ""
                      ? agent.city.toUpperCase()
                      : "-"}
                  </Typography>

                  <Typography
                    sx={{ fontSize: "14px", color: "#000000", mt: 2 }}
                  >
                    Pincode
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    {agent.pincode !== null && agent.pincode !== ""
                      ? agent.pincode.toUpperCase()
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            )
          ) : (
            <Box p={2}>
              <Typography>No details available</Typography>
            </Box>
          )}
     
          {openFundTransfer && (
            <StandardAgentFundTransfer {...props} agent={agent} processing={processing} setProcessing={setProcessing} setOpenFundTransfer={setOpenFundTransfer} setError={setError} openFundTransfer={openFundTransfer} />
          )}
        </Paper>
      </Box>
    </Box>
  );
}
