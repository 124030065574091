import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";
import user_request from "../../../../rest_api/user_request";
import payment_request from "../../../../rest_api/payment_request";
import StandardRequestHistory from "./StandardRequestHistory";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const columns = [
  "Request Id",
  "Request Date",
  "Payment Id",
  "Bank",
  "Account",
  "Mode",
  "Amount",
  "Remark",
  "Status",
];

export default function StandardRequestEvalue(props) {
  const { appData } = props;

  const [bankName, setbankName] = React.useState(" ");
  const [mode, setMode] = React.useState(" ");
  const [amount, setAmount] = React.useState("");
  const [utr, setUtrNo] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [error, setError] = React.useState("");
  const [requests, setRequests] = React.useState([]);
  const [bankList, setBankList] = React.useState([]);
  const [bankDetails, setBankDetails] = React.useState([]);
  const [bankloading, setBankLoading] = React.useState(true);
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [viewType, setViewType] = React.useState(0);

  const navigate = useNavigate();

  const handlerBankList = (event) => {
    setbankName(event.target.value);

    const bank = bankList.filter((b) => b.bankName === event.target.value)[0];
    console.log(bank);
    setBankDetails(bank);
    setError("");
  };

  React.useEffect(() => {
    const getBank = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const res = await user_request.get_company_bank_list(token);
          setBankLoading(false);
          if (res && res.status === 1) {
            setError("");
            setBankList(res.banks);
          } else {
            if (!res.isLoggedIn) {
              localStorage.clear();
              navigate("/login");
            }

            setError(res.message);
          }
        } catch (e) {
          setError("Unable to get bank details.");
        }
      } else {
        localStorage.clear();
        navigate("/login");
      }
    };
    viewType === 0 && getBank();
  }, [navigate, viewType]);

  React.useEffect(() => {
    const getRecentRequests = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          setLoading(true);
          const res = await payment_request.getRecentRequestList();
          setLoading(false);
          if (res && res.status === 1) {
            setError("");
            setRequests(res.requests);
          } else {
            if (!res.isLoggedIn) {
              localStorage.clear();
              navigate("/login");
            }
            setError(res.message);
          }
        } catch (e) {
          setError("Unable to get request list.");
        }
      } else {
        localStorage.clear();
        navigate("/login");
      }
    };
    viewType === 1 && getRecentRequests();
  }, [navigate, viewType]);

  const requestHandler = async (e) => {
    e.preventDefault();

    if (
      bankName &&
      bankName !== " " &&
      mode &&
      mode !== " " &&
      amount &&
      amount.length > 1 &&
      remark &&
      remark.length > 2 &&
      utr &&
      utr.length > 2 &&
      utr.length < 15
    ) {
      setProcessing(true);
      try {
        const data = {
          account_number: bankDetails.accountNumber,
          bank_name: bankName,
          mode: mode,
          amount: amount,
          remark: remark,
          utr: utr,
        };
        const res = await payment_request.raise(data);
        console.log(res);
        setProcessing(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              navigate("/portal/receipt", {
                state: {
                  type: "Fund Requst",
                  tid: res.req_id,
                  data: [
                    { label: "Amount", value: amount },
                    { label: "Status", value: 1 },
                    { label: "UTR No.", value: utr },
                    { label: "Request Id", value: res.req_id },
                    {
                      label: "Message",
                      value: "Fund request sucessfully raised.",
                    },
                  ],
                },
              });
            } else {
              setError(res.message);
            }
          } else {
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError("No Response Key");
        }
      } catch (e) {
        setError(e.message);
      }
    } else {
      setError("Please fill the form correctly.");
    }
  };

  return viewType === 1 ? (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        <IconButton onClick={() => setViewType(0)}>
          <ArrowBackIcon />
        </IconButton>
        Request History
      </Typography>
      <Box
        mt={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            width: "100%",
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          {loading ? (
            <Box
              p={2}
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <CircularProgress />
            </Box>
          ) : requests && requests.length > 0 ? (
            <StandardRequestHistory rows={requests} columns={columns} />
          ) : (
            <Box p={2}>
              <Typography variant="h6">No Request Available</Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  ) : (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Request E-value
      </Typography>
      <Box
        mt={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            width: "100%",
            maxWidth: { xs: "100%", md: "48%" },
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box p={3}>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontWeight: "500" }}>
                Raise Fund Request
              </Typography>
              {bankloading && <CircularProgress ml={1} size={24} />}
            </Box>
            <Box pt={3} component={"form"} onSubmit={requestHandler}>
              {error && error !== "" && (
                <Box p={1}>
                  <Typography color="error">{error}</Typography>
                </Box>
              )}

              <FormControl fullWidth>
                <Select
                  id="standard-banklist-select"
                  value={bankName}
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          borderRadius: "16px",
                          borderColor: error ? "error" : "#e9e9e9 !important",
                          borderWidth: "1px",
                        },
                      }}
                    />
                  }
                  onChange={handlerBankList}
                >
                  <MenuItem value={" "}>Select Bank</MenuItem>
                  {bankList &&
                    bankList.map((bank, index) => (
                      <MenuItem
                        key={bank["id"] + index}
                        value={bank["bankName"]}
                      >
                        {bank["bankName"]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box py={1}></Box>
              <StandardStyledTextfield
                fullWidth
                placeholder="Amount"
                value={amount}
                onChange={(e) =>
                  e.target.value.length <= 8 && setAmount(e.target.value)
                }
              />
              <Box py={1}></Box>
              <StandardStyledTextfield
                fullWidth
                placeholder="UTR NO."
                value={utr}
                onChange={(e) =>
                  e.target.value.length <= 15 && setUtrNo(e.target.value)
                }
              />
              <Box py={1}></Box>
              <FormControl fullWidth>
                <Select
                  id="standard-payment-mode-select"
                  value={mode}
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          borderRadius: "16px",
                          borderColor: error ? "error" : "#e9e9e9 !important",
                          borderWidth: "1px",
                        },
                      }}
                    />
                  }
                  onChange={(e) => setMode(e.target.value)}
                >
                  <MenuItem value={" "}>Select Payment Mode</MenuItem>
                  <MenuItem value={"UPI"}>UPI</MenuItem>
                  <MenuItem value={"IMPS"}>IMPS</MenuItem>
                  <MenuItem value={"NEFT"}>NEFT</MenuItem>
                  <MenuItem value={"RTGS"}>RTGS</MenuItem>
                  <MenuItem value={"CASH"}>CASH</MenuItem>
                </Select>
              </FormControl>
              <Box py={1}></Box>
              <StandardStyledTextfield
                fullWidth
                placeholder="Remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
              <Box py={1}></Box>
              {processing ? (
                <CircularProgress />
              ) : (
                <StandardStyledButton
                  variant="contained"
                  disabled={bankloading}
                  customcolor={props.appData.buttonColor}
                  customfontcolor={props.appData.buttonFontColor}
                  p={"15px 16px"}
                  type="submit"
                >
                  Submit
                </StandardStyledButton>
              )}
              <StandardStyledButton
                sx={{ ml: 1 }}
                p={"15px 16px"}
                onClick={() => setViewType(1)}
                variant="contained"
                disabled={bankloading}
                customfontcolor={props.appData.buttonColor}
                customcolor={props.appData.buttonFontColor}
              >
                Request History
              </StandardStyledButton>
            </Box>
          </Box>
        </Paper>
        <Paper
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: { xs: "100%", md: "48%" },
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography py={3} align="center" sx={{ fontWeight: "500" }}>
              Bank Details
            </Typography>
            <Divider />
          </Box>

          {bankDetails && bankName && bankName !== " " && bankName !== "" ? (
            <Box sx={{ height: "100%", width: "100%" }}>
              <Box p={3}>
                <Box
                  mb={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>Account Holder</Box>

                  <Box>{bankDetails.accountHolder}</Box>
                </Box>
                <Divider />
                <Box
                  my={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>Bank Name</Box>
                  <Box>{bankDetails.bankName}</Box>
                </Box>
                <Divider />
                <Box
                  my={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>Account Number</Box>
                  <Box>{bankDetails.accountNumber}</Box>
                </Box>
                <Divider />
                <Box
                  my={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>IFSC Code</Box>
                  <Box>{bankDetails.ifscCode}</Box>
                </Box>
                <Divider />
                <Box
                  my={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>Branch</Box>
                  <Box>{bankDetails.branch}</Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                p: 3,
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography align="center">
                Select bank to view bank details
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
