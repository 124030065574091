import _utlis from "../utils/netwk_utils";

const details = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/details"
        : "/api/user/details";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const get_balance = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/balance"
        : "/api/user/balance";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const get_va = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/virtual_accounts"
        : "/api/user/virtual_accounts";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const get_wa = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/whitelisted_accounts"
        : "/api/user/whitelisted_accounts";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Whitelist funding account
const add_wa = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/add_funding_account"
        : "/api/user/add_funding_account";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Get bank account
const get_company_bank_list = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/company_bank_details"
        : "/api/user/company_bank_details";
    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const get_services = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/services"
        : "/api/user/services";

    const res = await _utlis._get(url, { token: token });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const get_notifications = async (token, query) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/notifications"
        : "/api/user/notifications";
    const res = await _utlis._get(url, { token: token, query: query });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const get_package = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/package"
        : "/api/user/package";
    const res = await _utlis._get(url, { token: token });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const reset_transaction_pin = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/reset_transaction_pin"
        : "/api/user/reset_transaction_pin";
    const res = await _utlis._get(url, { token: token });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const change_transaction_pin = async (data) => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/change_transaction_pin"
        : "/api/user/change_transaction_pin";
    const res = await _utlis._post(url, data, { token: token });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const change_password = async (data) => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/user/change_password"
        : "/api/user/change_password";
    const res = await _utlis._post(url, data, { token: token });
    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const user_request = {
  details,
  get_balance,
  get_services,
  get_va,
  get_wa,
  add_wa,
  get_notifications,
  get_package,
  get_company_bank_list,
  change_password,
  change_transaction_pin,
  reset_transaction_pin,
};

export default user_request;
