var CryptoJS = require("crypto-js");
const PUB_KEY = process.env.REACT_APP_PUBKEY;

function createPayload(data) {
  //console.log(process.env.REACT_APP_NODE_ENV);
 // console.log(data);
  return process.env.REACT_APP_NODE_ENV === "development"
    ? data
    : CryptoJS.AES.encrypt(data, PUB_KEY).toString();
}

module.exports = createPayload;
