import React, { useState, useEffect } from "react";
import StandardDrawerAppBar from "./Appbar/StandardDrawerAppBar";
import { useNavigate } from "react-router-dom";
import user_request from "../../../rest_api/user_request";
import StandardCustomSnackbar from "../StandardCustomComponent/StandardCustomSnackbar";
import { Box, CircularProgress } from "@mui/material";
import StandardCreateAgent from "./Agent/StandardCreateAgent";
import StandardAgentList from "./Agent/StandardAgentList";
import PageNotFound from "../public/PageNotFound";

const StandardAuthGuard = (props) => {
  const { Page, appData } = props;
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [isBalanceLoading, setBalanceLoading] = useState(false);
  const [snackbar_message, setSnackbar_message] = useState("");
  const [severity, setSeverity] = useState("info");

  const [user, setUser] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
  );

  const [balance, setBalance] = useState(0.0);

  const getBalance = async () => {
    try {
      setBalanceLoading(true);
      const res = await user_request.get_balance(token);
      console.log(res);
      setBalanceLoading(false);
      if (res.status === 1) {
        setBalance(res.balance || 0);
      } else {
        if (!res.isLoggedIn) {
          setToken(null);
        }
        setSeverity("error");
        setOpenSnackbar(true);
        setSnackbar_message(res.message);
      }
    } catch (e) {
      setSeverity("error");
      setOpenSnackbar(true);
      setSnackbar_message(e.message);
    }
  };

  const handlerCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    async function getUser() {
      if (token && token !== "") {
        try {
          const res = await user_request.details(token);

          if (res.status === 1) {
            if (res.user) {
              localStorage.setItem("user", JSON.stringify(res.user));
              setUser(res.user);

              const bl_res = await user_request.get_balance(token);

              if (bl_res.status === 1) {
                setBalance(bl_res.balance || 0);
              } else {
                if (!bl_res.isLoggedIn) {
                  setToken(null);
                }
                setSeverity("error");
                setOpenSnackbar(true);
                setSnackbar_message(res.message);
              }
            } else {
              setSeverity("warning");
              setOpenSnackbar(true);
              setSnackbar_message("User details not found");
              setToken(null);
            }
          } else {
            if (!res.isLoggedIn) {
              setToken(null);
            }
            setSeverity("error");
            setOpenSnackbar(true);
            setSnackbar_message(res.message);
          }
        } catch (e) {
          setSeverity("error");
          setOpenSnackbar(true);
          setSnackbar_message(e.message);
        }
      } else {
        localStorage.removeItem("services");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
    getUser();
  }, [token, navigate]);

  return user &&
    (user.account_type === "RT" ||
      user.account_type === "DT" ||
      user.account_type === "API" ||
      (user.account_type !== "DT" &&
        Page !== StandardCreateAgent &&
        Page !== StandardAgentList)) ? (
    <StandardDrawerAppBar
      {...props}
      user={user}
      balance={balance}
      isBalanceLoading={isBalanceLoading}
      getBalance={getBalance}
      open_snackbar={setOpenSnackbar}
      snackbar_severity={setSeverity}
      snackbar_message={setSnackbar_message}
    >
      {props.access_type !== "All" &&
      props.access_type !== "RT-API" &&
      props.access_type !== user.account_type ? (
        <PageNotFound {...props} />
      ) : props.access_type === "RT-API" &&
        user.account_type !== "RT" &&
        user.account_type !== "API" ? (
        <PageNotFound {...props} />
      ) : (
        <Page
          {...props}
          user={user}
          isBalanceLoading={isBalanceLoading}
          open_snackbar={setOpenSnackbar}
          snackbar_severity={setSeverity}
          snackbar_message={setSnackbar_message}
        />
      )}
      <StandardCustomSnackbar
        handleClose={handlerCloseSnackbar}
        open={openSnackBar}
        severity={severity}
        message={snackbar_message}
        radius={
          appData !== undefined && appData.cardRadius !== null
            ? appData.cardRadius
            : "6px"
        }
      />
    </StandardDrawerAppBar>
  ) : user && user.account_type === "ADMIN" ? (
    <Box p={5}>You are not authorize to use this service.</Box>
  ) : (
    <Box
      sx={{
        p: 5,
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default StandardAuthGuard;
