import {
  Box,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import StandardTabPanel from "../AddMoney/StandardTabPanel";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import report_requests from "../../../../rest_api/report_requests";
import StandardHistoryTable from "./StandardHistoryTable";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";

const handlerTabs = (index) => {
  return {
    id: `standard-tab-${index}`,
    "aria-controls": `standard-tabpanel-${index}`,
  };
};

export default function StandardHistoryPage(props) {
  const { appData, open_snackbar, snackbar_message, snackbar_severity, user } =
    props;
  const [last_tid, setLastTid] = React.useState(0);
  const [filtered_lastId, setFiltered_lastId] = React.useState(0);
  const [filterParam, setFilterParam] = React.useState("");
  const [txnType, setTxnType] = React.useState("all");
  const [error, setError] = React.useState();
  const [filteredHistory, setFilteredHistory] = React.useState([]);
  const [history, setHistory] = React.useState([]);
  const [refundList, setRefundList] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [reload, setReload] = React.useState(true);
  const [isSearching, setIsSearching] = React.useState(false);
  const [isCheckingStatus, setCheckStatus] = React.useState(false);
  const [showSeacrhBox, setShowSearchBox] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [startDate, setStartDate] = React.useState(
    "" //format(addDays(new Date(), 7), "yyyy-MM-dd")
  );
  const [lastDate, setLastDate] = React.useState(
    "" //format(new Date(), "yyyy-MM-dd")
  );

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setFilteredHistory([]);
    setIsSearching(false);
    var type =
      newValue === 1
        ? user.account_type === "DT"
          ? "Fund Transfer"
          : "Payout"
        : newValue === 2
        ? "TopUp"
        : newValue === 3
        ? "Recharge"
        : newValue === 4
        ? "DMT"
        : newValue === 5
        ? "AEPS"
        : newValue === 6
        ? "Aadhaar Pay"
        : newValue === 7
        ? "MATM"
        : newValue === 8
        ? "BBPS"
        : newValue === 9
        ? "FAStag"
        : newValue === 10
        ? "PAN Card"
        : newValue === 11
        ? "Credit Card Bill"
        : newValue === 12
        ? "Refunds"
        : newValue === 13
        ? "Pendings"
        : "all";

    setTxnType(type);
    setValue(newValue);
    newValue !== 0 && newValue !== 12 && newValue !== 13
      ? filterHandler(type, false)
      : newValue !== 0 &&
        filterByTxnStatus(newValue !== 12 ? "0" : "3", history);
  };

  const filterByTxnStatus = async (status, txns) => {
    console.log(txns);
    setCheckStatus(true);
    const list = txns.filter(
      (t) =>
        (status === "0" && (t.status === "0" || t.status === "6")) ||
        (status === "3" && (t.status === "2" || t.status === "3"))
    );

    setRefundList(list);
  };

  React.useEffect(() => {
    const getHistoryHandler = async () => {
      setLoading(true);
      try {
        const res = await report_requests.get_transaction_history(0);
        setLoading(false);
        setReload(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              setHistory(res.history);
              setLastTid(res.new_lastId);
            } else {
              setError(res.message);
            }
          } else {
            setError(res.message);
            navigate("/login");
          }
        } else {
          setError("No response key");
        }
      } catch (e) {
        setLoading(false);
        setError(e.message);
      }
    };
    if (reload) {
      getHistoryHandler();
    }
  }, [navigate, reload]);

  const paginationHandler = async (txnType) => {
    if (last_tid > 0) {
      setLoading(true);
      try {
        const res = await report_requests.get_transaction_history(last_tid);
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              var addHistory = history;
              addHistory.push(...res.history);

              setHistory(addHistory);
              setLastTid(res.new_lastId);
            } else {
              setError(res.message);
            }
          } else {
            setError(res.message);
            navigate("/login");
          }
        } else {
          setError("No response key");
        }
      } catch (e) {
        setLoading(false);
        setError(e.message);
      }
    }
  };

  const filterHandler = async (type, isSearching) => {
    var addHistory = txnType === type && !isSearching ? filteredHistory : [];
    txnType !== type && setFiltered_lastId(0);
    setIsSearching(isSearching ? isSearching : false);
    setLoading(true);
    try {
      const data = {
        number: isSearching ? filterParam : "",
        txnType: type === "Refunds" || type === "Pendings" ? "all" : type,
        startDate: isSearching ? startDate : "",
        endDate: isSearching ? lastDate : "",
        lastId: txnType !== type || isSearching ? 0 : filtered_lastId,
      };
      const res = await report_requests.filter_transaction_history(data);

      type === "all" && setIsSearching(true);
      setLoading(false);
      if (res) {
        if (res.isLoggedIn) {
          if (res.status === 1) {
            addHistory.push(...res.history);

            setFilteredHistory(addHistory);
            setFiltered_lastId(res.new_lastId);
            console.log(value);
            isCheckingStatus &&
              filterByTxnStatus(value !== 12 ? "0" : "3", addHistory);
          } else {
            setError(res.message);
          }
        } else {
          setError(res.message);
          navigate("/login");
        }
      } else {
        setError("No response key");
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  React.useEffect(() => {
    if (error && error !== "") {
      snackbar_severity("error");
      snackbar_message(error);
      open_snackbar(true);
    }
  }, [error, open_snackbar, snackbar_message, snackbar_severity]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
          History
        </Typography>
        <Box sx={{ display: "flex" }}>
          <IconButton
            color="primary"
            sx={{ fontSize: "24px", width: "32px", height: "32px" }}
            onClick={() => setShowSearchBox(!showSeacrhBox)}
          >
            <SearchIcon />
          </IconButton>
          <IconButton
            color="primary"
            disabled={reload || isLoading}
            sx={{ fontSize: "24px", width: "32px", height: "32px" }}
            onClick={() => {
              if (txnType === "all") {
                setReload(true);
                setLastTid(0);
                setIsSearching(false);
                setFilterParam("");
                setStartDate("");
                setLastDate("");
              } else {
                setFilteredHistory([]);
                setFiltered_lastId(0);
                filterHandler(txnType, false);
              }
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
      {showSeacrhBox && (
        <Box mt={2}>
          <Paper
            sx={{
              bgcolor: "#c2c5de",
              flexGrow: 1,
              pb: 2,
              overflow: "hidden",
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
              borderRadius: `${
                appData !== undefined && appData.cardRadius !== null
                  ? appData.cardRadius
                  : "6px"
              }`,
            }}
          >
            <Box p={2}>
              <Typography sx={{ fontSize: "18px" }}>Search</Typography>
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  alignItems: { sm: "unset", md: "center" },
                  flexDirection: { sm: "column", md: "row" },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                &nbsp;&nbsp;
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="End Date"
                      value={lastDate}
                      onChange={(newValue) => setLastDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                &nbsp;&nbsp;
                <TextField
                  sx={{
                    mt: 1,
                    display: "flex",
                    width: { sm: "100%", md: "auto" },
                  }}
                  value={filterParam}
                  onChange={(event) => setFilterParam(event.target.value)}
                  label={"TID / ACCOUNT / MOBILE"}
                />
                &nbsp;&nbsp;
                <Box mt={1}>
                  <StandardStyledButton
                    disabled={isLoading}
                    customcolor={props.appData.buttonColor}
                    customfontcolor={props.appData.buttonFontColor}
                    variant="contained"
                    color="info"
                    p={"16px 12px"}
                    onClick={() => {
                      setIsSearching(true);
                      filterHandler(txnType, true);
                    }}
                  >
                    Search
                  </StandardStyledButton>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Paper sx={{ display: "grid" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="History tabs"
            >
              <Tab label="All" {...handlerTabs(0)} />
              {user.account_type === "DT" && (
                <Tab label="Fund Transfer" {...handlerTabs(1)} />
              )}
              {user.account_type !== "DT" && (
                <Tab label="Payout" {...handlerTabs(1)} />
              )}
              <Tab label="TopUp" {...handlerTabs(2)} />
              <Tab label="Recharge" {...handlerTabs(3)} />
              <Tab label="DMT" {...handlerTabs(4)} />
              <Tab label="AEPS" {...handlerTabs(5)} />
              <Tab label="Aadhaar Pay" {...handlerTabs(6)} />
              <Tab label="MATM" {...handlerTabs(7)} />
              <Tab label="BBPS" {...handlerTabs(8)} />
              <Tab label="FAStag" {...handlerTabs(9)} />
              <Tab label="PAN Card" {...handlerTabs(10)} />
              <Tab label="CC Bill" {...handlerTabs(11)} />
              <Tab label="Refunds" {...handlerTabs(12)} />
              <Tab label="Pendings" {...handlerTabs(13)} />
            </Tabs>
          </Paper>
          <Box>
            <StandardTabPanel value={value} index={0}>
              <StandardHistoryTable
                transaction={
                  isSearching && txnType === "all" ? filteredHistory : history
                }
                paginationHandler={paginationHandler}
                isLoading={isLoading}
                last_tid={last_tid}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={1}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={2}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={3}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={4}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={5}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={6}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={7}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={8}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={9}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={10}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={filtered_lastId}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={11}>
              <StandardHistoryTable
                transaction={filteredHistory}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={last_tid}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>

            <StandardTabPanel value={value} index={12}>
              <StandardHistoryTable
                transaction={refundList}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={last_tid}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>

            <StandardTabPanel value={value} index={13}>
              <StandardHistoryTable
                transaction={refundList}
                paginationHandler={filterHandler}
                isLoading={isLoading}
                last_tid={last_tid}
                txnType={txnType}
                {...props}
              />
            </StandardTabPanel>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
