import { Paper, Box, Typography, Tab, Tabs, Divider } from '@mui/material'
import React from 'react'
import StandardStyledButton from '../../StandardCustomComponent/StandardStyledButton';
import StandardStyledTextfield from '../../StandardCustomComponent/StandardStyledTextfield';
import StandardTabPanel from './StandardTabPanel';
import StandardVirtualAccount from './StandardVirtualAccount';
import StandardWhiteListAccount from './StandardWhiteListAccount';


function AddMoneyForm(props) {
    const { formHandler, error, amount, setAmount, title, children, divider } = props;

    return <>
        <Typography mb={2} sx={{ fontWeight: 500 }}>{title}</Typography>
        <Box component={'form'} onSubmit={formHandler}>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: { xs: 'column', sm: 'row' } }}>
                <StandardStyledTextfield
                    variant="outlined"
                    error={error !== ""}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                    value={amount}
                    type={"number"}
                    onChange={(event) => event.target.value.length <= 5 ? setAmount(event.target.value) : {}}
                    placeholder='Amount' />
                <Box p={"4px"}></Box>
                <StandardStyledButton variant='contained' customcolor={props.appData.buttonColor} customfontcolor={props.appData.buttonFontColor} p={"15px 16px"}>Proceed</StandardStyledButton>
            </Box>
            <Box pt={5} pb={2}>
                {divider}
                <Typography component={"div"} mt={2} variant='caption'><strong>Note:-</strong> {children}</Typography>
            </Box>
        </Box>
    </>;


}

const handlerTabs = (index) => {
    return {
        id: `standard-tab-${index}`,
        'aria-controls': `standard-tabpanel-${index}`,
    };
}



export default function StandardAddMoney(props) {
    const { appData } = props;

    const [error, setError] = React.useState("");
    const [amount, setAmount] = React.useState("");
    const [value, setValue] = React.useState(appData.services && appData.services.payment_gateway ? 0 : appData.services.virtual_account ? 2 : 3);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const formHandler = (event) => {
        event.preventDefault();
    }


    return (
        <Box>
            <Typography mx={1} mb={2} variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Add Money</Typography>
            <Paper sx={{ my: 1, overflow: 'hidden', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                <Paper sx={{ display: 'grid' }}>
                    <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="add money tabs">
                        <Tab sx={appData.services && appData.services.payment_gateway ? {} : {display: 'none'}} label="UPI" {...handlerTabs(0)} />
                        <Tab sx={appData.services && appData.services.payment_gateway ? {} : {display: 'none'}} label="Debit/Credit Card" {...handlerTabs(1)} />
                        <Tab sx={appData.services && appData.services.virtual_account ? {} : {display: 'none'}} label="Virtual Account" {...handlerTabs(2)} />
                        <Tab label="Whitelisted Account" {...handlerTabs(3)} />
                    </Tabs>
                </Paper>


                {/* UPI Payment Mode */}
                {
                    appData.services && appData.services.payment_gateway && <Box p={4}><StandardTabPanel value={value} index={0}>
                        <AddMoneyForm title={"Add Money via UPI"} divider={<Divider />} appData={appData} formHandler={formHandler} amount={amount} setAmount={setAmount} setError={setError} error={error} >Please whitelist your UPI ID before adding money via UPI. Transaction done through Account/UPI ID without whitelisting, will not get updated in portal & the amount will be refunded back to the source account which will take 3 working days.</AddMoneyForm>

                        <StandardStyledButton variant='outlined' customcolor={props.appData.buttonFontColor} customfontcolor={props.appData.buttonColor} onClick={(e) => { handleChange(e, 3); }}>Whitelist Account/UPI ID</StandardStyledButton>
                    </StandardTabPanel>
                    </Box>
                }

                {/* Debit / Credit Card Payment Mode */}
                {appData.services && appData.services.payment_gateway && <Box p={4}> <StandardTabPanel value={value} index={1}>

                    <AddMoneyForm title={"Debit / Credit Card"} divider={<Divider />} appData={appData} formHandler={formHandler} amount={amount} setAmount={setAmount} setError={setError} error={error} >There will be 2% transaction charges for adding money via debit/credit card.</AddMoneyForm>


                </StandardTabPanel>
                </Box>
                }

                {/* Virtual Account Details */}
                {appData.services && appData.services.virtual_account &&
                    <StandardTabPanel sx={{ p: 2 }} value={value} index={2}>
                        <StandardVirtualAccount />
                        <StandardStyledButton variant='outlined' customcolor={props.appData.buttonFontColor} customfontcolor={props.appData.buttonColor} onClick={(e) => { handleChange(e, 3); }}>Whitelist Account/UPI ID</StandardStyledButton>
                    </StandardTabPanel>
                }

                {/* Whitelisted funding account Details */}
                <StandardTabPanel value={value} index={3}>
                    <StandardWhiteListAccount appData={appData} />
                </StandardTabPanel>

            </Paper>
        </Box>
    )
}

