import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React from "react";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import user_request from "../../../../rest_api/user_request";
import { useNavigate } from "react-router-dom";

export default function StandardChangePinPage(props) {
  const { appData, open_snackbar, snackbar_message, snackbar_severity } = props;
  const [isloading, setLoading] = React.useState(false);
  const [current_Pin, setCurrentPin] = React.useState("");
  const [new_Pin, setNewPin] = React.useState("");
  const [new2_Pin, setNew2Pin] = React.useState("");
  const [error, setError] = React.useState("");

  const navigate = useNavigate();

  const change_pin_request = async () => {
    if (
      current_Pin &&
      new_Pin &&
      new2_Pin &&
      current_Pin.length === 4 &&
      new_Pin.length === 4 &&
      new_Pin === new2_Pin &&
      current_Pin !== new_Pin
    ) {
      try {
        setLoading(true);
        const data = {
          current_transaction_pin: current_Pin,
          new_transaction_pin: new_Pin,
        };
        const res = await user_request.change_transaction_pin(data);
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            setError("");
            snackbar_severity(res.status === 1 ? "info" : "error");
            snackbar_message(res.message);
            open_snackbar(true);
            setCurrentPin("");
            setNewPin("");
            setNew2Pin("");
          } else {
            setError(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError("No response key");
        }
      } catch (e) {
        setLoading(false);
        setError(e.message);
      }
    } else {
      setError(
        "Please fill the details currectly. Minimum 4 digit Transaction PIN required."
      );
    }
  };

  const reset_pin_handler = async () => {
    try {
      setLoading(true);

      const res = await user_request.reset_transaction_pin();
      setLoading(false);
      if (res) {
        if (res.isLoggedIn) {
          setError("");
          snackbar_severity(res.status === 1 ? "info" : "error");
          snackbar_message(res.message);
          open_snackbar(true);
          setCurrentPin("");
          setNewPin("");
          setNew2Pin("");
        } else {
          setError(res.message);
          localStorage.clear();
          navigate("/login");
        }
      } else {
        setError("No response key");
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Change Transaction Pin
      </Typography>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box p={2}>
            <Box sx={{ maxWidth: "400px", width: "100%" }}>
              {error && (
                <Box py={1} sx={{ color: "red" }}>
                  {error}
                </Box>
              )}
              <Box py={1}>
                <StandardStyledTextfield
                  type="password"
                  disabled={isloading}
                  fullWidth
                  label={"Current Transaction PIN / OTP"}
                  value={current_Pin}
                  onChange={(e) =>
                    e.target.value.length <= 30 && setCurrentPin(e.target.value)
                  }
                />
              </Box>
              <Box py={1}>
                <StandardStyledTextfield
                  fullWidth
                  disabled={isloading}
                  label={"Create New PIN"}
                  type="password"
                  value={new_Pin}
                  onChange={(e) =>
                    e.target.value.length <= 30 && setNewPin(e.target.value)
                  }
                />
              </Box>
              <Box py={1}>
                <StandardStyledTextfield
                  fullWidth
                  disabled={isloading}
                  label={"Confirm New PIN"}
                  value={new2_Pin}
                  onChange={(e) =>
                    e.target.value.length <= 30 && setNew2Pin(e.target.value)
                  }
                />
              </Box>

              {isloading ? (
                <Box py={1}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box py={1}>
                  <StandardStyledButton
                    customcolor={props.appData.buttonFontColor}
                    customfontcolor={props.appData.buttonColor}
                    p={"15px 16px"}
                    variant="outlined"
                    onClick={reset_pin_handler}
                  >
                    Reset PIN
                  </StandardStyledButton>
                  &nbsp;&nbsp;
                  <StandardStyledButton
                    customcolor={props.appData.buttonColor}
                    customfontcolor={props.appData.buttonFontColor}
                    p={"15px 16px"}
                    variant="contained"
                    onClick={change_pin_request}
                  >
                    Submit
                  </StandardStyledButton>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
