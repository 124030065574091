import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

export default function Loading() {
  return (
    <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <CircularProgress />
      <Typography pt={2}>Loading...</Typography>
    </Box>
  )
}
