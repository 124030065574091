import React from "react";
import {
  Box,
  Paper,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@mui/material";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";

function ListItems(props) {
  const { plan, setAmount } = props;

  console.log(plan);

  return plan.map((p, index) => (
    <ListItem divider key={"plan" + index} secondaryAction={<Box component={"div"}><StandardStyledButton variant='contained' customcolor={props.appData.buttonColor} customfontcolor={props.appData.buttonFontColor} p={"15px 16px"} onClick={() => {setAmount(p.rs); window.scrollTo(0, 0);}}>Select</StandardStyledButton></Box>}>
      <ListItemAvatar>₹{p.rs}</ListItemAvatar>
      <ListItemText
        primary={<Box sx={{pr: 4}}>{p.desc}</Box>}
        secondary={p.Type.toUpperCase() +' | Validity: '+p.validity}
      />
    </ListItem>
  ));
}

export default function PlansList(props) {
  const { plans, plankeys, setAmount, appData } = props;

  console.log(plans);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlerTabs = (index) => {
    return {
      id: `standard-tab-${index}`,
      "aria-controls": `standard-tabpanel-${index}`,
    };
  };

  return (
    <Box>
      <Paper sx={{ display: "grid" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="recharge plans tabs"
        >
          {plankeys.map((plankey, index) => (
            <Tab key={"tab" + index} label={plans[plankey] && plankey === 'FULLTT' ? plans[plankey][0].Type : plankey} {...handlerTabs(index)} />
          ))}
        </Tabs>
      </Paper>
      <Box sx={{maxHeight: "300px", overflowY: "auto"}}>
        {plankeys.map((plankey, index) => (
          <Box
            key={"list" + index}
            component={"div"}
            role="tabpanel"
            hidden={value !== index}
            id={`standard-tabpanel-${index}`}
            aria-labelledby={`standard-tab-${index}`}
          >
            {value === index && (
              <List>
                {plans[plankey] ? (
                  <ListItems plan={plans[plankey]} appData={appData} setAmount={setAmount} />
                ) : (
                  <Box sx={{width: '100%', display: 'flex', flexDirection: "column", height: "200px", justifyContent: 'center', textAlign: 'center'}}>No Plans</Box>
                )}
              </List>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
