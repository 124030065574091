import { CircularProgress, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import StandardDasboardCustomCard from '../../StandardCustomComponent/StandardDasboardCustomCard'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import StandardNotificationBar from '../../StandardCustomComponent/StandardNotificationBar';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import StandardEStoreServices from './StandardEStoreServices';
import RecentTransactions from './RecentTransactions';
import { Pie } from 'react-chartjs-2';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { StandardLineChart } from './StandardLineChart';
import { useNavigate } from 'react-router-dom';
import report_requests from '../../../../rest_api/report_requests';
import user_request from '../../../../rest_api/user_request';
ChartJS.register(ArcElement, Tooltip, Legend);

export default function StandardRetailerDashboard(props) {
    //const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;
    const { appData } = props;

    const [recentTxns, setRecentTxn] = useState([]);
    const [activeServices, setActiveServices] = useState([]);
    const [pieChartData, setPieCartData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [lineChartData, setLneChartData] = useState([]);
    const [totalSale, setTotalSale] = useState(0);
    const [totalSuccessCount, setTotalSuccessCount] = useState(0);
    const [totalPendingCount, setTotalPendingCount] = useState(0);
    const [totalFailureCount, setTotalFailureCount] = useState(0);
    const [totalSuccess, setTotalSuccess] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [totalFailure, setTotalFailure] = useState(0);
    const [isRecentTxnLoading, setRecentTxnLoading] = useState(true);
    const [useEffectAttempted, setUseEffectAttempted] = useState("first");


    const data = {
        labels: activeServices,
        datasets: [
            {
                label: '# of Sale',
                data: pieChartData,
                backgroundColor: [
                    'rgb(255, 138, 163)',
                    'rgb(64, 75, 255)',
                    'rgb(255, 218, 128)',
                    'rgb(120, 208, 208)',
                    'rgb(182, 64, 255)',
                    'rgb(209, 20, 98)',
                    'rgb(58, 209, 20)',
                    'rgb(106, 124, 1)',
                    'rgb(253, 157, 88)',
                ],
                borderColor: [
                    'rgba(255, 138, 163, 0.50)',
                    'rgba(64, 75, 255, 0.50)',
                    'rgba(255, 218, 128, 0.50)',
                    'rgba(120, 208, 208, 0.5)',
                    'rgba(182, 64, 255, 0.5)',
                    'rgba(209, 20, 98, 0.50)',
                    'rgba(58, 209, 20, 0.50)',
                    'rgba(106, 124, 1, 0.50)',
                    'rgba(253, 157, 88, 0.50)',
                ],
                borderWidth: 1,
            },
        ],
    };


    const line_chart_options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Report Statistics Chart',
            },
        },
    };

    const labels = ['', 'Yesterday', 'Today', ''];

    const line_chart_data = {
        labels,
        datasets: lineChartData,
    };



    const navigate = useNavigate();

    useEffect(() => {

        if (appData && appData.services) {
            setRecentTxnLoading(true);
            async function getUserServices() {

                

                const token = localStorage.getItem("token");
                var user_services = localStorage.getItem("user_services");
                try {
                    if (user_services === null || user_services === undefined || user_services === "" || user_services === []) {
                        const _services = await user_request.get_services(token);

                        user_services = _services.services;

                        delete user_services.id;
                        localStorage.setItem('user_services', JSON.stringify(user_services));
                    } else {
                        user_services = JSON.parse(user_services);
                        localStorage.removeItem('user_services');
                        if (useEffectAttempted === 'first') {
                            setUseEffectAttempted("re-attempt")
                            getUserServices();
                        }

                    }

                    

                    var services = [];

                    appData && appData.services.recharge && user_services.isRecharge === "1" && services.push('Recharge');
                    appData && appData.services.dmr && user_services.isDMR === "1" && services.push('DMT');
                    appData && appData.services.aeps && user_services.isAEPS === "1" && services.push('AEPS');
                    appData && appData.services.aadhaar_pay && user_services.isAadhaarPay === "1" && services.push('Aadhaar Pay');
                    appData && appData.services.matm && user_services.isMATM === "1" && services.push('MATM');
                    appData && appData.services.fastag && user_services.isFastag === "1" && services.push('FAStag');
                    appData && appData.services.credit_card_bill && user_services.isCreditCardBill === "1" && services.push('CC Bill');
                    appData && appData.services.bbps && user_services.isBBPS === "1" && services.push('BBPS');
                    appData && appData.services.pancard && user_services.isPancard === "1" && services.push('PAN Card');



                    setActiveServices(services);

                    if (useEffectAttempted === 'first') {
                        setPieCartData([0, 0, 0, 0, 0, 0, 0, 0, 0]);
                    }

                } catch (e) {

                    console.log(e.message);

                }

            }
            getUserServices();

        }


        var lineData = [
            appData && appData.services.recharge && {
                label: 'Recharge',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(255, 138, 163)',
                backgroundColor: 'rgba(255, 138, 163, 0.5)',
            },
            appData && appData.services.dmr && {
                label: 'DMT',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(64, 75, 255)',
                backgroundColor: 'rgba(64, 75, 255, 0.5)',
            },
            appData && appData.services.aeps && {
                label: 'AEPS',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(255, 218, 128)',
                backgroundColor: 'rgba(255, 218, 128, 0.5)',
            },
            !appData && appData.services.aadhaar_pay && {
                label: 'Aadhaar Pay',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(120, 208, 208)',
                backgroundColor: 'rgba(120, 208, 208, 0.5)',
            },
            appData && appData.services.matm && {
                label: 'MATM',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(182, 64, 255)',
                backgroundColor: 'rgba(182, 64, 255, 0.5)',
            },
            appData && appData.services.fastag && {
                label: 'FAStag',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(209, 20, 98)',
                backgroundColor: 'rgba(209, 20, 98, 0.5)',
            },
            appData && appData.services.credit_card_bill && {
                label: 'CC Bill',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(58, 209, 20)',
                backgroundColor: 'rgba(58, 209, 20, 0.5)',
            },
            appData && appData.services.bbps && {
                label: 'BBPS',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(106, 124, 1)',
                backgroundColor: 'rgba(106, 124, 1, 0.5)',
            },
            appData && appData.services.pancard && {
                label: 'PAN Card',
                data: [0, 0, 0, 0],
                borderColor: 'rgb(253, 157, 88)',
                backgroundColor: 'rgba(253, 157, 88, 0.5)',
            },
        ];

        setLneChartData(lineData);



        async function getRecentTxn() {

            try {
                setRecentTxnLoading(true);
                const token = localStorage.getItem("token");
                const res = await report_requests.recent_txn(token);
                setRecentTxnLoading(false);
                if (res) {
                    if (res.isLoggedIn) {
                        if (res.status === 1) {


                            const yesterdayTotalTxnSuccess = res.last_day_transactions && res.last_day_transactions.filter((txn) => txn.status.includes("1"));

                            const yesterday_recharge = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("Recharge")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const yesterday_dmt = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("DMT")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const yesterday_aeps = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("AEPS")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const yesterday_aadhaar_pay = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("Aadhaar Pay")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const yesterday_matm = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("Micro ATM") || txn.type.includes("MPOS") || txn.type.includes("Cash@Pos")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const yesterday_fastag = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("FAStag")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const yesterday_cc_bill = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("Credit Card Bill")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const yesterday_bbps = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("BBPS")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const yesterday_pancard = yesterdayTotalTxnSuccess && yesterdayTotalTxnSuccess.filter((txn) => txn.type.includes("PAN Card")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);

                            
                            const recentTxn = res.recent_transactions && res.recent_transactions.length > 6 ? res.recent_transactions.splice(0, 6) : res.recent_transactions;

                           

                            setRecentTxn(recentTxn);


                            const todayTotalTxnSuccess = res.statistics && res.statistics.filter((txn) => txn.status.includes("1"));
                            const todayTotalSale = todayTotalTxnSuccess.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const todayTotalTxnFailure = res.statistics && res.statistics.filter((txn) => txn.status.includes("2") || txn.status.includes("3"));
                            const todayTotalFailure = todayTotalTxnFailure.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const todayTotalTxnPending = res.statistics && res.statistics.filter((txn) => txn.status.includes("0") || txn.status.includes("5"));
                            const todayTotalPending = todayTotalTxnPending.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);

                            setTotalSale(todayTotalSale);
                            setTotalSuccess(todayTotalSale);
                            setTotalPending(todayTotalPending);
                            setTotalFailure(todayTotalFailure);

                            setTotalSuccessCount(todayTotalTxnSuccess.length);
                            setTotalPendingCount(todayTotalTxnPending.length);
                            setTotalFailureCount(todayTotalTxnFailure.length);


                            const today_recharge = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("Recharge")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const today_dmt = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("DMT")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const today_aeps = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("AEPS")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const today_aadhaar_pay = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("Aadhaar Pay")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const today_matm = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("Micro ATM") || txn.type.includes("MPOS") || txn.type.includes("Cash@Pos")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const today_fastag = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("FAStag")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const today_cc_bill = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("Credit Card Bill")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const today_bbps = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("BBPS")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);
                            const today_pancard = todayTotalTxnSuccess && todayTotalTxnSuccess.filter((txn) => txn.type.includes("PAN Card")).reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0);


                            var pie_data = [];

                            appData && appData.services.recharge && pie_data.push(today_recharge);
                            appData && appData.services.dmr && pie_data.push(today_dmt);
                            appData && appData.services.aeps && pie_data.push(today_aeps);
                            appData && appData.services.aadhaar_pay && pie_data.push(today_aadhaar_pay);
                            appData && appData.services.matm && pie_data.push(today_matm);
                            appData && appData.services.fastag && pie_data.push(today_fastag);
                            appData && appData.services.credit_card_bill && pie_data.push(today_cc_bill);
                            appData && appData.services.bbps && pie_data.push(today_bbps);
                            appData && appData.services.pancard && pie_data.push(today_pancard);

                            setPieCartData(pie_data);


                            lineData = [
                                appData && appData.services.recharge && {
                                    label: 'Recharge',
                                    data: [0, yesterday_recharge, today_recharge, 0],
                                    borderColor: 'rgb(255, 138, 163)',
                                    backgroundColor: 'rgba(255, 138, 163, 0.5)',
                                },
                                appData && appData.services.dmr && {
                                    label: 'DMT',
                                    data: [0, yesterday_dmt, today_dmt, 0],
                                    borderColor: 'rgb(64, 75, 255)',
                                    backgroundColor: 'rgba(64, 75, 255, 0.5)',
                                },
                                appData && appData.services.aeps && {
                                    label: 'AEPS',
                                    data: [0, yesterday_aeps, today_aeps, 0],
                                    borderColor: 'rgb(255, 218, 128)',
                                    backgroundColor: 'rgba(255, 218, 128, 0.5)',
                                },
                                appData && appData.services.aadhaar_pay && {
                                    label: 'Aadhaar Pay',
                                    data: [0, yesterday_aadhaar_pay, today_aadhaar_pay, 0],
                                    borderColor: 'rgb(120, 208, 208)',
                                    backgroundColor: 'rgba(120, 208, 208, 0.5)',
                                },
                                appData && appData.services.matm && {
                                    label: 'MATM',
                                    data: [0, yesterday_matm, today_matm, 0],
                                    borderColor: 'rgb(182, 64, 255)',
                                    backgroundColor: 'rgba(182, 64, 255, 0.5)',
                                },
                                appData && appData.services.fastag && {
                                    label: 'FAStag',
                                    data: [0, yesterday_fastag, today_fastag, 0],
                                    borderColor: 'rgb(209, 20, 98)',
                                    backgroundColor: 'rgba(209, 20, 98, 0.5)',
                                },
                                appData && appData.services.credit_card_bill && {
                                    label: 'CC Bill',
                                    data: [0, yesterday_cc_bill, today_cc_bill, 0],
                                    borderColor: 'rgb(58, 209, 20)',
                                    backgroundColor: 'rgba(58, 209, 20, 0.5)',
                                },
                                appData && appData.services.bbps && {
                                    label: 'BBPS',
                                    data: [0, yesterday_bbps, today_bbps, 0],
                                    borderColor: 'rgb(106, 124, 1)',
                                    backgroundColor: 'rgba(106, 124, 1, 0.5)',
                                },
                                appData && appData.services.pancard && {
                                    label: 'PAN Card',
                                    data: [0, yesterday_pancard, today_pancard, 0],
                                    borderColor: 'rgb(253, 157, 88)',
                                    backgroundColor: 'rgba(253, 157, 88, 0.5)',
                                },
                            ];

                            setLneChartData(lineData);


                        }
                    } else {
                        localStorage.removeItem("services");
                        localStorage.removeItem("user");
                        localStorage.removeItem("token");
                        localStorage.clear();
                        navigate('/login');
                    }
                }

            } catch (e) {

            }

        }

        getRecentTxn();

    }, [appData, navigate, useEffectAttempted])




    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <StandardNotificationBar sx={{ mt: 1, display: { xs: 'block', sm: 'none' }, overflow: 'hidden' }} appname={appData !== undefined ? appData.title : ""} />
            <Box sx={{ display: { xs: 'block', sm: 'none' }, }}>
                <Typography variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>E-Store</Typography>
                <Paper sx={{ mt: 1, mb: 3, boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>

                    {appData.services && activeServices ? <StandardEStoreServices activeServices={activeServices} {...props} /> : <Box>No service active on your account</Box>}

                </Paper>
            </Box>

            <Typography variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Dashboard</Typography>

            {/* Statistics Card  */}
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <StandardDasboardCustomCard {...props} Icon={DescriptionOutlinedIcon} title={"Today's Sale"} subtitle={totalSale.toFixed(2)} backgroundColor={appData !== undefined && appData.dashboardCardBgColor1 != null ? appData.dashboardCardBgColor1 : '#299f4b'} />
                <StandardDasboardCustomCard {...props} Icon={CheckOutlinedIcon} title={`Success (${totalSuccessCount})`} subtitle={totalSuccess.toFixed(2)} backgroundColor={appData !== undefined && appData.dashboardCardBgColor2 != null ? appData.dashboardCardBgColor2 : '#4a6ecc'} />
                <StandardDasboardCustomCard {...props} Icon={PriorityHighOutlinedIcon} title={`Pending (${totalPendingCount})`} subtitle={totalPending.toFixed(2)} backgroundColor={appData !== undefined && appData.dashboardCardBgColor3 != null ? appData.dashboardCardBgColor3 : '#ee8031'} />
                <StandardDasboardCustomCard {...props} Icon={CloseOutlinedIcon} title={`Failed (${totalFailureCount})`} subtitle={totalFailure.toFixed(2)} backgroundColor={appData !== undefined && appData.dashboardCardBgColor4 != null ? appData.dashboardCardBgColor4 : '#b14e07'} />
            </Box>

            {/* Notification component for large screen */}
            <StandardNotificationBar sx={{ mt: 1, display: { xs: 'none', sm: 'block' }, overflow: 'hidden' }} appname={appData !== undefined ? appData.title : ""} />


            {/*E-store for services & recent transactions */}

            <Box sx={{ my: { xs: 1, sm: 3 }, display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }}>


                <Box sx={{ flexGrow: 1, minWidth: '280px',  mb: 2, maxWidth: { sm: '100%', md: 'calc(77% - 170px)', lg: 'calc(75% - 80px)' } }}>

                    {/*e-Store for services on large screen*/}
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, }}>
                        <Typography variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>E-Store</Typography>
                        <Paper sx={{ mt: 1, boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                            {appData.services && activeServices ? <StandardEStoreServices activeServices={activeServices} {...props} /> : <Box>No service active on your account</Box>}
                        </Paper>
                    </Box>

                    {/* Statistics Line Chart */}
                    <Typography mt={4} variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Statistics</Typography>
                    <Paper sx={{ mt: 2, boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                        <Box p={1}>
                            <StandardLineChart options={line_chart_options} data={line_chart_data} />
                        </Box>
                    </Paper>

                </Box>

                <Box sx={{ width: '20px', display: { xs: 'none', sm: 'block' } }}></Box>

                <Box sx={{ flexGrow: 1, minWidth: '280px', width: '100%', maxWidth: { sm: '100%', md: '23%',  lg:'25%' } }} >

                    {/*Recent Transaction*/}
                    <Typography variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Recent Transactions</Typography>
                    <Paper sx={{ overflow: 'hidden', mt: 1, boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                        <RecentTransactions {...props} isRecentTxnLoading={isRecentTxnLoading} list={recentTxns} />
                    </Paper>

                    {/*Pie Chart for statistics*/}
                    <Typography mt={3} variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Today's Statistics</Typography>

                    <Paper sx={{ overflow: 'hidden', mt: 1, boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                        <Box p={2}>
                            {data ? <Pie
                                data={data}
                            />
                                : <CircularProgress />
                            }
                        </Box>
                    </Paper>
                </Box>


            </Box>
        </Box>
    )
}
