import { AppBar, Toolbar, Box, IconButton, Link, Paper, Typography, Button } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import StandardStyledTextfield from '../StandardCustomComponent/StandardStyledTextfield';
import StandardStyledButton from '../StandardCustomComponent/StandardStyledButton';

export default function StandardResetPassword(props) {

    const { appData } = props;
    const navigate = useNavigate();
    const [isOTPSent, setOTPStatus] = React.useState(false)
    const [mobile, setMobile] = React.useState("")
    const [otp, setOTP] = React.useState("")
    const [pwd1, setPwd1] = React.useState("")
    const [pwd2, setPwd2] = React.useState("")
    const [isError, setError] = React.useState("")

    const sendOTP = (event) => {
        event.preventDefault()

        if (!isOTPSent) {
            if (mobile !== null && mobile.length === 10) {
                setOTPStatus(!isOTPSent)
            } else {
                setError('mobile');
            }
        }
    }

    return (
        <Box sx={{ backgroundColor: { xs: '#ffffff', sm: '#f0f0f0' } }}>
            <AppBar sx={{ boxShadow: { xs: 'none', sm: '0px 0px 6px 0px rgb(0 0 0 / 20%)' }, backgroundColor: appData.appBarColor != null ? appData.appBarColor : 'primary', color: appData.appBarFontColor != null ? appData.appBarFontColor : 'secondary' }} component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => { navigate('/'); }}
                        sx={{ mr: 2 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: "center" }}>
                        <Link href="/" underline="none" color={appData.appBarFontColor != null ? appData.appBarFontColor : 'inherit'}>
                            <img height={"24px"} src={appData.logo} alt={appData.title} />
                        </Link>
                    </Box>
                    <Box ml={1} py={1} px={3}></Box>
                </Toolbar>
            </AppBar>

            <Box p={3} sx={{ display: 'flex', height: 'calc(100vh - 48px)', justifyContent: 'center', alignItems: 'center' }}>
                <Paper elevation={0} sx={{ boxShadow: { xs: 'none', sm: '0px 0px 6px 0px rgb(0 0 0 / 10%)' }, maxWidth: '360px', width: '100%', display: 'block', borderRadius: '20px' }}>
                    <Box p={3}>
                        <Box sx={{ justifyContent: 'center' }}>
                            <Typography variant='h6' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                                Reset Password
                            </Typography>
                            {
                                isOTPSent ?
                                    <Typography variant='body2' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                                        Enter valid OTP & create new password
                                    </Typography> :
                                    <Typography variant='body2' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                                        Enter your registered mobile number.
                                    </Typography>
                            }
                        </Box>

                        {/* login form */}
                        <Box py={3} component="form" onSubmit={sendOTP}>
                            {
                                !isOTPSent ?
                                    <StandardStyledTextfield
                                        fullWidth
                                        value={mobile}
                                        onChange={(event) => { event.target.value.length <= 10 && setMobile(event.target.value) }}
                                        variant="outlined"
                                        error={isError === "mobile" || isError === "all"}
                                        sx={{ borderColor: '#f0f0f0', }}
                                        placeholder='Mobile Number' />


                                    :
                                    <>
                                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Typography variant='caption'>OTP Sent on: <strong>{mobile}</strong></Typography>
                                            <Button color='success' onClick={() => {setOTPStatus(false)}}>Change</Button>
                                        </Box>
                                        <Box py={1}></Box>
                                        <StandardStyledTextfield
                                            fullWidth
                                            value={otp}
                                            onChange={(event) => { event.target.value.length <= 10 && setOTP(event.target.value) }}
                                            variant="outlined"
                                            sx={{ borderColor: '#f0f0f0', }}
                                            type="password"
                                            placeholder='OTP' />
                                        <Box py={1}></Box>
                                        <StandardStyledTextfield
                                            fullWidth
                                            value={pwd1}
                                            onChange={(event) => { event.target.value.length <= 10 && setPwd1(event.target.value) }}
                                            variant="outlined"
                                            error={isError === "password" || isError === "all"}
                                            type="password"
                                            sx={{ borderColor: '#f0f0f0', }}
                                            placeholder='Create Password' />
                                        <Box py={1}></Box>
                                        <StandardStyledTextfield
                                            fullWidth
                                            value={pwd2}
                                            onChange={(event) => { event.target.value.length <= 10 && setPwd2(event.target.value) }}
                                            variant="outlined"
                                            error={isError === "password" || isError === "all"}
                                            sx={{ borderColor: '#f0f0f0', }}
                                            placeholder='Confirm Password' />
                                    </>
                            }

                            <Box pt={1}></Box>
                            {
                                !isOTPSent ?
                                    <StandardStyledButton customcolor={props.appData.buttonFontColor} customfontcolor={props.appData.buttonColor} fullWidth variant='conained' onClick={sendOTP}>Get OTP</StandardStyledButton>
                                    :
                                    <StandardStyledButton customcolor={props.appData.buttonColor} customfontcolor={props.appData.buttonFontColor} fullWidth variant='contained' onClick={sendOTP}>Change Password</StandardStyledButton>
                            }

                            {
                                isOTPSent ? <Box p={1} align="center">
                                    <Link href="/" underline="none">
                                        <Typography p={1} variant='caption'>Resend OTP</Typography>
                                    </Link>
                                </Box> :
                                    <Box p={1} align="center">
                                        <Link href="#" onClick={() => navigate(-1)} underline="none">
                                            <Typography p={1} variant='caption'>Go Back to Login</Typography>
                                        </Link>
                                    </Box>
                            }
                        </Box>

                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}
