import { Box, Paper, Typography } from '@mui/material'
import React from 'react'


export default function StandardMatmPage(props) {
    const { appData } = props;


    return (
        <Box >
            <Typography variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>Micro ATM & POS</Typography>
            <Box mt={1} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Paper sx={{ flexGrow: 1, overflow: 'hidden', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                    <Box sx={{ py: 5, px:3 }}>
                        <Typography component={'div'} mb={1} align='center' sx={{ fontSize: '16px', fontWeight: '500', color: `${appData.mainHeadingColor}` }}>This service is only available on mobile devices.</Typography>
                        <Typography component={'p'} variant='body2' align="center" sx={{ color: `${appData.mainHeadingColor}` }}>Please use our mobile application to use Micro ATM & POS.</Typography>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}
