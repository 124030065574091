import {
  Box,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import help_request from "../../../../rest_api/help_request";
import { format } from "date-fns";

export default function StandardTicketHistory(props) {
  const { open_snackbar, snackbar_severity, snackbar_message } = props;
  const [isloading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [tickets, setTickets] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    const getTicketList = async () => {
      try {
        setLoading(true);

        const res = await help_request.getRecentTicketsList();
        console.log(res);
        setLoading(false);
        if (res && res.status === 2) {
          setError(res.message);
        }
        if (res && res.status === 1) {
          setTickets(res.complaints);
        }

        if (res && !res.isLoggedIn) {
          navigate("/login");
        }
      } catch (e) {
        setError(e.message);
      }
    };
    getTicketList();
  }, [navigate]);

  React.useEffect(() => {
    if (error && error !== "") {
      snackbar_severity("error");
      snackbar_message(error);
      open_snackbar(true);
    }
  }, [error, open_snackbar, snackbar_message, snackbar_severity]);

  const columns = [
    "S.N",
    "COMPLAINT ID",
    "CREATED AT",
    "CLOSED AT",
    "TYPE",
    "TID",
    "YOUR MSG",
    "RESPONSE",
    "STATUS",
  ];

  return (
    <>
      {isloading ? (
        <Box p={2}>
          <CircularProgress />
        </Box>
      ) : tickets.length > 0 ? (
        <TableContainer>
          <Table aria-label="offer table">
            <TableHead>
              <TableRow>
                {columns.map((clm, index) => (
                  <TableCell key={index + "" + clm}>{clm}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.map((row, index) => (
                <Row key={"complaint" + index} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (<Box p={2}><Typography>No Ticket Available</Typography></Box>)}
    </>
  );
}

function Row(props) {
  const { row, index } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell>{row.id ? row.id : "-"}</TableCell>
        <TableCell>
          {row.createdAt
            ? format(new Date(row.createdAt), "dd-MM-yyyy hh:mm:ss a")
            : "-"}
        </TableCell>
        <TableCell>{row.updatedAt ? row.updatedAt : "-"}</TableCell>
        <TableCell>{row.category ? row.category : "-"}</TableCell>
        <TableCell>{row.trans_id}</TableCell>
        <TableCell>{row.message ? row.message : "-"}</TableCell>
        <TableCell>{row.replay ? row.replay : "-"}</TableCell>
        <TableCell>
          {row.status === 1 ? (
            <Chip size="small" color="primary" label="Success" />
          ) : (
            <Chip size="small" color="warning" label="Pending" />
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
