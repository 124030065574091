import ArrowBack from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";
import user_request from "../../../../../../rest_api/user_request";
import dmt_request from "../../../../../../rest_api/dmt_request";

export default function StandardDmtConfirmationPage(props) {
  const { open_snackbar, snackbar_severity, snackbar_message } = props;
  const [amount, setAmount] = useState("");
  const [transactionPIN, setTransactionPIN] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [rate, setRate] = useState(0.5);
  const [minRate, setminRate] = useState(6.0);
  const [package_slab, setPackage] = useState([]);
  const [charge, setCharge] = useState(0);
  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [mode, setMode] = useState("IMPS");
  const { appData } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const beneficiary = location.state;

  useEffect(() => {
    setRate(0.5);
    if (
      amount !== null &&
      amount !== "" &&
      package_slab &&
      package_slab.length > 0
    ) {
      setCharge(
        (parseInt(amount) * rate) / 100 < minRate
          ? minRate
          : (parseInt(amount) * rate) / 100
      );
      setTotalAmount(
        parseInt(amount) +
          ((parseInt(amount) * rate) / 100 < minRate
            ? minRate
            : (parseInt(amount) * rate) / 100)
      );
    } else {
      setCharge(0);
      setTotalAmount(0);
    }
  }, [amount, rate, minRate, package_slab]);

  useEffect(() => {
    async function get_charges() {
      try {
        var saved_package = localStorage.getItem("package")
          ? JSON.parse(localStorage.getItem("package"))
          : null;

        if (saved_package) {
          const dmt_package = saved_package.filter(
            (p) => p.operator === "DMT" && p.service_type === mode
          );

          setPackage(dmt_package);

          const percentage_slab = dmt_package.filter(
            (p) => p.slab_calculation === "Percentage"
          );
          const flat_slab = dmt_package.filter(
            (p) => p.slab_calculation === "Flat"
          );

          setRate(parseFloat(percentage_slab[0].slab));
          setminRate(parseFloat(flat_slab[0].slab));
        } else {
          const token = localStorage.getItem("token");
          const package_res = await user_request.get_package(token);

          if (package_res) {
            if (package_res.isLoggedIn) {
              if (package_res.status === 1) {
                localStorage.setItem(
                  "package",
                  JSON.stringify(package_res.package)
                );

                const dmt_package = package_res.package.filter(
                  (p) => p.operator === "DMT" && p.service_type === mode
                );

                setPackage(dmt_package);

                const percentage_slab = dmt_package.filter(
                  (p) => p.slab_calculation === "Percentage"
                );
                const flat_slab = dmt_package.filter(
                  (p) => p.slab_calculation === "Flat"
                );

                setRate(parseFloat(percentage_slab[0].slab));
                setminRate(parseFloat(flat_slab[0].slab));
              } else {
                setError(package_res.message);
              }
            } else {
              setError(package_res.message);
              localStorage.clear();
              navigate("/login");
            }
          } else {
            setError(
              "Network error. Please check your internet connection & try again."
            );
          }
        }
      } catch (e) {}
    }

    get_charges();
  }, [beneficiary, navigate, mode]);

  useEffect(() => {
    if (error && error !== "") {

      snackbar_message(error);
      open_snackbar(true);
      snackbar_severity("error");

      setError("");

    }
  }, [error, snackbar_message, open_snackbar, snackbar_severity]);

  const transferHandler = async () => {
    if (
      beneficiary &&
      beneficiary.id &&
      amount &&
      parseFloat(amount) > 9 &&
      parseFloat(beneficiary.available_limit) >= parseFloat(amount) && parseFloat(amount) <= 50000 &&
      mode &&
      transactionPIN &&
      transactionPIN.length === 4
    ) {
      try {
        const token = localStorage.getItem("token");
        const wallet = localStorage.getItem("wallet");
        const payload = {
          beneId: beneficiary.id,
          bank: beneficiary.bene_bank,
          account: beneficiary.bene_account,
          wallet: wallet,
          ifsc: beneficiary.bene_ifsc,
          amount: amount,
          mode: mode,
          txn_pin: transactionPIN,
        };
        setLoading(true);
        const res = await dmt_request.process_transaction(payload, token);
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              navigate("/portal/dmt/receipt", {
                state: { ...beneficiary, wallet: wallet, ...res.payload },
                replace: true,
              });
            } else {
              open_snackbar(true);
              snackbar_severity("error");
              snackbar_message(res.message);
            }
          } else {
            open_snackbar(true);
            snackbar_severity("error");
            snackbar_message(res.message);

            localStorage.clear();
            navigate("/login");
          }
        } else {
          open_snackbar(true);
          snackbar_severity("error");
          snackbar_message(
            "No response. Please transaction history for status of this transaction"
          );
        }
      } catch (e) {
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message("An error occured {" + e.message + "}");
      }
    } else if (beneficiary === null || beneficiary.id === null) {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message("Something went wrong. Please go back and try again.");
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        DMT
      </Typography>
      <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
        <Paper
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box p={{ xs: 3, sm: 2 }}>
            <Box
              sx={{
                mb: { xs: 2, sm: 1 },
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ display: { xs: "none", sm: "inline-flex" } }}
                onClick={() => navigate(-1)}
              >
                <ArrowBack />
              </IconButton>
              <Typography sx={{ fontWeight: "600" }}>
                Confirm Transaction Details
              </Typography>
            </Box>
            <Box sx={{ mt: 1, mb: { xs: 1, sm: 3 } }}>
              <Divider />
            </Box>
            <Grid container spacing={2} columns={12}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Name</Typography>
                  <Typography variant="body2">
                    {beneficiary.is_verified
                      ? beneficiary.account_name
                      : beneficiary.bene_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Account</Typography>
                  <Typography variant="body2">
                    {beneficiary.bene_account}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Bank</Typography>
                  <Typography variant="body2">
                    {beneficiary.bene_bank}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">IFSC</Typography>
                  <Typography variant="body2">
                    {beneficiary.bene_ifsc}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Is Verified?</Typography>
                  <Typography variant="body2">
                    {!beneficiary.is_verified ? "Not Verified" : "Verified"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Status</Typography>
                  <Typography variant="body2">Active</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Paper
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box p={3}>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "600" }}>
                Confirm Payment Details
              </Typography>
            </Box>
            <Box sx={{ mt: 1, mb: { xs: 1, sm: 3 } }}>
              <Divider />
            </Box>
            <Grid container spacing={2} columns={12}>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="caption">Rate</Typography>
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: "600" }}>
                      {rate}%
                    </Typography>
                    {minRate !== "" && (
                      <Typography sx={{ fontSize: "12px" }}>
                        Mini. charge is Rs.{minRate}/-
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="caption">Charges</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "500" }}>
                    {totalAmount > 0 && charge < 6 ? "6" : charge}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="caption">Total Payable</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "500" }}>
                    {totalAmount}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} mt={1}>
              <Grid item xs={12} sm={3}>
                <Box>
                  <StandardStyledButton
                    buttonColor="success"
                    variant={mode === "IMPS" ? "contained" : "text"}
                    onClick={() => setMode("IMPS")}
                  >
                    IMPS
                  </StandardStyledButton>
                  <StandardStyledButton
                    customcolor="success"
                    variant={mode === "NEFT" ? "contained" : "text"}
                    sx={{ ml: 1 }}
                    onClick={() => setMode("NEFT")}
                  >
                    NEFT
                  </StandardStyledButton>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Box>
                  <StandardStyledTextfield
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                    type={"number"}
                    placeholder={"Amount (Max. "+beneficiary.available_limit+"/-)"}
                    value={amount}
                    onChange={(e) =>
                      ((e.target.value.length < 6 && parseFloat(beneficiary.available_limit) >= parseFloat(e.target.value)) || e.target.value === "") && setAmount(e.target.value)
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Box>
                  <StandardStyledTextfield
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                    type={"password"}
                    placeholder="Transaction PIN"
                    value={transactionPIN}
                    onChange={(e) =>
                      e.target.value.length < 5
                        ? setTransactionPIN(e.target.value)
                        : {}
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  {isloading ? (
                    <CircularProgress />
                  ) : (
                    <StandardStyledButton
                      sx={{ width: { xs: "100%", sm: "auto" } }}
                      variant="contained"
                      p={"15px 16px"}
                      customcolor={appData.buttonColor}
                      customfontcolor={appData.buttonFontColor}
                      onClick={transferHandler}
                    >
                      Confirm & Pay
                    </StandardStyledButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
