import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import StandardDasboardCustomCard from "../../StandardCustomComponent/StandardDasboardCustomCard";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import StandardNotificationBar from "../../StandardCustomComponent/StandardNotificationBar";
import StandardEStoreServices from "./StandardEStoreServices";
import RecentTransactions from "./RecentTransactions";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { StandardLineChart } from "./StandardLineChart";
import { useNavigate } from "react-router-dom";
import report_requests from "../../../../rest_api/report_requests";
import user_request from "../../../../rest_api/user_request";
import CurrencyExchangeOutlined from "@mui/icons-material/CurrencyExchangeOutlined";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";
import dt_user_request from "../../../../rest_api/dt_user_request";
ChartJS.register(ArcElement, Tooltip, Legend);

export default function StandardDistributorDashboard(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;

  const [error, setError] = useState("");
  const [recentTxns, setRecentTxn] = useState([]);
  const [activeServices, setActiveServices] = useState([]);
  const [lineChartData, setLneChartData] = useState([]);
  const [totalSale, setTotalSale] = useState(0);
  const [totalBillingCount, setTotalBillingCount] = useState(0);
  const [totalEarnCount, setTotalEarnCount] = useState(0);
  const [totalBilling, setTotalBilling] = useState(0);
  const [totalEarn, setTotalEarn] = useState(0);
  const [totalAgent, setTotalAgent] = useState(0);
  const [newAgent, setNewAgent] = useState(0);
  const [isRecentTxnLoading, setRecentTxnLoading] = useState(true);
  const [useEffectAttempted, setUseEffectAttempted] = useState("first");

  const line_chart_options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Report Statistics Chart",
      },
    },
  };

  const labels = ["", "Yesterday", "Today", ""];

  const line_chart_data = {
    labels,
    datasets: lineChartData,
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (appData && appData.services) {
      setRecentTxnLoading(true);
      async function getUserServices() {
        const token = localStorage.getItem("token");
        var user_services = localStorage.getItem("user_services");

        try {
          if (
            user_services === null ||
            user_services === undefined ||
            user_services === "" ||
            user_services === []
          ) {
            const _services = await user_request.get_services(token);

            user_services = _services.services;
            console.log(user_services);
            delete user_services.id;
            localStorage.setItem(
              "user_services",
              JSON.stringify(user_services)
            );
          } else {
            user_services = JSON.parse(user_services);
            localStorage.removeItem("user_services");
            if (useEffectAttempted === "first") {
              setUseEffectAttempted("re-attempt");
              getUserServices();
            }
          }

          var services = [];

          if (user_services) {
            appData &&
              appData.services.recharge &&
              user_services.isRecharge === "1" &&
              services.push("Recharge");
            appData &&
              appData.services.dmr &&
              user_services.isDMR === "1" &&
              services.push("DMT");
            appData &&
              appData.services.aeps &&
              user_services.isAEPS === "1" &&
              services.push("AEPS");
            appData &&
              appData.services.aadhaar_pay &&
              user_services.isAadhaarPay === "1" &&
              services.push("Aadhaar Pay");
            appData &&
              appData.services.matm &&
              user_services.isMATM === "1" &&
              services.push("MATM");
            appData &&
              appData.services.fastag &&
              user_services.isFastag === "1" &&
              services.push("FAStag");
            appData &&
              appData.services.credit_card_bill &&
              user_services.isCreditCardBill === "1" &&
              services.push("CC Bill");
            appData &&
              appData.services.bbps &&
              user_services.isBBPS === "1" &&
              services.push("BBPS");
            appData &&
              appData.services &&
              appData.services.pancard &&
              user_services.isPancard &&
              user_services.isPancard === "1" &&
              services.push("PAN Card");
          }
          setActiveServices(services);
        } catch (e) {
          console.log(e.message);
        }
      }
      getUserServices();
    }

    var lineData = [
      appData &&
        appData.services.recharge && {
          label: "Recharge",
          data: [0, 0, 0, 0],
          borderColor: "rgb(255, 138, 163)",
          backgroundColor: "rgba(255, 138, 163, 0.5)",
        },
      appData &&
        appData.services.dmr && {
          label: "DMT",
          data: [0, 0, 0, 0],
          borderColor: "rgb(64, 75, 255)",
          backgroundColor: "rgba(64, 75, 255, 0.5)",
        },
      appData &&
        appData.services.aeps && {
          label: "AEPS",
          data: [0, 0, 0, 0],
          borderColor: "rgb(255, 218, 128)",
          backgroundColor: "rgba(255, 218, 128, 0.5)",
        },
      appData &&
        appData.services.aadhaar_pay && {
          label: "Aadhaar Pay",
          data: [0, 0, 0, 0],
          borderColor: "rgb(120, 208, 208)",
          backgroundColor: "rgba(120, 208, 208, 0.5)",
        },
      appData &&
        appData.services.matm && {
          label: "MATM",
          data: [0, 0, 0, 0],
          borderColor: "rgb(182, 64, 255)",
          backgroundColor: "rgba(182, 64, 255, 0.5)",
        },
      appData &&
        appData.services.fastag && {
          label: "FAStag",
          data: [0, 0, 0, 0],
          borderColor: "rgb(209, 20, 98)",
          backgroundColor: "rgba(209, 20, 98, 0.5)",
        },
      appData &&
        appData.services.credit_card_bill && {
          label: "CC Bill",
          data: [0, 0, 0, 0],
          borderColor: "rgb(58, 209, 20)",
          backgroundColor: "rgba(58, 209, 20, 0.5)",
        },
      appData &&
        appData.services.bbps && {
          label: "BBPS",
          data: [0, 0, 0, 0],
          borderColor: "rgb(106, 124, 1)",
          backgroundColor: "rgba(106, 124, 1, 0.5)",
        },
      appData &&
        appData.services &&
        appData.services.pancard && {
          label: "PAN Card",
          data: [0, 0, 0, 0],
          borderColor: "rgb(253, 157, 88)",
          backgroundColor: "rgba(253, 157, 88, 0.5)",
        },
    ];

    setLneChartData(lineData);

    async function getRecentTxn() {
      try {
        setRecentTxnLoading(true);
        const token = localStorage.getItem("token");
        const res = await report_requests.recent_txn(token);
        setRecentTxnLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              const yesterdayTotalTxnSuccess =
                res.last_day_transactions &&
                res.last_day_transactions.filter((txn) =>
                  txn.status.includes("1")
                );

              const yesterday_recharge =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("Recharge"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const yesterday_dmt =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("DMT"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const yesterday_aeps =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("AEPS"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const yesterday_aadhaar_pay =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("Aadhaar Pay"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const yesterday_matm =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter(
                    (txn) =>
                      txn.type.includes("Micro ATM") ||
                      txn.type.includes("MPOS") ||
                      txn.type.includes("Cash@Pos")
                  )
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const yesterday_fastag =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("FAStag"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const yesterday_cc_bill =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("Credit Card Bill"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const yesterday_bbps =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("BBPS"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const yesterday_pancard =
                yesterdayTotalTxnSuccess &&
                yesterdayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("PAN Card"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );

              const recentTxn =
                res.recent_transactions && res.recent_transactions.length > 6
                  ? res.recent_transactions.splice(0, 6)
                  : res.recent_transactions;

              setRecentTxn(recentTxn);

              const todayTotalTxnSuccess =
                res.statistics &&
                res.statistics.filter((txn) => txn.status.includes("1"));

              const todayTotalSaleTxn = todayTotalTxnSuccess.filter(
                (txn) => txn.type === "Fund Transfer"
              );
              const todayTotalBillingTxn = todayTotalTxnSuccess.filter(
                (txn) => txn.type === "TopUp"
              );

              const todayTotalSale = todayTotalSaleTxn.reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue.amount),
                0
              );

              const todayTotalBilling = todayTotalBillingTxn.reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue.amount),
                0
              );

              const todayTotalCommission = todayTotalTxnSuccess.reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue.commission),
                0
              );

              const todayTotalCharge = todayTotalTxnSuccess.reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue.charge),
                0
              );

              setTotalSale(todayTotalSale ? todayTotalSale : 0);
              setTotalBilling(todayTotalBilling ? todayTotalBilling : 0);
              setTotalEarn(todayTotalCommission - todayTotalCharge);

              setTotalBillingCount(
                todayTotalBilling && todayTotalBilling.length
              );
              setTotalEarnCount(
                todayTotalCommission && todayTotalCommission.length
              );

              const today_recharge =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("Recharge"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const today_dmt =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("DMT"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const today_aeps =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("AEPS"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const today_aadhaar_pay =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("Aadhaar Pay"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const today_matm =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter(
                    (txn) =>
                      txn.type.includes("Micro ATM") ||
                      txn.type.includes("MPOS") ||
                      txn.type.includes("Cash@Pos")
                  )
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const today_fastag =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("FAStag"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const today_cc_bill =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("Credit Card Bill"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const today_bbps =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("BBPS"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );
              const today_pancard =
                todayTotalTxnSuccess &&
                todayTotalTxnSuccess
                  .filter((txn) => txn.type.includes("PAN Card"))
                  .reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0
                  );

              lineData = [
                appData &&
                  appData.services.recharge && {
                    label: "Recharge",
                    data: [0, yesterday_recharge, today_recharge, 0],
                    borderColor: "rgb(255, 138, 163)",
                    backgroundColor: "rgba(255, 138, 163, 0.5)",
                  },
                appData &&
                  appData.services.dmr && {
                    label: "DMT",
                    data: [0, yesterday_dmt, today_dmt, 0],
                    borderColor: "rgb(64, 75, 255)",
                    backgroundColor: "rgba(64, 75, 255, 0.5)",
                  },
                appData &&
                  appData.services.aeps && {
                    label: "AEPS",
                    data: [0, yesterday_aeps, today_aeps, 0],
                    borderColor: "rgb(255, 218, 128)",
                    backgroundColor: "rgba(255, 218, 128, 0.5)",
                  },
                appData &&
                  appData.services.aadhaar_pay && {
                    label: "Aadhaar Pay",
                    data: [0, yesterday_aadhaar_pay, today_aadhaar_pay, 0],
                    borderColor: "rgb(120, 208, 208)",
                    backgroundColor: "rgba(120, 208, 208, 0.5)",
                  },
                appData &&
                  appData.services.matm && {
                    label: "MATM",
                    data: [0, yesterday_matm, today_matm, 0],
                    borderColor: "rgb(182, 64, 255)",
                    backgroundColor: "rgba(182, 64, 255, 0.5)",
                  },
                appData &&
                  appData.services.fastag && {
                    label: "FAStag",
                    data: [0, yesterday_fastag, today_fastag, 0],
                    borderColor: "rgb(209, 20, 98)",
                    backgroundColor: "rgba(209, 20, 98, 0.5)",
                  },
                appData &&
                  appData.services.credit_card_bill && {
                    label: "CC Bill",
                    data: [0, yesterday_cc_bill, today_cc_bill, 0],
                    borderColor: "rgb(58, 209, 20)",
                    backgroundColor: "rgba(58, 209, 20, 0.5)",
                  },
                appData &&
                  appData.services.bbps && {
                    label: "BBPS",
                    data: [0, yesterday_bbps, today_bbps, 0],
                    borderColor: "rgb(106, 124, 1)",
                    backgroundColor: "rgba(106, 124, 1, 0.5)",
                  },
                appData &&
                  appData.services &&
                  appData.services.pancard && {
                    label: "PAN Card",
                    data: [0, yesterday_pancard, today_pancard, 0],
                    borderColor: "rgb(253, 157, 88)",
                    backgroundColor: "rgba(253, 157, 88, 0.5)",
                  },
              ];

              setLneChartData(lineData);
            }
          } else {
            localStorage.removeItem("services");
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.clear();
            navigate("/login");
          }
        }
      } catch (e) {}
    }

    getRecentTxn();
  }, [appData, navigate, useEffectAttempted]);

  useEffect(() => {
    const getAgentStats = async () => {
      try {
        const res = await dt_user_request.get_agent_stats();

        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              setError("");
              setNewAgent(res.recent_agents);
              setTotalAgent(res.total_agents);
            } else {
              setError(res.message);
            }
          } else {
            setError(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError("No response key");
        }
      } catch (e) {
        setError(e.message);
      }
    };

    getAgentStats();
  }, [navigate]);

  useEffect(() => {
    if (error && error !== "") {
      snackbar_severity("error");
      snackbar_message(error);
      open_snackbar(true);
    }
  }, [error, open_snackbar, snackbar_severity, snackbar_message]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <StandardNotificationBar
        sx={{ mt: 1, display: { xs: "block", sm: "none" }, overflow: "hidden" }}
        appname={appData !== undefined ? appData.title : ""}
      />
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
          E-Store
        </Typography>
        <Paper
          sx={{
            mt: 1,
            mb: 3,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          {appData.services && activeServices ? (
            <StandardEStoreServices
              activeServices={activeServices}
              {...props}
            />
          ) : (
            <Box>No service active on your account</Box>
          )}
        </Paper>
      </Box>

      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Dashboard
      </Typography>

      {/* Statistics Card  */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <StandardDasboardCustomCard
          {...props}
          Icon={DescriptionOutlinedIcon}
          title={"Today's Sale"}
          subtitle={totalSale.toFixed(2)}
          backgroundColor={
            appData !== undefined && appData.dashboardCardBgColor1 != null
              ? appData.dashboardCardBgColor1
              : "#299f4b"
          }
        />
        <StandardDasboardCustomCard
          {...props}
          Icon={CheckOutlinedIcon}
          title={`Billing (${totalBillingCount})`}
          subtitle={totalBilling.toFixed(2)}
          backgroundColor={
            appData !== undefined && appData.dashboardCardBgColor2 != null
              ? appData.dashboardCardBgColor2
              : "#4a6ecc"
          }
        />
        <StandardDasboardCustomCard
          {...props}
          Icon={CurrencyExchangeOutlined}
          title={`Earning (${totalEarnCount})`}
          subtitle={totalEarn.toFixed(2)}
          backgroundColor={
            appData !== undefined && appData.dashboardCardBgColor3 != null
              ? appData.dashboardCardBgColor3
              : "#ee8031"
          }
        />
        <StandardDasboardCustomCard
          {...props}
          Icon={PersonAddIcon}
          title={`New Agent`}
          subtitle={newAgent}
          backgroundColor={"#76018b"}
        />
      </Box>

      {/* Notification component for large screen */}
      <StandardNotificationBar
        sx={{ mt: 1, display: { xs: "none", sm: "block" }, overflow: "hidden" }}
        appname={appData !== undefined ? appData.title : ""}
      />

      {/*E-store for services & recent transactions */}

      <Box
        sx={{
          my: { xs: 1, sm: 3 },
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            mb: 2,
            maxWidth: { xs: "100%", md: "calc(50% - 20px)" },
          }}
        >
          {/* Statistics Line Chart */}
          <Typography
            variant="h6"
            sx={{ color: `${appData.mainHeadingColor}` }}
          >
            Statistics
          </Typography>
          <Paper
            sx={{
              mt: 1,
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
              borderRadius: `${
                appData !== undefined && appData.cardRadius !== null
                  ? appData.cardRadius
                  : "6px"
              }`,
            }}
          >
            <Box p={1}>
              <StandardLineChart
                options={line_chart_options}
                data={line_chart_data}
              />
            </Box>
          </Paper>
        </Box>

        <Box sx={{ width: "40px", display: { xs: "none", md: "block" } }}></Box>

        <Box
          sx={{ flexGrow: 1, maxWidth: { xs: "100%", md: "calc(50% - 20px)" } }}
        >
          {/*Agent Overview*/}
          <Typography
            variant="h6"
            sx={{ color: `${appData.mainHeadingColor}` }}
          >
            Agent Network
          </Typography>
          <Paper
            sx={{
              overflow: "hidden",
              mt: 1,
              mb: 2,
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
              borderRadius: `${
                appData !== undefined && appData.cardRadius !== null
                  ? appData.cardRadius
                  : "6px"
              }`,
            }}
          >
            <Box p={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption">TOTAL AGENTS</Typography>
                <Typography component="div" pt={2} variant="h4">
                  {totalAgent}
                </Typography>
              </Box>
              <Box
                pt={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StandardStyledButton
                  variant="contained"
                  onClick={() => {
                    navigate("/portal/agent/list");
                  }}
                >
                  View List
                </StandardStyledButton>
                <Box p={1}></Box>
                <StandardStyledButton
                  onClick={() => {
                    navigate("/portal/agent/new");
                  }}
                >
                  New Agent
                </StandardStyledButton>
              </Box>
            </Box>
          </Paper>
          {/*Recent Transaction*/}
          <Typography
            variant="h6"
            sx={{ color: `${appData.mainHeadingColor}` }}
          >
            Recent Transactions
          </Typography>
          <Paper
            sx={{
              overflow: "hidden",
              mt: 1,
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
              borderRadius: `${
                appData !== undefined && appData.cardRadius !== null
                  ? appData.cardRadius
                  : "6px"
              }`,
            }}
          >
            <RecentTransactions
              {...props}
              isRecentTxnLoading={isRecentTxnLoading}
              list={recentTxns}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
