import _utlis from "../utils/netwk_utils";

const getBankList = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/bank/list"
        : "/api/send_money/bank/list";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const walletLogin = async (wallet, token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/wallet/search/" + wallet
        : "/api/send_money/wallet/search/" + wallet;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const getWalletDetails = async (wallet, token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/wallet/details/" + wallet
        : "/api/send_money/wallet/details/" + wallet;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const getWalletMpin = async (wallet, token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/wallet/mpin/" + wallet
        : "/api/send_money/wallet/mpin/" + wallet;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

//Create wallet
const create_wallet = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/wallet/create"
        : "/api/send_money/wallet/create";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Activate wallet by OTP
const activate_wallet = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/wallet/activate"
        : "/api/send_money/wallet/activate";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Resend wallet activation OTP
const resend_wallet_otp = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/wallet/resend"
        : "/api/send_money/wallet/resend";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Add new beneficiary
const add_new_beneficiary = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/beneficiary/new"
        : "/api/send_money/beneficiary/new";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Add new beneficiary
const getBankIfsc = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/bank/ifsc"
        : "/api/send_money/bank/ifsc";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Delete beneficiary
const delete_beneficiary = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/beneficiary/delete"
        : "/api/send_money/beneficiary/delete";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Verify beneficiary
const verify_beneficiary = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/beneficiary/validation"
        : "/api/send_money/beneficiary/validation";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Process Transaction
const process_transaction = async (data, token) => {
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/transaction"
        : "/api/send_money/transaction";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

//Transaction Status

const get_dmt_txn_status = async (tid, token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/transaction/status/" + tid
        : "/api/send_money/transaction/status/" + tid;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const resend_dmt_refund_otp = async (tid, token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/transaction/resend_otp/" + tid
        : "/api/send_money/transaction/resend_otp/" + tid;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const submit_refund_otp = async (tid, otp, otp_token, token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/send_money/transaction/submit/" + otp + "/" + tid
        : "/api/send_money/transaction/submit/" + otp + "/" + tid;

    const res = await _utlis._get(url, { token: token, otp_token: otp_token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const dmt_request = {
  walletLogin,
  getWalletDetails,
  getWalletMpin,
  create_wallet,
  activate_wallet,
  resend_wallet_otp,
  add_new_beneficiary,
  delete_beneficiary,
  verify_beneficiary,
  process_transaction,
  getBankList,
  getBankIfsc,
  get_dmt_txn_status,
  resend_dmt_refund_otp,
  submit_refund_otp,
};

export default dmt_request;
