import _utlis from "../utils/netwk_utils";

const recent_txn = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/report/transaction/recent"
        : "/api/report/transaction/recent";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const get_gst_invoices = async () => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/report/gst/invoices"
        : "/api/report/gst/invoices";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const get_transaction_status = async (tid) => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/report/transaction/status/" + tid
        : "/api/report/transaction/status/" + tid;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const get_transaction_history = async (tid) => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/report/transaction/history/" + tid
        : "/api/report/transaction/history/" + tid;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const filter_transaction_history = async (data) => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/report/transaction/history/filter"
        : "/api/report/transaction/history/filter";

    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const txn_statistics = async (token) => {
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/report/transaction/statistics"
        : "/api/report/transaction/statistics";

    const res = await _utlis._post(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const txn_daybook = async (data) => {
  const token = localStorage.getItem("token");
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/report/transaction/daybook"
        : "/api/report/transaction/daybook";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const report_requests = {
  recent_txn,
  txn_statistics,
  txn_daybook,
  get_transaction_history,
  filter_transaction_history,
  get_gst_invoices,
  get_transaction_status,
};

export default report_requests;
