import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { format } from "date-fns";
import { Collapse } from "@mui/material";
import StandardStyledTextfield from "./../../StandardCustomComponent/StandardStyledTextfield";
import dmt_request from "../../../../rest_api/dmt_request";
import report_requests from "../../../../rest_api/report_requests";
import StandardTransactionDetails from "./StandardTransactionDetails";
import { useNavigate } from "react-router-dom";
import StandardStyledButton from "./../../StandardCustomComponent/StandardStyledButton";
import { useReactToPrint } from "react-to-print";

export default function StandardHistoryTable(props) {
  const { transaction, isLoading, paginationHandler, txnType } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const columns = [
    "",
    "Date",
    "TID",
    "TXN TYPE",
    "UTR NO",
    "ACCOUNT NO",
    "MOBILE NO",
    "BANK/OPT.",
    "AMOUNT",
    "CHARGE",
    "COMM.",
    "BALANCE",
    "STATUS",
    "RECIPIENT",
  ];

  const handleChangePage = (event, newPage) => {
    const page_viewed = newPage * rowsPerPage;
    const pages = page_viewed + rowsPerPage;

    console.log(
      transaction.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );

    if (page < newPage && pages === transaction.length) {
      paginationHandler(txnType, false);
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return isLoading &&
    (transaction === null ||
      transaction === undefined ||
      transaction.length < 1) ? (
    <Box p={5} sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  ) : transaction === null ||
    transaction === undefined ||
    transaction.length < 1 ? (
    <Box p={2}>
      <Typography>No Transaction</Typography>
    </Box>
  ) : (
    <>
      <TableContainer>
        <Table aria-label="offer table">
          <TableHead>
            <TableRow>
              {columns.map((clm, index) => (
                <TableCell key={index + "" + clm}>
                  <Typography sx={{ fontSize: "11px", fontWeight: 500 }}>
                    {clm}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={13}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              transaction
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row key={"txn" + index} row={row} index={index} {...props} />
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {transaction && transaction.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={transaction.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}

function Row(props) {
  const { row, open_snackbar, snackbar_severity, snackbar_message } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [otp_token, setOtpToken] = React.useState("");

  const [rowData, setRowData] = React.useState(row);

  const navigate = useNavigate();
  useEffect(() => {
    setRowData(row);
  }, [row]);

  const resend_refund_otp = async (type, tid) => {
    if (type && tid && type !== "" && tid !== "") {
      setLoading(tid);
      const token = localStorage.getItem("token");

      const resData = await dmt_request.resend_dmt_refund_otp(tid, token);

      setLoading("");
      console.log(resData);
      setOtpToken(resData.otp_token ? resData.otp_token : "");
      snackbar_message(resData.message);
      snackbar_severity(resData.status === 1 ? "info" : "error");
      open_snackbar(true);
    } else {
      snackbar_severity("error");
      snackbar_message("Invalid TID or Transaction Type.");
      open_snackbar(true);
    }
  };

  const initiate_refund = async (tid) => {
    if (tid && tid !== "" && otp && otp !== "") {
      var row_data = rowData;

      setLoading(tid);
      const token = localStorage.getItem("token");

      const resData = await dmt_request.submit_refund_otp(
        tid,
        otp,
        otp_token,
        token
      );

      setLoading("");
      console.log(resData);
      row_data["status"] = resData.status === 1 ? "3" : row_data.status;
      setRowData(row_data);
      snackbar_message(resData.message);
      snackbar_severity(resData.status === 1 ? "info" : "error");
      open_snackbar(true);
    } else {
      snackbar_severity("error");
      snackbar_message("Invalid TID or Transaction Type.");
      open_snackbar(true);
    }
  };

  const get_txn_status = async (type, tid) => {
    if (type && tid && type !== "" && tid !== "") {
      var row_data = rowData;
      var resData = [];
      setLoading(tid);
      const token = localStorage.getItem("token");

      if (type === "DMT") {
        resData = await dmt_request.get_dmt_txn_status(tid, token);
      } else {
        resData = await report_requests.get_transaction_status(tid, token);
      }
      setLoading("");
      console.log(resData);
      row_data["status"] =
        resData.status === 1 && resData.transaction
          ? "" + resData.transaction.status
          : row_data.status;
      setRowData(row_data);
      snackbar_message(
        resData.transaction ? resData.transaction.remark : resData.message
      );
      snackbar_severity(resData.status === 1 ? "info" : "error");
      open_snackbar(true);
    } else {
      snackbar_severity("error");
      snackbar_message("Invalid TID or Transaction Type.");
      open_snackbar(true);
    }
  };

  //Print function
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }} align="right">
          {rowData.createdAt
            ? format(new Date(rowData.createdAt), "dd-MM-yyy hh:mm:ss a")
            : "-"}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }} component="th" scope="row">
          #{rowData.id}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>{rowData.type}</TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {rowData.type !== "Recharge" &&
          rowData.type !== "BBPS" &&
          rowData.type !== "FAStag" &&
          rowData.utr_no
            ? rowData.utr_no
            : rowData.operatorId
            ? rowData.operatorId
            : "-"}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {rowData.account ? rowData.account : "-"}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {rowData.number ? rowData.number : "-"}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {rowData.operator ? rowData.operator : "-"}
        </TableCell>
        <TableCell sx={{ fontSize: "12px", minWidth: "120px" }}>
          <Typography
            component={"span"}
            sx={{ fontWeight: 500, fontSize: "12px" }}
          >
            {rowData.credit === null || rowData.credit === "" ? "- " : "+ "}
          </Typography>
          {rowData.amount ? "₹" + parseFloat(rowData.amount).toFixed(2) : "0"}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {rowData.charge ? "₹" + parseFloat(rowData.charge).toFixed(2) : "0"}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {rowData.commission
            ? "₹" + parseFloat(rowData.commission).toFixed(2)
            : "0"}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {"₹" + parseFloat("" + rowData.balance).toFixed(2)}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {rowData.status === "1" ? (
            <Chip
              color="primary"
              sx={{ fontSize: "12px" }}
              label={"Success"}
              size={"small"}
            />
          ) : rowData.status === "0" ? (
            <Chip
              color="warning"
              sx={{ fontSize: "12px" }}
              label={"Pending"}
              size={"small"}
            />
          ) : rowData.status === "3" &&
            rowData.credit &&
            rowData.credit !== "" &&
            rowData.credit !== 0 &&
            rowData.credit !== "0" ? (
            <Chip
              color="info"
              sx={{ fontSize: "12px" }}
              label={"Refund Received"}
              size={"small"}
            />
          ) : rowData.status === "3" &&
            rowData.debit &&
            rowData.debit !== "" &&
            rowData.debit !== 0 &&
            rowData.debit !== "0" ? (
            <Chip
              color="secondary"
              sx={{ fontSize: "12px" }}
              label={"Refunded"}
              size={"small"}
            />
          ) : rowData.status === "4" ? (
            <Chip
              color="success"
              sx={{ fontSize: "12px" }}
              label={"Hold"}
              size={"small"}
            />
          ) : rowData.status === "5" ? (
            <Chip
              color="info"
              sx={{ fontSize: "12px" }}
              label={"Initiated"}
              size={"small"}
            />
          ) : rowData.status === "6" ? (
            <Chip
              color="primary"
              sx={{ fontSize: "12px" }}
              label={"Refund Pending"}
              size={"small"}
            />
          ) : (
            <Chip
              color="error"
              sx={{ fontSize: "12px" }}
              label={"Failure"}
              size={"small"}
            />
          )}
        </TableCell>
        <TableCell sx={{ fontSize: "12px" }}>
          {rowData.account_holder_name &&
          rowData.account_holder_name !== "UNKNOWN" &&
          rowData.account_holder_name !== "Unknown"
            ? rowData.account_holder_name
            : "-"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={14}>
          <Collapse
            in={open}
            sx={{ bgcolor: "#f9f9f9" }}
            timeout="auto"
            unmountOnExit
          >
            <StandardTransactionDetails ref={componentRef} keysid={212131} KeyValue={KeyValue} payload={rowData} />

            {parseInt(rowData.status) === 6 && (
              <Box
                p={1}
                sx={{
                  py: "4px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <StandardStyledTextfield
                  variant="outlined"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                {loading === rowData.id ? (
                  <CircularProgress />
                ) : (
                  <>
                    <StandardStyledButton
                      variant="contained"
                      sx={{ mx: 1 }}
                      color="info"
                      onClick={() => {
                        initiate_refund(rowData.id);
                      }}
                    >
                      Submit
                    </StandardStyledButton>{" "}
                    <StandardStyledButton
                      variant="text"
                      color="warning"
                      onClick={() => {
                        resend_refund_otp(rowData.type, rowData.id);
                      }}
                    >
                      Resend OTP
                    </StandardStyledButton>
                  </>
                )}
              </Box>
            )}

            {parseInt(rowData.status) !== 6 && (
              <Box
                px={1}
                sx={{
                  py: "4px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <StandardStyledButton
                  variant="text"
                  color="warning"
                  onClick={() => {
                    navigate("/portal/help", {
                      state: {
                        type: rowData.type,
                        tid: rowData.id,
                      },
                    });
                  }}
                >
                  Raise Dispute
                </StandardStyledButton>
                <Box sx={{ display: "flex" }}>
                  {loading === rowData.id ? (
                    <CircularProgress />
                  ) : (
                    <StandardStyledButton
                      sx={{ mr: 2 }}
                      variant="text"
                      color="info"
                      onClick={() => {
                        get_txn_status(rowData.type, rowData.id);
                      }}
                    >
                      Check Status
                    </StandardStyledButton>
                  )}
                  <StandardStyledButton
                    variant="text"
                    color="success"
                    onClick={handlePrint}
                  >
                    Print
                  </StandardStyledButton>
                </Box>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function KeyValue(props) {
  const { keyName, value } = props;

  return (
    <Box
      px={1}
      sx={{
        py: "4px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          textAlign: "right",
          width: "calc(50% - 9px)",
          fontWeight: 500,
          fontSize: "16px",
        }}
        component="div"
      >
        {keyName}
      </Box>
      <Box px={2}>:</Box>
      <Box sx={{ width: "calc(50% - 9px)", fontSize: "16px" }} component="div">
        {value ? value : "-"}
      </Box>
    </Box>
  );
}
