import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import StandardStyledTextfield from "../../StandardCustomComponent/StandardStyledTextfield";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";

export default function StandardDirectUPIPayout(props) {
  const {
    setView,
    firstTab,
    setBeneficiary,
    open_snackbar,
    snackbar_severity,
    snackbar_message,
  } = props;

  const [name, setName] = React.useState("");
  const [upi_id, setUPI_id] = React.useState("");
  const [error, setError] = React.useState([]);

  const [isloading, setLoading] = React.useState(false);

  const directTransferHandler = async () => {
    try {
      if (name && name.length > 2 && upi_id && upi_id.length > 5) {
        setError([]);
        setLoading(true);
        const data = {
          accountType: "UPI",
          bank_name: "",
          upi_id: upi_id,
          account_name: name,
          account_number: "",
          id: "0",
          ifsc: "",
          service_type: "UPI",
          createdAt: new Date(),
          status: "1",
        };
        setLoading(false);
        firstTab.current.click();
        setView("BeneConfirm");
        setBeneficiary(data);
      } else {
        var err = [];
        if (name === null || name === undefined || name.length < 3) {
          err.push("name");
        }
        if (upi_id === null || upi_id === undefined || upi_id.length <= 5) {
          err.push("upi");
        }
        setError(err);
      }
    } catch (e) {
      snackbar_severity("error");
      snackbar_message(e.message);
      open_snackbar(true);
    }
  };

  return (
    <Box p={3}>
      <Typography sx={{ fontWeight: 500 }}> UPI Direct Transfer</Typography>
      <Box px={1} pt={1} pb={2}>
        <Typography variant="caption" sx={{ fontWeight: 500 }}>
          Note:-
        </Typography>
        <br />
        <Typography variant="caption">
          {" "}
          1. Verify your UPI Id carefully. Wrong transfer can not be
          refund/change/modify.
        </Typography>
        <br />
        <Typography variant="caption">
          2. Standard tranfser charges will be applicable for UPI Direct
          transfer. Please check your offer list for transaction charges.
        </Typography>
      </Box>
      <Box mt={2}>
        <Box pb={1}>
          <StandardStyledTextfield
            error={error.indexOf("name") >= 0}
            disabled={isloading}
            fullWidth
            label={"Beneficiary Name"}
            value={name}
            onChange={(e) =>
              e.target.value.length <= 30 && setName(e.target.value)
            }
          />
        </Box>
        <Box py={1}>
          <StandardStyledTextfield
            fullWidth
            label={"UPI Id"}
            error={error.indexOf("upi") >= 0}
            disabled={isloading}
            value={upi_id}
            onChange={(e) =>
              e.target.value.length <= 30 && setUPI_id(e.target.value)
            }
          />
        </Box>

        <Box py={1}>
          {isloading ? (
            <CircularProgress />
          ) : (
            <StandardStyledButton
              p={"15px 16px"}
              disabled={isloading}
              customcolor={props.appData.buttonColor}
              customfontcolor={props.appData.buttonFontColor}
              variant={"contained"}
              onClick={directTransferHandler}
            >
              Confirm & Pay
            </StandardStyledButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}
