import CheckOutlined from "@mui/icons-material/CheckOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";

export default function StandardReceiptPage(props) {
  const { appData } = props;
  const [status, setStatus] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  process.env.REACT_APP_NODE_ENV === "development" && console.log(location.state);

  React.useEffect(() => {
    const statusData = location.state
      ? location.state.data.filter((d) => d.label === "Status")
      : null;
    
    setStatus(statusData && statusData[0].value);
  }, [location.state]);

  return !location.state ? (
    <Box>
      <Paper
        sx={{
          p: 2,
          overflow: "hidden",
          boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
          borderRadius: `${
            appData !== undefined && appData.cardRadius !== null
              ? appData.cardRadius
              : "6px"
          }`,
        }}
      >
        No data available for this receipt
      </Paper>
    </Box>
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
          Receipt
        </Typography>
      </Box>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box
            p={2}
            pt={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{location.state.type}</Typography>
          </Box>
          <Box
            p={2}
            pb={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {status === 1 ? (
              <Avatar
                sx={{ width: "60px", height: "60px", backgroundColor: "blue" }}
              >
                <CheckOutlined />
              </Avatar>
            ) : status === 2 ? (
              <Avatar
                sx={{ width: "60px", height: "60px", backgroundColor: "red" }}
              >
                <CloseOutlined />
              </Avatar>
            ) : (
              <Avatar
                sx={{
                  width: "60px",
                  height: "60px",
                  backgroundColor: "#cddc39",
                }}
              >
                <InfoOutlined />
              </Avatar>
            )}
          </Box>
          <Box pb={2} sx={{ textAlign: "center", fontSize: '18px', fontWeight: 500 }}>
            {status === 1
              ? "Successful"
              : status === 0
              ? "Pending"
              : status === 2
              ? "Failure"
              : "Unknown"}
          </Box>
          {location.state.data.map((d) => {
            return (
              d.label !== "Status" && (
                <Box
                  key={d.label}
                  sx={{
                    px: 1,
                    py: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "calc(50% - 16px)", textAlign: "right" }}>
                    {d.label}
                  </Box>
                  <Box px={"16px"}>:</Box>
                  <Box sx={{ width: "calc(50% - 16px)" }}>{d.value}</Box>
                </Box>
              )
            );
          })}
          <Box
            pt={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StandardStyledButton variant="contained" onClick={() => {navigate("/portal/dashboard")}}>
              Done
            </StandardStyledButton>
            <Box pt={2}>
              Need Help?{" "}
              <StandardStyledButton
                variant="text"
                onClick={() => {
                  navigate("/portal/help", {
                    state: {
                      type: location.state.type,
                      tid: location.state.tid,
                    },
                  });
                }}
              >
                Raise Dispute
              </StandardStyledButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
