import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import StandardTabPanel from "../../../AddMoney/StandardTabPanel";
import StandardMobilePostpaid from "./Operaters/StandardMobilePostpaid";
import StandardOperatorPage from "./Operaters/StandardOperatorPage";

const handlerTabs = (index) => {
  return {
    id: `standard-tab-${index}`,
    "aria-controls": `standard-tabpanel-${index}`,
  };
};

export default function StandardBBPSPage(props) {
  const { appData } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        BBPS
      </Typography>
      <Box mt={1} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Paper sx={{ display: "grid" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="add money tabs"
            >
              <Tab label="Broadband" {...handlerTabs(0)} />
              <Tab label="Mobile Postpaid" {...handlerTabs(1)} />
              <Tab label="Electricity" {...handlerTabs(2)} />
              <Tab label="Water" {...handlerTabs(3)} />
              <Tab label="Gas" {...handlerTabs(4)} />
              <Tab label="Tax" {...handlerTabs(5)} />
              <Tab label="LPG Cylinder" {...handlerTabs(6)} />
              <Tab label="Landline" {...handlerTabs(7)} />
              <Tab label="Insurance" {...handlerTabs(8)} />
              <Tab label="Loan Repayment" {...handlerTabs(9)} />
              <Tab label="Subscriptions" {...handlerTabs(10)} />
              <Tab label="Cable TV" {...handlerTabs(11)} />
              <Tab label="Education Fee" {...handlerTabs(12)} />
              <Tab label="Municipal Taxes" {...handlerTabs(13)} />
              <Tab label="Hospital" {...handlerTabs(14)} />
              <Tab label="Housing Society" {...handlerTabs(15)} />
              <Tab label="Municipal Services" {...handlerTabs(16)} />
              <Tab label="Rental Payment" {...handlerTabs(17)} />
            </Tabs>
          </Paper>
          <Box>
            <StandardTabPanel value={value} index={0}>
              <StandardOperatorPage title={"Broadband"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={1}>
              <StandardMobilePostpaid {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={2}>
              <StandardOperatorPage title={"Electricity"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={3}>
              <StandardOperatorPage title={"Water"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={4}>
              <StandardOperatorPage title={"Gas"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={5}>
              <StandardOperatorPage title={"Tax"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={6}>
              <StandardOperatorPage title={"LPG Cylinder"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={7}>
              <StandardOperatorPage title={"Landline"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={8}>
              <StandardOperatorPage title={"Insurance"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={9}>
              <StandardOperatorPage title={"Loan Repayment"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={10}>
              <StandardOperatorPage title={"Subscriptions"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={11}>
              <StandardOperatorPage title={"Cable TV"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={12}>
              <StandardOperatorPage title={"Education Fee"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={13}>
              <StandardOperatorPage title={"Municipal Taxes"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={14}>
              <StandardOperatorPage title={"Hospital"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={15}>
              <StandardOperatorPage title={"Housing Society"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={16}>
              <StandardOperatorPage title={"Municipal Services"} {...props} />
            </StandardTabPanel>
            <StandardTabPanel value={value} index={17}>
              <StandardOperatorPage title={"Rental Payment"} {...props} />
            </StandardTabPanel>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
