import { Paper, Box, Typography, Avatar } from '@mui/material'
import React from 'react'

export default function StandardDasboardCustomCard(props) {
    const { appData, backgroundColor, Icon, title, subtitle } = props;
    return (
        <Paper elevation={0} sx={{ overflow: 'hidden', backgroundColor: `${backgroundColor !== null ? backgroundColor : 'white'}`, mx: { xs: 0 }, my: { xs: 2, sm: '20px' }, borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.10)', minWidth: { xs: '100%', sm: '160px' }, width: { xs: '100%', sm: 'calc(50% - 40px)', md: 'calc(25% - 40px)' } }}>
            <Box sx={{ width: '30%', height: '120px', borderRadius: '20px', transform: 'rotate(45deg);', ml: '-30px', backgroundColor: '#ffffff20', boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.1)' }}></Box>
            <Box p={2} sx={{ position: 'absolute', mt: '-120px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: '#00000020' }}><Icon sx={{ color: `${appData !== undefined && appData.dashboardCardFontColor != null ? appData.dashboardCardFontColor : '#ffffff'}` }} /></Avatar>
                    <Box ml={2}>
                        <Typography variant="caption" sx={{ color: `${appData !== undefined && appData.dashboardCardFontColor != null ? appData.dashboardCardFontColor : '#ffffff'}` }}>{title}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ mt: 1, color: `${appData !== undefined && appData.dashboardCardFontColor != null ? appData.dashboardCardFontColor : '#ffffff'}`, fontWeight: '500', fontSize: { xs: '28px', sm: '24px', md: '24px', xl: '32px' } }}>{title !== 'New Agent' ? '₹'+subtitle : subtitle}</Typography>
                </Box>
            </Box>
        </Paper>
    )
}
