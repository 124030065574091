import { Box, FormControl, MenuItem, OutlinedInput, Paper, Select, Typography } from '@mui/material'
import React from 'react'
import StandardStyledButton from '../../../../StandardCustomComponent/StandardStyledButton';
import StandardStyledTextfield from '../../../../StandardCustomComponent/StandardStyledTextfield';
import { useNavigate } from 'react-router-dom';

export default function StandardDthRecharge(props) {
    const { appData } = props;

    const [dthNumber, setDthNumber] = React.useState("")
    const [amount, setAmount] = React.useState("")
    const [operator, setOperator] = React.useState(" ")
    const [circle, setCircle] = React.useState(" ")
    const optList = [' ', 'Airtel Digital TV', 'Dish TV', 'Sun Direct', 'Tata Sky', 'Videocon D2H'];
    const circleList = [
        " ",
        "Andhra Pradesh",
        "Assam",
        "Bihar Jharkhand",
        "Delhi NCR",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu Kashmir",
        "Karnataka",
        "Kerala",
        "Kolkata",
        "Madhya Pradesh",
        "Chhattisgarh",
        "Maharashtra",
        "Mumbai",
        "North East",
        "Orissa",
        "Punjab",
        "Rajasthan",
        "Tamil Nadu",
        "UP East",
        "UP West",
        "West Bengal",
    ];;
    const [error, setError] = React.useState([])

    const navigate = useNavigate();

    const SubmitRechargeForm = () => {

        if (dthNumber.length > 5 && circle.length > 2 && operator.length > 1 && amount !== "" && parseInt(amount) > 9) {
            navigate('/portal/recharge/confirm', { state: { number: dthNumber, operator: operator, circle: circle, serviceType: 'DTH', amount: amount } });
            setError([]);
        } else {

            var newError = [];

            if (dthNumber.length < 5) {
                newError.push('dthNumber');
            }

            if (amount === "" || parseInt(amount) < 10) {
                newError.push('amount');
            }

            if (circle.length < 2) {
                newError.push('circle');
            }

            if (operator.length < 2) {
                newError.push('operator');
            }

            setError(newError);

        }

    }



    return (

        <Box >
            <Typography variant='h6' sx={{ color: `${appData.mainHeadingColor}` }}>DTH Recharge</Typography>
            <Box mt={1} sx={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }}>
                <Paper sx={{ flexGrow: 1, width: '100%', maxWidth: { xs: '100%', md: '48%' }, overflow: 'hidden', mt: 1, boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)', borderRadius: `${appData !== undefined && appData.cardRadius !== null ? appData.cardRadius : '6px'}`, }}>
                    <Box p={3}>
                        <Box component={'form'} onSubmit={SubmitRechargeForm}>
                            <StandardStyledTextfield type="number" error={error.indexOf('dthNumber') >= 0} value={dthNumber} onChange={(e) => { if (e.target.value.length <= 10) { setDthNumber(e.target.value); } }} fullWidth placeholder='Subscriber Number' />
                            <Box py={1}></Box>
                            <FormControl fullWidth >
                                <Select
                                    id="standard-payment-mode-select"
                                    value={operator}
                                    error={error.indexOf('operator') >= 0}
                                    input={<OutlinedInput sx={{
                                        '& fieldset': {
                                            borderRadius: '16px',
                                            borderColor: error.indexOf('operator') >= 0 ? 'error' : '#e9e9e9 !important',
                                            borderWidth: '1px'
                                        }
                                    }} />}
                                    onChange={(e) => setOperator(e.target.value)}
                                >
                                    {optList.map((opt) => opt === " " ? <MenuItem key={"selectOtp"} value={" "}>Select Operator</MenuItem> : <MenuItem key={opt} value={opt}>{opt}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <Box py={1}></Box>
                            <FormControl fullWidth >
                                <Select
                                    id="standard-payment-mode-select"
                                    value={circle}
                                    error={error.indexOf('circle') >= 0}
                                    input={<OutlinedInput sx={{
                                        '& fieldset': {
                                            borderRadius: '16px',
                                            borderColor: error.indexOf('circle') >= 0 ? 'error' : '#e9e9e9 !important',
                                            borderWidth: '1px'
                                        }
                                    }} />}
                                    onChange={(e) => setCircle(e.target.value)}
                                >
                                    {circleList.map((circle) => circle === " " ?
                                        <MenuItem key="circle" value={" "}>Select Circle</MenuItem> :
                                        <MenuItem key={circle} value={circle}>{circle}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <Box py={1}></Box>
                            <StandardStyledTextfield type="number" error={error.indexOf('amount') >= 0} value={amount} onChange={(e) => { if (e.target.value.length <= 5) { setAmount(e.target.value); } }} fullWidth placeholder='Amount' />
                            <Box py={1}></Box>
                            <StandardStyledButton variant='contained' customcolor={props.appData.buttonColor} customfontcolor={props.appData.buttonFontColor} p={"15px 16px"} onClick={SubmitRechargeForm}>Proceed</StandardStyledButton>
                        </Box>
                    </Box>
                </Paper>

            </Box>
        </Box>

    )
}
