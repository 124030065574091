import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React from "react";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";
import bbps_request from "../../../../../../rest_api/bbps_request";
import { useNavigate } from "react-router-dom";

export default function StandardGooglePlayRechargePage(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setlongitude] = React.useState("");

  navigator.geolocation.getCurrentPosition(function (position) {
    setLatitude(position.coords.latitude);
    setlongitude(position.coords.longitude);
    console.log("Longitude is :", position.coords.longitude);
  });

  const navigate = useNavigate();

  const [number, setNumber] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [pin, setPIN] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const purchaseGooglePlayCode = async () => {
    if (
      number &&
      number.length === 10 &&
      amount &&
      amount.length > 1 &&
      pin &&
      pin.length === 4
    ) {
      try {
        setLoading(true);
        const data = {
          category: "Digital Voucher",
          operatorId: "446",
          operator: "Google Play Recharge",
          billNumber: number,
          optNumber: "",
          opt2Number: "",
          opt3Number: "",
          ad1name: "",
          ad2name: "",
          ad3name: "",
          latitude: latitude,
          longitude: longitude,
          amount: amount,
          txn_pin: pin,
        };

        const res = await bbps_request.transaction(data);
        setLoading(false);
        console.log(res);
        if (res && !res.isLoggedIn) {
          open_snackbar(true);
          snackbar_severity("error");
          snackbar_message(res.message);
          localStorage.clear();
          navigate("/login");
        } else if (res && res.status && res.txn_status) {
          navigate("/portal/bbps/bill/receipt", {
            state: {
              res: res,
              billdetails: data,
              category: "Digital Voucher",
              operator: "Google Play Recharge",
            },
            replace: true,
          });
        } else {
          open_snackbar(true);
          snackbar_severity("error");
          snackbar_message(res.message);
        }
      } catch (e) {
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message(e.message);
      }
    } else {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message("Please fill the form correctly");
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Google Play Recharge
      </Typography>
      <Box mt={1} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box
            px={3}
            pt={1}
            pb={2}
            sx={{
              display: "flex",
              maxWidth: "600px",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box width={"100%"}>
              <Typography
                variant="caption"
                sx={{ fontWeight: "600" }}
                component={"div"}
                px={1}
                pt={1}
              >
                Customer Mobile Number
              </Typography>
              <StandardStyledTextfield
                type={"number"}
                value={number}
                placeholder="Mobile Number"
                fullWidth
                onChange={(e) =>
                  e.target.value.length <= 10 && setNumber(e.target.value)
                }
              />
            </Box>

            <Box width={"100%"} mt={1}>
              <Typography
                variant="caption"
                sx={{ fontWeight: "600" }}
                component={"div"}
                px={1}
                pt={1}
              >
                Enter Amount
              </Typography>
              <StandardStyledTextfield
                type="number"
                placeholder="Amount"
                fullWidth
                value={amount}
                onChange={(e) =>
                  e.target.value.length < 6 && setAmount(e.target.value)
                }
              />
            </Box>

            <Box width={"100%"} mt={1}>
              <Typography
                variant="caption"
                sx={{ fontWeight: "600" }}
                component={"div"}
                px={1}
                pt={1}
              >
                Enter Your Transaction PIN
              </Typography>
              <StandardStyledTextfield
                type="password"
                placeholder="Transaction PIN"
                fullWidth
                value={pin}
                onChange={(e) =>
                  e.target.value.length < 5 && setPIN(e.target.value)
                }
              />
            </Box>

            <Box sx={{ mt: 1 }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <StandardStyledButton
                  variant="contained"
                  disabled={
                    pin === "" ||
                    pin.length !== 4 ||
                    amount === "" ||
                    number === "" ||
                    number.length !== 10
                  }
                  customcolor={props.appData.buttonColor}
                  customfontcolor={props.appData.buttonFontColor}
                  p={"15px 16px"}
                  onClick={() => purchaseGooglePlayCode()}
                >
                  Pay & Generate Recharge Code
                </StandardStyledButton>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
