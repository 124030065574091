import {
  Avatar,
  ListItemAvatar,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
} from "@mui/material";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box } from "@mui/system";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import PriorityHighOutlined from "@mui/icons-material/PriorityHighOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import TollIcon from "../../../../global_component/TollIcon";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function RecentTransactions(props) {
  const { list, isRecentTxnLoading } = props;

  return (
    <List sx={{ width: "100%" }}>
      {isRecentTxnLoading ? (
        <Box p={2} sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : list.length < 1 ? (
        <Box p={4}>
          <Typography align="center">
            No Recent Transaction Available
          </Typography>
        </Box>
      ) : (
        list.map(
          (txn, index) =>
            index < 6 && (
              <ListItem
                divider={
                  list.length > index + 1 && 6 > index + 1 ? true : false
                }
                key={`recentTxn${txn.id}${index}`}
                secondaryAction={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography ml={3} sx={{ fontSize: "14px" }}>
                      ₹{txn.amount}
                    </Typography>
                    <Avatar
                      sx={{
                        boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.2)",
                        width: 16,
                        height: 16,
                        ml: "3px",
                        backgroundColor:
                          txn.status === "1"
                            ? "#4a6ecc"
                            : txn.status === "0"
                            ? "#ee8031"
                            : "#b14e07",
                      }}
                    >
                      {txn.status === "1" ? (
                        <CheckOutlined sx={{ fontSize: "12px" }} />
                      ) : txn.status === "0" ? (
                        <PriorityHighOutlined sx={{ fontSize: "12px" }} />
                      ) : (
                        <CloseOutlinedIcon sx={{ fontSize: "12px" }} />
                      )}
                    </Avatar>
                  </Box>
                }
              >
                <ListItemAvatar>
                  {txn.operator === "Google Play Recharge" ? (
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        border: "1px solid #efefef",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <FontAwesomeIcon color="#000000" icon={faGooglePlay} />
                    </Avatar>
                  ) : txn.type === "Micro ATM" || txn.type === "MPOS" ? (
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        border: "1px solid #efefef",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={solid("calculator")}
                        color="#3f51b5"
                      />
                    </Avatar>
                  ) : txn.type === "FAStag" ? (
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        border: "1px solid #efefef",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <TollIcon
                        sx={{
                          color: "#3f51b5",
                        }}
                      />
                    </Avatar>
                  ) : txn.type === "AEPS" ? (
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        border: "1px solid #efefef",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <FingerprintOutlinedIcon
                        sx={{
                          color: "#3f51b5",
                        }}
                      />
                    </Avatar>
                  ) : (
                    <Avatar
                      sx={{
                        width: 36,
                        height: 36,
                        border:
                          txn.service_type === "BBPS"
                            ? "1px solid #111111"
                            : "1px solid #efefef",
                        filter:
                          txn.service_type === "BBPS" ? "invert(1)" : "none",
                        bgcolor:
                          txn.service_type === "BBPS" ? "#000000" : "#ffffff",
                      }}
                      src={
                        txn.operator === "VI"
                          ? "/opt_logo/vi.png"
                          : txn.operator === "Airtel"
                          ? "/opt_logo/airtel.png"
                          : txn.operator === "Jio"
                          ? "/opt_logo/jio.png"
                          : txn.operator === "BSNL"
                          ? "/opt_logo/bsnl.png"
                          : txn.operator === "Airtel Digital TV"
                          ? "/opt_logo/airteldigitaltv.png"
                          : txn.operator === "Dish TV"
                          ? "/opt_logo/dishTv.png"
                          : txn.operator === "Sun Direct"
                          ? "/opt_logo/sundirect.png"
                          : txn.operator === "Tata Sky"
                          ? "/opt_logo/tatasky.png"
                          : txn.operator === "Videocon D2H"
                          ? "/opt_logo/D2h.png"
                          : txn.service_type === "UPI"
                          ? "/opt_logo/bhim-upi.png"
                          : txn.service_type === "IMPS"
                          ? "/opt_logo/imps.png"
                          : txn.service_type === "NEFT"
                          ? "/opt_logo/neft.png"
                          : txn.type === "TopUp"
                          ? "/opt_logo/addfund.png"
                          : txn.type === "Fund Transfer"
                          ? "/opt_logo/sendmoney.png"
                          : txn.service_type === "BBPS"
                          ? "/bbps.svg"
                          : ""
                      }
                      alt={txn.operator}
                    ></Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  sx={{ "& .MuiListItemText-primary": { fontSize: "14px" } }}
                  primary={
                    txn.type === "DMT" && txn.service_type === "BBPS"
                      ? txn.operator
                      : txn.type === "Micro ATM" ||
                        txn.type === "MPOS" ||
                        txn.type === "Cash@Pos"
                      ? txn.service_type
                      : txn.type
                  }
                  secondary={
                    <Typography variant="caption">
                      {txn.type !== "Recharge" &&
                      txn.type !== "Digital Voucher" &&
                      txn.type !== "FAStag" &&
                      txn.type !== "Micro ATM" &&
                      txn.type !== "MPOS" &&
                      txn.type !== "Cash@Pos" &&
                      txn.type !== "AEPS" &&
                      txn.type !== "Payout" &&
                      txn.type !== "Verification" &&
                      txn.type !== "TopUp" &&
                      txn.account
                        ? `${txn.number} - ${txn.account}`
                        : txn.type !== "Digital Voucher"
                        ? txn.account
                        : txn.number}
                    </Typography>
                  }
                />
              </ListItem>
            )
        )
      )}
    </List>
  );
}
