import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import report_requests from "../../../../rest_api/report_requests";
import StandardStyledButton from "../../StandardCustomComponent/StandardStyledButton";

export default function StandardGstInvoicePage(props) {
  const { appData } = props;

  const navigate = useNavigate();
  const [isloading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [invoices, setInvoices] = React.useState();

  React.useEffect(() => {
    async function getInvoices() {
      try {
        setLoading(true);
        const res = await report_requests.get_gst_invoices();
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              setInvoices(res.invoices);
              setError("");
            } else {
              setError(res.message);
            }
          } else {
            setError(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError("No response key");
        }
      } catch (e) {
        setError(e.message);
      }
    }
    getInvoices();
  }, [navigate]);

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Download GST Invoice
      </Typography>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Box>
            {isloading ? (
              <Box p={2}>
                <CircularProgress />
              </Box>
            ) : error && error.length > 0 ? (
              <Box p={2}>{error}</Box>
            ) : (
              <Box>
                <Box p={2}>
                  <Typography variant={"h6"}>Invoices</Typography>
                </Box>
                <Divider />
                {invoices && invoices.length > 0 ? (
                  <InvoiceList invoices={invoices} />
                ) : (
                  <Box p={2}>No invoice available</Box>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

function InvoiceList(props) {
  const { invoices } = props;

  return (
    <List>
      {invoices.map((inv) => (
        <ListItem
          divider
          secondaryAction={
            <StandardStyledButton variant="text" size={"small"} onClick={() => {
                window.open(inv.invoice_url, '_blank');
            }}>Download</StandardStyledButton>
          }
        >
          <ListItemText primary={`${inv.month}, ${inv.year}`} secondary={"Invoice No.: #"+inv.invoice_no} />
        </ListItem>
      ))}
    </List>
  );
}
