import { AppBar, Toolbar, Box, IconButton, Link, Paper, Typography } from '@mui/material'
import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

export default function PageNotFound(props) {
    const { appData } = props;
    const navigate = useNavigate();
    return (
        <Box sx={{ backgroundColor: { xs: '#ffffff', sm: '#f0f0f0' } }}>
            <AppBar sx={{ boxShadow: '0px 0px 6px 0px rgb(0 0 0 / 20%)', backgroundColor: appData.appBarColor != null ? appData.appBarColor : 'primary', color: appData.appBarFontColor != null ? appData.appBarFontColor : 'secondary' }} component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => { navigate(-1); }}
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: "center" }}>
                        <Link href="/" underline="none" color={appData.appBarFontColor != null ? appData.appBarFontColor : 'inherit'}>
                            <img height={"24px"} src={appData.logo} alt={appData.title} />
                        </Link>
                    </Box>
                    <Box ml={1} py={1} px={3}></Box>
                </Toolbar>
            </AppBar>

            <Box p={3} sx={{ display: 'flex', height: 'calc(100vh - 48px)', justifyContent: 'center', alignItems: 'center' }}>
                <Paper elevation={0} sx={{ boxShadow: { xs: 'none', sm: '0px 0px 6px 0px rgb(0 0 0 / 10%)' }, maxWidth: '360px', width: '100%', display: 'block', borderRadius: '20px' }}>
                    <Box p={3} pb={4}>
                        <Box p={2} align="center">
                            <ErrorIcon sx={{ fontSize: 48 }} color="warning"/>
                        </Box>
                        <Typography py={2} variant='h6' align='center'>
                            Error 404 - Page Not found
                        </Typography>

                        <Typography pb={2} variant='body1' align='center'>
                            The page or content you are looking for is not found or removed.
                        </Typography>
                      
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}
