import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import bbps_request from "../../../../../../../rest_api/bbps_request";
import StandardStyledButton from "../../../../../StandardCustomComponent/StandardStyledButton";
import StandardStyledTextfield from "../../../../../StandardCustomComponent/StandardStyledTextfield";

export default function StandardOperatorPage(props) {
  const { title, open_snackbar, snackbar_severity, snackbar_message } = props;
  const [error, setError] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [number, setNumber] = useState("");
  const [number2, setNumber2] = useState("");
  const [number3, setNumber3] = useState("");
  const [number4, setNumber4] = useState("");
  const [formError, setFormError] = useState([]);
  const [param, setParam] = useState([]);
  const [isloading, setLoading] = useState(true);
  const [fetching, setfetching] = useState(false);
  const [operator, setOperator] = useState(" ");
  const [list, setList] = useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setlongitude(position.coords.longitude);
      console.log("Longitude is :", position.coords.longitude);
    });

    async function get_operator() {
      try {
        setLoading(true);
        setOperator(" ");
        setError("");
        setList([]);
        const res = await bbps_request.get_operators(title);

        setLoading(false);
        if (res && !res.isLoggedIn) {
          setError(res.message);
          localStorage.clear();
          navigate("/login");
        } else if (res && res.status === 1) {
          setOperator(" ");
          setList(res.list);
          setError("");
        } else {
          setOperator(" ");
          setList([]);
          setError(res.message);
        }
      } catch (e) {
        setOperator(" ");
        setList([]);
        setLoading(false);
        setError(e.message);
      }
    }

    get_operator();
  }, [navigate, title]);

  React.useEffect(() => {
    async function get_operator_param() {
      try {
        setLoading(true);
        setParam([]);
        setError("");
        const opt = list.filter((opt) => opt["name"] === operator)[0];
        const res = await bbps_request.get_operator_param(title, opt["id"]);
        setLoading(false);
        if (res && res.status === 1) {
          setParam(res.param);
          setError("");
        } else {
          setOperator(" ");
          setParam([]);
          setError(res.message);
        }
      } catch (e) {
        setOperator(" ");
        setLoading(false);
        setParam([]);
        setError(e.message);
      }
    }

    const opt = list.filter((opt) => opt["name"] === operator)[0];

    if (opt && opt["displayname"] && opt["displayname"] !== "null") {
      console.log(opt);
      const displayName =
        opt && opt["displayname"] && opt["displayname"] !== "null"
          ? opt["displayname"]
          : "Customer Number";

      var paramData = {
        error_message: `Please enter a valid ${displayName}`,
        param_id: "1",
        param_label: displayName,
        param_name: "canumber",
      };

      var paramData1 =
        opt && opt["ad1_d_name"] && opt["ad1_d_name"] !== "null"
          ? {
              error_message: `Please enter a valid ${displayName}`,
              param_id: "1",
              param_label: opt && opt["ad1_d_name"],
              param_name: opt && opt["ad1_name"],
            }
          : null;
      var paramData2 =
        opt && opt["ad2_d_name"] && opt["ad2_d_name"] !== "null"
          ? {
              error_message: `Please enter a valid ${opt["ad2_d_name"]}`,
              param_id: "1",
              param_label: opt["ad2_d_name"],
              param_name: opt["ad2_d_name"],
            }
          : null;
      var paramData3 =
        opt && opt["ad3_d_name"] && opt["ad3_d_name"] !== "null"
          ? {
              error_message: `Please enter a valid ${opt["ad3_d_name"]}`,
              param_id: "1",
              param_label: opt["ad3_d_name"],
              param_name: opt["ad3_d_name"],
            }
          : null;

      var data =
        paramData !== null &&
        paramData1 !== null &&
        paramData2 !== null &&
        paramData2 !== null
          ? [paramData, paramData1, paramData2, paramData3]
          : paramData !== null && paramData1 !== null && paramData2 !== null
          ? [paramData, paramData1, paramData2]
          : paramData !== null && paramData1 !== null
          ? [paramData, paramData1]
          : [paramData];

      setParam(data);
      setError("");
    } else if (operator && operator !== " ") {
      get_operator_param();
    }
  }, [operator, title, list]);

  const fetchbill = async () => {
    if (
      operator &&
      operator !== " " &&
      number &&
      number !== "" &&
      latitude &&
      longitude
    ) {
      setfetching(true);
      const opt = list.filter((opt) => opt["name"] === operator)[0];
      const operatorId = opt["id"];
      const res = await bbps_request.fetch_bill(
        title,
        operatorId,
        operator,
        number,
        number2,
        number3,
        number4,
        latitude,
        longitude,
        param && param.length > 1 ? param[1]["param_label"] : "",
        param && param.length > 2 ? param[2]["param_label"] : "",
        param && param.length > 3 ? param[3]["param_label"] : ""
      );
      setfetching(false);
      console.log(res);

      if (res && res.status === 1) {
        navigate("/portal/bbps/confirm/bill", {
          state: {
            billdetails: res,
            param: param,
            operator: operator,
            operatorId: operatorId,
            category: title,
            number2: number2,
            number3: number3,
            number4: number4,
            latitude: latitude,
            longitude: longitude,
          },
        });
      } else {
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message(
          res.message === "NA" ? "Unable to fecth bill" : res.message
        );
      }
    } else {
      setFormError(["operator", "number"]);
    }
  };

  return isloading ? (
    <Box py={4} sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box p={2}>
      <Typography mb={2} sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
      {error && error.length > 0 && error !== "" ? (
        <Box p={2}>
          <Typography
            mb={2}
            color="error"
            sx={{ fontSize: "16px", fontWeight: 400 }}
          >
            {error}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{ maxWidth: "600px", width: "100%" }}
          component={"form"}
          onSubmit={() => {}}
        >
          <Box>
            <FormControl fullWidth>
              <Select
                id="standard-operator-select"
                value={operator}
                error={
                  formError.indexOf("operator") >= 0 ||
                  formError.indexOf("error") >= 0
                }
                input={
                  <OutlinedInput
                    placeholder="Type"
                    onChange={(e) => setOperator(e.target.value)}
                    sx={{
                      "& fieldset": {
                        borderRadius: "16px",
                        borderColor:
                          formError.indexOf("operator") >= 0
                            ? "error"
                            : "#e9e9e9 !important",
                        borderWidth: "1px",
                      },
                    }}
                  />
                }
              >
                <MenuItem value=" ">Select Operator</MenuItem>
                {list &&
                  list.map((opt, index) => (
                    <MenuItem key={"bbps_opt" + index} value={opt["name"]}>
                      {opt["name"]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {operator && operator !== " " && (
            <Box mt={2}>
              {param && param.length > 0 && (
                <Box>
                  <StandardStyledTextfield
                    error={
                      formError.indexOf("number") >= 0 ||
                      formError.indexOf("error") >= 0
                    }
                    fullWidth
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder={param[0]["param_label"]}
                  />
                </Box>
              )}
              {param && param.length > 1 && (
                <Box mt={2}>
                  <StandardStyledTextfield
                    error={
                      formError.indexOf("number2") >= 0 ||
                      formError.indexOf("error") >= 0
                    }
                    value={number2}
                    onChange={(e) => setNumber2(e.target.value)}
                    fullWidth
                    placeholder={param[1]["param_label"]}
                  />
                </Box>
              )}
              {param && param.length > 2 && (
                <Box mt={2}>
                  <StandardStyledTextfield
                    error={
                      formError.indexOf("number3") >= 0 ||
                      formError.indexOf("error") >= 0
                    }
                    value={number3}
                    onChange={(e) => setNumber3(e.target.value)}
                    fullWidth
                    placeholder={param[2]["param_label"]}
                  />
                </Box>
              )}
              {param && param.length > 3 && (
                <Box mt={2}>
                  <StandardStyledTextfield
                    error={
                      formError.indexOf("number4") >= 0 ||
                      formError.indexOf("error") >= 0
                    }
                    value={number4}
                    onChange={(e) => setNumber4(e.target.value)}
                    fullWidth
                    placeholder={param[3]["param_label"]}
                  />
                </Box>
              )}
            </Box>
          )}
          {operator && operator !== " " && (
            <Box mt={2}>
              {fetching ? (
                <CircularProgress />
              ) : (
                <StandardStyledButton
                  variant="outlined"
                  customfontcolor={props.appData.buttonColor}
                  customcolor={props.appData.buttonFontColor}
                  p={"15px 16px"}
                  onClick={fetchbill}
                >
                  Fetch Bill
                </StandardStyledButton>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
