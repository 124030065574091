import _utlis from "../utils/netwk_utils";

const getRecentRequestList = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/fund/request/history"
        : "/api/fund/request/history";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const raise = async (data) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/fund/manual_payment_request"
        : "/api/fund/manual_payment_request";

    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const payment_request = {
  raise,
  getRecentRequestList,
};

export default payment_request;
