import _utlis from "../utils/netwk_utils";

const getRecentTicketsList = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/help/tickets/list"
        : "/api/help/tickets/list";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const generateTicket = async (data) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/help/tickets/add"
        : "/api/help/tickets/add";

    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const help_request = {
  generateTicket,
  getRecentTicketsList,
};

export default help_request;
