import React from 'react'
import PremiumLayoutRoute from './PremiumLayoutRoute';
import StandardLayoutRoutes from './StandardLayoutRoutes';


export default function RoutesComponent(props) {

   // console.log(props);

    return (
        props.appData.layout === 'standard' ?
            <StandardLayoutRoutes {...props} />
            :
            <PremiumLayoutRoute {...props} />
    )
}
