import React from 'react'
import { Route, Routes } from "react-router-dom";
import PremiumHomePage from '../layouts/premium/public/PremiumHomePage';


export default function PremiumLayoutRoute(props) {

    return (
        <Routes>
            <Route exact path="/about" element={"about us"} />
            <Route exact path="/users" element={"User"} />
            <Route exact path="/" element={<PremiumHomePage />} />
            <Route exact path="*" element={"User"} />
        </Routes>
    )
}
