import {
  Box,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import StandardTabPanel from "../AddMoney/StandardTabPanel";
import { useNavigate } from "react-router-dom";
import user_request from "../../../../rest_api/user_request";
import StandardOfferTable from "./StandardOfferTable";

const handlerTabs = (index) => {
  return {
    id: `standard-tab-${index}`,
    "aria-controls": `standard-tabpanel-${index}`,
  };
};

export default function StandardOffersPage(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message, user } = props;
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [fsPackageList, setFsPackageList] = React.useState([]);
  const [rechargePackageList, setRechargePackageList] = React.useState([]);
  const [utilityPackageList, setUtilityPackageList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    async function getPackage() {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const res = await user_request.get_package(token);
        console.log(res);
        setLoading(false);
        if (res) {
          if (res.isLoggedIn) {
            setRechargePackageList(
              res.package &&
                res.package.length > 0 &&
                res.package.filter(
                  (p) =>
                    p.service_type === "Prepaid" ||
                    p.service_type === "Postpaid"
                )
            );
            setFsPackageList(
              res.package &&
                res.package.length > 0 &&
                res.package.filter(
                  (p) =>
                    p.operator === "DMT" ||
                    p.operator === "Payout" ||
                    p.operator === "AEPS" ||
                    p.operator === "Micro ATM" ||
                    p.operator === "MPOS" ||
                    p.operator === "Virtual Account" ||
                    p.operator === "Payment Gateway" ||
                    p.operator === "Cash@Pos"
                )
            );
            setUtilityPackageList(
              res.package &&
                res.package.length > 0 &&
                res.package.filter(
                  (p) => p.service_type === "BBPS" || p.operator === "FAStag"
                )
            );
          } else {
            setError(res.message);
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError(res.message);
        }
      } catch (e) {
        setError(e.message);
      }
    }

    getPackage();
  }, [navigate]);

  React.useEffect(() => {
    if (error && error !== "") {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message(error);
    }
  }, [error, open_snackbar, snackbar_message, snackbar_severity]);

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        My Offers
      </Typography>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            pb: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          <Paper sx={{ display: "grid" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="add money tabs"
            >
              <Tab label="Recharge" {...handlerTabs(0)} />
              <Tab label="Financial Services" {...handlerTabs(1)} />
              <Tab label="Utilities" {...handlerTabs(2)} />
            </Tabs>
          </Paper>
          <Box>
            <StandardTabPanel value={value} index={0}>
              {loading ? (
                <CircularProgress />
              ) : (
                <StandardOfferTable rows={rechargePackageList} userType={user.account_type} />
              )}
            </StandardTabPanel>
            <StandardTabPanel value={value} index={1}>
              {loading ? (
                <CircularProgress />
              ) : (
                <StandardOfferTable rows={fsPackageList} userType={user.account_type} />
              )}
            </StandardTabPanel>
            <StandardTabPanel value={value} index={2}>
              {loading ? (
                <CircularProgress />
              ) : (
                <StandardOfferTable rows={utilityPackageList} userType={user.account_type} />
              )}
            </StandardTabPanel>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
