import { Paper, Box, Avatar, IconButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import React, { useEffect, useState } from 'react'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import StandardStyledButton from './StandardStyledButton';
import { useNavigate } from 'react-router-dom';
import user_request from '../../../rest_api/user_request';

export default function StandardNotificationBar(props) {
    const { sx, appname } = props;
    const [notification, setNotification] = useState("");
    const navigate = useNavigate();

    useEffect(() => {

        async function get_notifications() {
            const token = localStorage.getItem("token");
            const res = await user_request.get_notifications(token, 'one');

            if (res) {

                if (res.isLoggedIn) {
                    setNotification(res.notifications.length > 0 ? res.notifications : "")
                } else {
                    setNotification("")
                }
            }
        }

        get_notifications();

    }, [navigate])


    return (
        <Box mb={1} sx={sx}>
            <Paper elevation={0} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '20px', border: '1px solid #f1f1f1' }}>
                <Box >
                    <IconButton>
                        <Avatar sx={{ bgcolor: blue[50] }}><NotificationsActiveOutlinedIcon color='primary' /></Avatar>
                    </IconButton>
                </Box>
                <Box mx={2} sx={{ flexGrow: 1, height: '20px', fontSize: '14px', whiteSpace: {xs: 'break-spaces', sm: 'nowrap'}, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {notification === "" ? `Welcome to ${appname}` : notification[0].message}
                </Box>
                <StandardStyledButton p={'4px 10px'} variant='contained' onClick={() => navigate('/portal/notifications')}>View All</StandardStyledButton>
            </Paper>
        </Box>
    )
}
