import { Box } from '@mui/material'
import React from 'react'
import StandardAppbar from './StandardAppbar'

export default function StandardHomePage(props) {
  return (
    <StandardAppbar {...props} >
      <Box sx={{ width: '100%', display: 'flex' }}>
        <img style={{width: '100%'}} src='/images/banner1.jpg' alt='banner1' />
      </Box>
    </StandardAppbar>
  )
}
