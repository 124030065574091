import {
  Avatar,
  Box,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import { blue } from "@mui/material/colors";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";
import mobile_recharge_request from "../../../../../../rest_api/mobile_recharge_request";

function NullDataError() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography component={"div"} mr={2}>
        Something went wrong. Please go back and try again.{" "}
      </Typography>
      <StandardStyledButton onClick={() => navigate(-1)}>
        Go Back
      </StandardStyledButton>
    </Box>
  );
}


export default function StandardConfirmRechargeScreen(props) {
  const { appData } = props;
  const location = useLocation();

  const [isloading, setIsloading] = React.useState(false);
  const [txn_pin, setTxnPin] = React.useState("");

  const navigate = useNavigate();

  const recharge_data = location.state;

  const transaction_processing = async () => {

    if (txn_pin && txn_pin.length === 4 && recharge_data && recharge_data.amount && parseInt(recharge_data.amount) > 9 && recharge_data.serviceType && recharge_data.circle && recharge_data.operator) {

      const data = { txn_pin: txn_pin, number: recharge_data.number, opt: recharge_data.operator, service_type: recharge_data.serviceType, circle: recharge_data.circle, amount: recharge_data.amount };

      try {
        setIsloading(true);
        const res = await mobile_recharge_request.transaction(data);
        setIsloading(false);
        console.log(res);

        if (res && res.isLoggedIn) {

          if (res.status === 1) {

            navigate('/portal/recharge/receipt', { replace: true, state: res.payload });

          } else {
            props.open_snackbar(true);
            props.snackbar_severity('error');
            props.snackbar_message(res.message);

          }



        } else {

          props.open_snackbar(true);
          props.snackbar_severity('error');
          props.snackbar_message(res.message);

          localStorage.clear();
          navigate('/login');

        }


      } catch (e) {
        setIsloading(false);
        props.open_snackbar(true);
        props.snackbar_severity('error');
        props.snackbar_message(e.message);

      }

    } else if ((txn_pin === null || txn_pin === undefined || txn_pin.length !== 4) && recharge_data && recharge_data.amount && parseInt(recharge_data.amount) > 9 && recharge_data.serviceType && recharge_data.circle && recharge_data.operator) {
      props.open_snackbar(true);
      props.snackbar_severity('error');
      props.snackbar_message('Please enter valid transaction PIN');
    } else {
      props.open_snackbar(true);
      props.snackbar_severity('error');
      props.snackbar_message('Something went wrong. Please go back and try again');
    }



  }

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        Confirm Recharge Details
      </Typography>
      <Box mt={1}>
        <Paper
          sx={{
            flexGrow: 1,
            width: "100%",
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${appData !== undefined && appData.cardRadius !== null
              ? appData.cardRadius
              : "6px"
              }`,
          }}
        >
          <Box p={3}>
            {recharge_data !== null &&
              recharge_data !== undefined &&
              recharge_data.number != null ? (
              <Box
                sx={{
                  maxWidth: "1060px",
                  flexWrap: "wrap",
                  flexDirection: { xs: "column", md: "row" },
                  display: "flex",
                  justifyContent: { xs: "center", md: "start" },
                  alignItems: { xs: "space-between", md: "center" },
                }}
              >
                <Box
                  sx={{
                    py: "5px",
                    px: 1,
                    pr: 2,
                    border: "1px solid #e9e9e9",
                    borderRadius: `${appData !== undefined && appData.cardRadius !== null
                      ? appData.cardRadius
                      : "6px"
                      }`,
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    mb: { xs: 2, md: 0 },
                    mr: { xs: 0, md: 5 },
                  }}
                >
                  <Box>
                    <IconButton onClick={() => navigate(-1)}>
                      <Avatar sx={{ border: "1px solid #efefef" }} src={
                        recharge_data.operator === 'VI' ? '/opt_logo/vi.png' : recharge_data.operator === 'Airtel' ? "/opt_logo/airtel.png" : recharge_data.operator === 'Jio' ? "/opt_logo/jio.png" : recharge_data.operator === 'BSNL' ? "/opt_logo/bsnl.png" : recharge_data.operator === 'Airtel Digital TV' ? "/opt_logo/airteldigitaltv.png" : recharge_data.operator === 'Dish TV' ? "/opt_logo/dishTv.png" : recharge_data.operator === 'Sun Direct' ? "/opt_logo/sundirect.png" : recharge_data.operator === 'Tata Sky' ? "/opt_logo/tatasky.png" : recharge_data.operator === 'Videocon D2H' ? "/opt_logo/D2h.png" : "/opt_logo/airteldigitaltv.png"
                      } alt={recharge_data.operator} >
                      </Avatar>
                    </IconButton>
                  </Box>
                  <Box
                    ml={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "700" }}>
                      {recharge_data.number}
                    </Typography>
                    <Typography variant="caption">
                      {recharge_data.serviceType} - {recharge_data.circle}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    py: "7px",
                    pl: 1,
                    pr: 2,
                    border: "1px solid #e9e9e9",
                    borderRadius: `${appData !== undefined && appData.cardRadius !== null
                      ? appData.cardRadius
                      : "6px"
                      }`,
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    mb: { xs: 2, md: 0 },
                    mr: { xs: 0, md: 5 },
                  }}
                >
                  <Box>
                    <Avatar sx={{ bgcolor: blue[400] }}>
                      <CurrencyRupeeOutlinedIcon />
                    </Avatar>
                  </Box>
                  <Box
                    ml={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "700" }}>
                      {recharge_data.amount}/-
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <StandardStyledTextfield
                    type="password"
                    autoComplete="none"
                    value={txn_pin}
                    placeholder="Transaction PIN"
                    onChange={(e) => e.target.value.length <= 4 ? setTxnPin(e.target.value) : {}}
                  />
                  {isloading ? (
                    <Box sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 1 } }}><CircularProgress /></Box>
                  ) : (
                    <StandardStyledButton
                      sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}
                      variant="contained"
                      customcolor={props.appData.buttonColor}
                      customfontcolor={props.appData.buttonFontColor}
                      p={"15px 16px"}
                      onClick={transaction_processing}
                    >
                      Confirm & Pay
                    </StandardStyledButton>
                  )}
                </Box>
              </Box>
            ) : (
              <NullDataError />
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
