import _utlis from "../utils/netwk_utils";

const get_operators = async (category) => {
  const token = localStorage.getItem("token");

  if (token) {
    if (category && category.length > 2) {
      const url =
        process.env.REACT_APP_NODE_ENV === "development"
          ? "/bbps/operators/list/" + category.replaceAll(" ", "%20")
          : "/api/bbps/operators/list/" + category.replaceAll(" ", "%20");

      const res = await _utlis._get(url, { token: token });
      console.log(res);
      return res;
    } else {
      return {
        status: 2,
        message: "Invalid category selected",
        isLoggedIn: true,
      };
    }
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const get_operator_param = async (category, operatorId) => {
  const token = localStorage.getItem("token");

  if (token) {
    const cat = category.replaceAll(" ", "%20");
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/bbps/operator/" + cat + "/" + operatorId
        : "/api/bbps/operator/" + cat + "/" + operatorId;

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const fetch_bill = async (
  category,
  operatorId,
  operator,
  billNumber,
  optNumber,
  optNumber2,
  optNumber3,
  latitude,
  longitude,
  ad1_name,
  ad2_name,
  ad3_name
) => {
  const token = localStorage.getItem("token");

  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/bbps/bill/fetch"
        : "/api/bbps/bill/fetch";

    const res = await _utlis._post(
      url,
      {
        category: category,
        operatorId: operatorId,
        operator: operator,
        billNumber: billNumber,
        optNumber: optNumber,
        optNumber2: optNumber2,
        optNumber3: optNumber3,
        latitude: latitude,
        longitude: longitude,
        ad1_name: ad1_name,
        ad2_name: ad2_name,
        ad3_name: ad3_name
      },
      { token: token }
    );

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

//Whitelist funding account
const transaction = async (data) => {
  const token = localStorage.getItem("token");
  if (token && data) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/bbps/transaction"
        : "/api/bbps/transaction";
    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication failed", isLoggedIn: false };
  }
};

const bbps_request = { get_operators, get_operator_param, fetch_bill, transaction };

export default bbps_request;
