import { SvgIcon } from "@mui/material";
import React from 'react'

const BBPSIcon = (props) => {

    return <SvgIcon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(0.047424, 0, 0, 0.047424, -7.372256, -2.764278)">
            <title>Layer 1</title>
            <path d="M 345.702 435.292 C 363.351 436.728 425.625 435.404 425.625 435.404 C 425.625 435.404 476.631 435.727 475.372 388.883 C 474.744 365.52 462.045 350.863 442.464 346.122 C 418.29 340.268 366.532 342.688 354.635 342.634 C 345.175 342.591 342.535 345.377 341.996 346.128 L 341.996 433.723 C 342.619 434.389 343.741 435.133 345.702 435.292 Z M 352.809 281.712 C 381.376 281.561 406.322 281.756 433.065 281.551 C 451.886 281.407 487.799 288.969 506.473 304.21 C 514.586 310.831 539.845 326.758 544.395 361.608 C 548.594 393.764 544.562 418.519 527.965 439.024 C 508.841 462.651 493.887 470.87 444.664 476.127 C 436.437 477.006 341.879 476.653 341.879 476.653 L 341.875 476.366 L 271.045 476.366 L 271.045 147.362 L 341.996 147.362 L 341.996 279.473 C 343.034 281.048 345.802 281.749 352.809 281.712 Z M 358.121 145.927 C 358.831 145.931 387.264 147.248 435.681 147.152 C 484.098 147.056 505.461 166.69 505.461 166.69 C 505.461 166.69 528.157 178.453 531.662 222.147 C 535.167 265.841 502.473 286.324 502.473 286.324 C 502.473 286.324 447.599 270.504 451.554 269.021 C 455.509 267.538 467.372 249.247 460.452 225.518 C 453.532 201.789 419.915 201.789 419.915 201.789 C 419.915 201.789 364.053 201.788 359.604 202.283 C 355.155 202.778 355.155 199.317 355.155 199.317 L 354.661 151.35 C 354.595 147.237 356.057 145.915 358.121 145.927 Z" />
        </g></SvgIcon>

}


export default BBPSIcon;
