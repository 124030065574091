import { Box } from "@mui/material";
import React from "react";

export default function StandardTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component={"div"}
      role="tabpanel"
      hidden={value !== index}
      id={`standard-tabpanel-${index}`}
      aria-labelledby={`standard-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ display: "grid" }}>{children}</Box>}
    </Box>
  );
}
