import _utlis from "../utils/netwk_utils";

const getAepsBankList = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/aeps/bank/list"
        : "/api/aeps/bank/list";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const aeps_login = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/aeps/login"
        : "/api/aeps/login";

    const res = await _utlis._get(url, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const aeps_authentication = async (data) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/aeps/authentication"
        : "/api/aeps/authentication";

    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "authentication Failed", isLoggedIn: false };
  }
};

const aeps_txn = async (data) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? "/aeps/transaction"
        : "/api/aeps/transaction";

    const res = await _utlis._post(url, data, { token: token });

    return res;
  } else {
    return { status: 2, message: "Authentication Failed", isLoggedIn: false };
  }
};

const aeps_request = {
  aeps_login,
  getAepsBankList,
  aeps_authentication,
  aeps_txn,
};

export default aeps_request;
