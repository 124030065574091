import { AppBar, Toolbar, Box, IconButton, Link, Paper, Typography, Button, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import StandardStyledTextfield from '../StandardCustomComponent/StandardStyledTextfield';
import StandardStyledButton from '../StandardCustomComponent/StandardStyledButton';
import authentication from '../../../rest_api/authentication';


export default function StandardLoginPage(props) {
    const { appData } = props;
    const [loginError, setLoginError] = useState("");
    const [mobile, setMobile] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [otp, setOtp] = useState("");
    const [otp_required, setOtp_required] = useState(false);

    const navigate = useNavigate();

    const submitLoginForm = async (event) => {
        event.preventDefault();

        if (mobile !== null && mobile.length === 10 && password !== null && password.length > 5) {
            setLoading(true);
            const res = await authentication.login(mobile, password)

            //console.log(res);
            setLoading(false);
            if (res && (res.status === 0 || res.status === 1)) {

                //check whether login was successful without otp or not.

                if (res.status === 1 && !res.otp_required && res.is_reg && res.user_type !== 'admin') {
                    setLoginError("");
                    localStorage.setItem("token", res.token);
                    navigate('/portal/dashboard');
                } else if (res.user_type === 'admin') {
                    setLoginError('Invalid mobile number & password combination');
                } else {
                    if (!res.is_reg) {
                        setLoginError(res ? res.message : 'Invalid mobile number & password combination');
                    } else {
                        setToken(res.token);
                        setOtp_required(res.otp_required);
                        setLoginError("");
                    }
                }
            } else {

                setLoginError(res ? res.message : 'Login failed! Network error');

            }

        } else {
            setLoginError("Please enter valid credentials");

        }
    }


    //OTP form

    const submitOTPForm = async (event) => {
        event.preventDefault();

        if (mobile !== null && mobile.length === 10 && otp && otp.length === 6 && token && token.length > 10) {
            setLoading(true);
            const res = await authentication.verify(mobile, token, otp)

            console.log(res);
            setLoading(false);
            
            if (res && (res.status === 0 || res.status === 1)) {

                //check whether login was successful without otp or not.
                if (res.status === 1 && !res.otp_required && res.is_reg && res.user_type !== 'admin') {
                    setLoginError("");
                    localStorage.setItem("token", res.token);
                    navigate('/portal/dashboard');

                } else if (res.user_type === 'admin') {

                    setLoginError('Invalid mobile number & password combination');

                } else {

                    if (!res.is_reg) {
                        setLoginError(res ? res.message : 'Invalid mobile & password combination');
                    } else {
                        setToken(res.token);
                        setOtp_required(res.otp_required);
                        setLoginError("");
                    }

                }
            } else {

                setLoginError(res ? res.message : 'Login failed! Network error');

            }

        } else {
            setLoginError("Please enter valid credentials");

        }
    }

    return (
        <Box sx={{ backgroundColor: { xs: '#ffffff', sm: '#f0f0f0' } }}>
            <AppBar sx={{ boxShadow: { xs: 'none', sm: '0px 0px 6px 0px rgb(0 0 0 / 20%)' }, backgroundColor: appData.appBarColor != null ? appData.appBarColor : 'primary', color: appData.appBarFontColor != null ? appData.appBarFontColor : 'secondary' }} component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => { navigate('/'); }}
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: "center" }}>
                        <Link href="/" underline="none" color={appData.appBarFontColor != null ? appData.appBarFontColor : 'inherit'}>
                            <img height={"24px"} src={appData.logo} alt={appData.title} />
                        </Link>
                    </Box>
                    <Box ml={1} py={1} px={3}></Box>
                </Toolbar>
            </AppBar>

            <Box p={3} sx={{ display: 'flex', height: 'calc(100vh - 48px)', justifyContent: 'center', alignItems: 'center' }}>
                <Paper elevation={0} sx={{ boxShadow: { xs: 'none', sm: '0px 0px 6px 0px rgb(0 0 0 / 10%)' }, maxWidth: '360px', width: '100%', display: 'block', borderRadius: '20px' }}>
                    {!otp_required ? <Box p={3}>
                        <Box sx={{ justifyContent: 'center' }}>
                            <Typography variant='h6' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                                Welcome back
                            </Typography>
                            <Typography variant='body2' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                                Login to continue
                            </Typography>
                        </Box>

                        {/* login form */}
                        <Box py={3} component="form" onSubmit={submitLoginForm}>
                            {loginError !== "" && <Box p={1} sx={{ color: 'red' }}>{loginError}</Box>}
                            <StandardStyledTextfield
                                fullWidth
                                variant="outlined"
                                error={loginError !== ""}
                                sx={{ borderColor: '#f0f0f0', }}
                                value={mobile}
                                onChange={(event) => event.target.value.length <= 10 ? setMobile(event.target.value) : {}}
                                placeholder='Agent Id / Mobile Number' />
                            <Box py={1}></Box>

                            <StandardStyledTextfield
                                fullWidth
                                variant="outlined"
                                error={loginError !== ""}
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                sx={{ borderColor: '#f0f0f0', }}
                                type="password"
                                placeholder='Password' />
                            <Box pt={1}></Box>
                            {
                                isLoading ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box> :
                                    <StandardStyledButton type="submit" customcolor={props.appData.buttonColor} customfontcolor={props.appData.buttonFontColor} fullWidth variant='contained' onClick={submitLoginForm}>Login</StandardStyledButton>
                            }
                            <Box p={1}>
                                <Typography variant='caption'>Having trouble in sign in?</Typography>
                                <Link href="/reset_password" underline="none">
                                    <Typography p={1} variant='caption'>Reset Password</Typography>
                                </Link>
                            </Box>

                        </Box>

                        {/*Privacy policy section*/}

                        <Typography variant='body2' sx={{ fontSize: '11px' }}>By clicking the login button, you agree to our <Link underline="none" href="/privacy_policy">Privacy Policy</Link> & <Link underline="none" href="/terms_condition">Terms and Conditions</Link> of use.</Typography>

                    </Box>
                        : <Box p={3}>

                            <Box sx={{ justifyContent: 'center' }}>
                                <Typography variant='h6' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                                    Verify By OTP
                                </Typography>
                                <Typography variant='body2' sx={{ mt: 1, textAlign: { xs: 'center', sm: 'left' } }}>
                                    OTP has been sent on your registered mobile number <strong>{mobile}</strong> <Button variant='text' size={"small"} onClick={() => setOtp_required(false)}>Change</Button>
                                </Typography>
                            </Box>
                            {/* login form */}
                            <Box py={3} component="form" onSubmit={submitOTPForm}>
                                {loginError !== "" && <Box p={1} sx={{ color: 'red' }}>{loginError}</Box>}
                                <StandardStyledTextfield
                                    fullWidth
                                    variant="outlined"
                                    error={loginError !== ""}
                                    sx={{ borderColor: '#f0f0f0', }}
                                    value={otp}
                                    type="number"
                                    onChange={(event) => event.target.value.length <= 6 ? setOtp(event.target.value) : {}}
                                    placeholder='Enter OTP' />
                                <Box py={1}></Box>

                                {
                                    isLoading ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box> :
                                        <StandardStyledButton type="submit" customcolor={props.appData.buttonColor} customfontcolor={props.appData.buttonFontColor} fullWidth variant='contained' onClick={submitOTPForm}>Submit</StandardStyledButton>
                                }
                            </Box>
                        </Box>
                    }
                </Paper>
            </Box>
        </Box>
    )
}
