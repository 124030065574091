import { Paper, Box } from "@mui/material";
import StandardRetailerDashboard from "./StandardRetailerDashboard";
import StandardDistributorDashboard from "./StandardDistributorDashboard";
import React from "react";
import StandardAPIDashboard from "./StandardAPIDashboard";

export default function StandardDashboard(props) {
  return props.user && props.user.account_type === "RT" ? (
    <StandardRetailerDashboard {...props} />
  ) : props.user.account_type === "DT" ? (
    <StandardDistributorDashboard {...props} />
  ) : props.user.account_type === "API" ? (
    <StandardAPIDashboard {...props} />
  ) : (
    <Paper p={2}>
      <Box p={2}>You are not authorize to use this service</Box>
    </Paper>
  );
}
