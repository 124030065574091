import {
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import fastag_request from "../../../../../../rest_api/fastag_request";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";
import { useNavigate } from "react-router-dom";

export default function StandardFastagPage(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;
  const [isDetailsFetched, setDetailsFetched] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [number, setNumber] = useState("");
  const [vehicaleNumber, setVehicaleNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [txnPin, setTxnPin] = useState("");
  const [operator, setOperator] = useState(" ");
  const [list, setList] = useState([]);
  const [isprocessing, setProcessing] = useState(false);
  const [isloading, setLoading] = useState(true);
  const [error, setError] = useState([]);
  const [service_error, setServiceError] = useState("");
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState("");
  const [longitude, setlongitude] = useState("");

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setlongitude(position.coords.longitude);
      console.log("Longitude is :", position.coords.longitude);
    });

    async function get_operators_list() {
      try {
        const res = await fastag_request.get_fastag_operators();
        
        setLoading(false);
        if (res && !res.isLoggedIn) {
          setServiceError(res.message);
          localStorage.clear();
          navigate("/login");
        } else if (res.status === 1) {
          setList(res.list);
        } else {
          setServiceError(res.message);
        }
      } catch (e) {
        setServiceError(e.message);
      }
    }

    get_operators_list();
  }, [navigate]);

  const fetchCustomerDetails = async () => {
    const operatorList = list.filter((op) => op["name"] === operator)[0];
    const operatorId = operatorList["id"];

    try {
      setProcessing(true);
      const res = await fastag_request.fetch_bill(
        operatorId,
        operator,
        vehicaleNumber,
        number,
        latitude,
        longitude
      );

      setProcessing(false);
      if (res && !res.isLoggedIn) {
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message(res.message);
        localStorage.clear();
        navigate("/login");
      } else if (res.status === 1) {
        setCustomerName(res.consumerName);
        setDetailsFetched(true);
      } else {
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message(res.message);
      }
    } catch (e) {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message(e.message);
    }
  };

  const proceedToPay = async () => {
    const operatorList = list.filter((op) => op["name"] === operator)[0];
    const operatorId = operatorList["id"];

    const data = {
      operatorId: operatorId,
      operator: operator,
      vehicaleNumber: vehicaleNumber,
      number: number,
      latitude: latitude,
      longitude: longitude,
      amount: amount,
      txn_pin: txnPin,
    };

    try {
      setProcessing(true);
      const res = await fastag_request.transaction(data);

      setProcessing(false);
      if (res && !res.isLoggedIn) {
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message(res.message);
        localStorage.clear();
        navigate("/login");
      } else if (res.status === 1 || res.respCode === 'OK') {
        const billdetails = {
          customerName: customerName,
          number: number,
          amount: amount,
          vehicaleNumber: vehicaleNumber,
        };

        navigate("/portal/fastag/receipt", {
          state: {
            res: res,
            billdetails: billdetails,
            category: "FAStag",
            operator: operator,
          },
          replace: true,
        });
      } else {
        open_snackbar(true);
        snackbar_severity("error");
        snackbar_message(res.message);
      }
    } catch (e) {
      open_snackbar(true);
      snackbar_severity("error");
      snackbar_message(e.message);
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        FAStag
      </Typography>
      <Box mt={1} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Paper
          sx={{
            flexGrow: 1,
            my: 1,
            py: 2,
            overflow: "hidden",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${
              appData !== undefined && appData.cardRadius !== null
                ? appData.cardRadius
                : "6px"
            }`,
          }}
        >
          {isloading ? (
            <Box
              px={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : service_error !== "" ? (
            <Box
              px={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography>{service_error}</Typography>
            </Box>
          ) : (
            <Box
              px={3}
              sx={{
                display: "flex",
                maxWidth: "600px",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box width={"100%"}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "600" }}
                  component={"div"}
                  px={1}
                  pt={1}
                >
                  Customer Mobile Number
                </Typography>
                <StandardStyledTextfield
                  placeholder="Mobile Number"
                  type={"number"}
                  value={number}
                  error={error.indexOf("number") >= 0}
                  onChange={(e) =>
                    e.target.value.length <= 10 && setNumber(e.target.value)
                  }
                  fullWidth
                />
              </Box>
              <Box mt={1} width={"100%"}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "600" }}
                  component={"div"}
                  pt={1}
                  px={1}
                >
                  Select Bank
                </Typography>
                <FormControl fullWidth>
                  <Select
                    id="standard-operator-select"
                    value={operator}
                    error={error.indexOf("operator") >= 0}
                    input={
                      <OutlinedInput
                        placeholder="Type"
                        onChange={(e) => setOperator(e.target.value)}
                        sx={{
                          "& fieldset": {
                            borderRadius: "16px",
                            borderColor:
                              error.indexOf("operator") >= 0
                                ? "error"
                                : "#e9e9e9 !important",
                            borderWidth: "1px",
                          },
                        }}
                      />
                    }
                  >
                    <MenuItem value=" ">Select FAStag Provider</MenuItem>
                    {list.map((l) => (
                      <MenuItem key={l["name"]} value={l["name"]}>
                        {l["name"]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box width={"100%"} mt={1}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "600" }}
                  component={"div"}
                  px={1}
                  pt={1}
                >
                  Customer Vehicle Number
                </Typography>
                <StandardStyledTextfield
                  placeholder="Vehicle Number"
                  fullWidth
                  value={vehicaleNumber}
                  error={error.indexOf("vehicaleNumber") >= 0}
                  onChange={(e) =>
                    e.target.value.length <= 12 &&
                    setVehicaleNumber(e.target.value)
                  }
                />
              </Box>

              {customerName && (
                <Box width={"100%"} mt={1}>
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: "600" }}
                    component={"div"}
                    px={1}
                    pt={1}
                  >
                    Customer Name
                  </Typography>
                  <StandardStyledTextfield
                    disabled
                    placeholder="Customer Name"
                    value={customerName}
                    fullWidth
                  />
                </Box>
              )}

              {isDetailsFetched && (
                <Box width={"100%"} mt={1}>
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: "600" }}
                    component={"div"}
                    px={1}
                    pt={1}
                  >
                    Enter Amount
                  </Typography>
                  <StandardStyledTextfield
                    placeholder="Amount (Mini ₹100)"
                    fullWidth
                    type={"number"}
                    error={error.indexOf("amount") >= 0}
                    value={amount}
                    onChange={(e) =>
                      e.target.value.length <= 10 && setAmount(e.target.value)
                    }
                  />
                </Box>
              )}

              {isDetailsFetched && (
                <Box width={"100%"} mt={1}>
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: "600" }}
                    component={"div"}
                    px={1}
                    pt={1}
                  >
                    Enter Amount
                  </Typography>
                  <StandardStyledTextfield
                    placeholder="Transaction PIN"
                    fullWidth
                    error={error.indexOf("txnpin") >= 0}
                    type={"password"}
                    value={txnPin}
                    onChange={(e) =>
                      e.target.value.length <= 10 && setTxnPin(e.target.value)
                    }
                  />
                </Box>
              )}
              <Box sx={{ mt: 1 }}>
                {isprocessing ? (
                  <CircularProgress />
                ) : isDetailsFetched ? (
                  <>
                    <StandardStyledButton
                      variant="contained"
                      customcolor={props.appData.buttonColor}
                      customfontcolor={props.appData.buttonFontColor}
                      p={"15px 16px"}
                      onClick={() => {
                        if (
                          operator !== "" &&
                          number &&
                          number.length === 10 &&
                          vehicaleNumber &&
                          vehicaleNumber.length >= 10 &&
                          amount &&
                          amount.length >= 2 &&
                          txnPin &&
                          txnPin.length === 4
                        ) {
                          proceedToPay();
                        } else {
                          const errorData = [];

                          operator === "" && errorData.push("operator");
                          (number === "" || number.length !== 10) &&
                            errorData.push("number");
                          (vehicaleNumber === "" ||
                            vehicaleNumber.length < 10) &&
                            errorData.push("vehicaleNumber");
                          (amount === "" || amount.length < 2) &&
                            errorData.push("amount");
                          setError(errorData);
                        }
                      }}
                    >
                      Confirm & Pay
                    </StandardStyledButton>
                    <Box width={10} display={"inline-block"}></Box>
                    <StandardStyledButton
                      variant="outlined"
                      customcolor={props.appData.buttonFontColor}
                      customfontcolor={props.appData.buttonColor}
                      p={"15px 16px"}
                      onClick={() => {
                        setOperator(" ");
                        setDetailsFetched(false);
                        setNumber("");
                        setVehicaleNumber("");
                        setCustomerName("");
                      }}
                    >
                      Reset
                    </StandardStyledButton>
                  </>
                ) : (
                  <StandardStyledButton
                    variant="outlined"
                    customcolor={props.appData.buttonFontColor}
                    customfontcolor={props.appData.buttonColor}
                    p={"15px 16px"}
                    onClick={() => {
                      if (
                        operator !== "" &&
                        number &&
                        number.length === 10 &&
                        vehicaleNumber &&
                        vehicaleNumber.length >= 10
                      ) {
                        fetchCustomerDetails();
                      } else {
                        const errorData = [];

                        operator === "" && errorData.push("operator");
                        (number === "" || number.length !== 10) &&
                          errorData.push("number");
                        (vehicaleNumber === "" || vehicaleNumber.length < 10) &&
                          errorData.push("vehicaleNumber");
                        setError(errorData);
                      }
                    }}
                  >
                    Fetch Details
                  </StandardStyledButton>
                )}{" "}
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
