import {
  Paper,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import dmt_request from "../../../../../../rest_api/dmt_request";
import { useNavigate } from "react-router-dom";
import StandardDmtWalletLogin from "./wallet/StandardDmtWalletLogin";
import StandardWalletDetails from "./wallet/StandardWalletDetails";
import StandardBeneficiaryList from "./wallet/StandardBeneficiaryList";
import StandardAddBeneficiary from "./wallet/StandardAddBeneficiary";

export default function StandardDmtPage(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message } = props;
  const navigate = useNavigate();

  const [isloading, setLoading] = React.useState(false);
  const [walletLoginStatus, setWalletLoginStatus] = React.useState(false);
  const [walletState, setWalletState] = React.useState("login")
  const [reloadBeneList, setReloadBeneList] = React.useState(false);
  const [newBeneComponent, showNewBeneComponent] = React.useState(false);
  const [wallet, setWallet] = React.useState(localStorage.getItem("wallet"));
  const [mobile, setMobile] = React.useState("");
  const [walletDetails, setWalletDetails] = React.useState();

  const [error, setError] = React.useState("");

  const [beneList, setBeneList] = React.useState([]);

  const handleWalletLogout = () => {
    setWallet("");
    localStorage.removeItem("wallet");
  };

  useEffect(() => {

    async function getWalletDetails() {
      if (wallet && wallet.length === 10) {
        try {
          setLoading(true);
          const token = localStorage.getItem("token");
          const res = await dmt_request.getWalletDetails(wallet, token);
          setLoading(false);

          if (res) {

            if (res.isLoggedIn) {

              if (res.status === 1 && res.wallet && res.wallet.status === 1) {

                setWalletLoginStatus(true);
                setWalletDetails({
                  name: res.wallet.name,
                  wallet: wallet,
                  monthly_limit: res.wallet.monthly_limit,
                  available_limit: res.wallet.available_limit,
                  kyc_status: res.wallet.kyc_status
                });

                setBeneList(res.wallet.beneficiaries);


              } else {

                setError(res.message);
                setWalletLoginStatus(false);
                setWalletState("login");
              }
            } else {

              setError("Session Expire");
              setWalletLoginStatus(false);
              setWalletState("login");
            }
          } else {
            setWalletLoginStatus(false);
            setWalletState("login");
            setError("No response");
          }

        } catch (e) {
          setError(e.message);
        }
      } else {
        setWalletLoginStatus(false);
      }
    }

    getWalletDetails();
  }, [wallet, navigate, reloadBeneList]);

  useEffect(() => {

    if (error && error !== "") {

      open_snackbar(true);
      snackbar_message(error);
      snackbar_severity("error");

      if (error === 'Session Expire') {

        localStorage.clear();
        navigate('/');

      }

    }

  }, [error, open_snackbar, snackbar_message, snackbar_severity, navigate])

  return (
    <Box>
      <Typography variant="h6" sx={{ color: `${appData.mainHeadingColor}` }}>
        DMT
      </Typography>
      <Box mt={1}>
        <Paper
          sx={{
            flexGrow: 1,
            width: "100%",
            overflow: "hidden",
            mt: 1,
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
            borderRadius: `${appData !== undefined && appData.cardRadius !== null
              ? appData.cardRadius
              : "6px"
              }`,
          }}
        >
          {isloading ? <Box p={5} sx={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></Box> : walletLoginStatus ? (
            <>
              <StandardWalletDetails walletDetails={walletDetails} handleWalletLogout={handleWalletLogout} {...props} />
              {!newBeneComponent ? (
                <StandardBeneficiaryList  walletDetails={walletDetails} showNewBeneComponent={showNewBeneComponent} reloadBeneList={reloadBeneList} setReloadBeneList={setReloadBeneList} {...props} beneList={beneList} />
              ) : (
                <StandardAddBeneficiary showNewBeneComponent={showNewBeneComponent} reloadBeneList={reloadBeneList} setReloadBeneList={setReloadBeneList} {...props} />
              )}
            </>
          ) : (
            <StandardDmtWalletLogin walletState={walletState} setWalletState={setWalletState} mobile={mobile} setMobile={setMobile} setWallet={setWallet} setBeneList={setBeneList} {...props} />
          )}
        </Paper>
      </Box>
    </Box>
  );
}
