import FingerprintOutlined from "@mui/icons-material/FingerprintOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import { useNavigate } from "react-router-dom";
import aeps_request from "./../../../../../../rest_api/aeps_request";
import axios from "axios";
import XMLParser from "react-xml-parser";
import StandardAepsAgentAuthForm from "./StandardAepsAgentAuthForm";
import StandardAEPSReceiptBuilder from "./StandardAEPSReceiptBuilder";
import StandardAadhaarPayForm from "./StandardAadhaarPayForm";

const performDeviceAction = async (action, port, uri, data) => {
  console.log(port);

  var res = await axios({
    method: action,
    url: "http://127.0.0.1:" + port + uri,
    data: data,
  });

  // console.log(res.status + " | " + res.statusText);

  // console.log(res.data);
  return res;
};

const checkDeviceStatus = async (port) => {
  console.log(port);

  var res = await axios({
    method: "RDSERVICE",
    url: "http://127.0.0.1:" + port,
  });

  console.log(res.status + " | " + res.statusText);

  console.log(res.data);
  var p = port;

  // console.log(xml.attributes);
  // console.log(xml.children);

  if (res === null || res.status !== 200 || res.data === null) {
    for (p = 11100; p < 11120; p++) {
      res = await axios({
        method: "RDSERVICE",
        url: "http://127.0.0.1:" + p,
      });
      console.log("res received " + JSON.stringify(res.data));
      console.log(res.status + " | " + res.statusText);

      if (res !== null && res.status === 200 && res.data !== null) {
        break;
      }
    }
  }

  var xml = new XMLParser().parseFromString(res.data);
  xml.port = port;
  return xml;
};

export default function StandardAadhaarPayPage(props) {
  const { appData, open_snackbar, snackbar_severity, snackbar_message, user } =
    props;
  const [serviceType, setServiceType] = useState("Aadhaar Pay");
  const [txnPayload, setTxnPayload] = useState("");
  const [error, setError] = useState("");
  const [isRunning, setRunning] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [aeps_twofactorauth, setAeps_twofactorauth] = useState(false);
  const [is_aadhar_required, setIs_aadhar_required] = useState(true);
  const [AgentAadhaarNo, setAgentAadhaarNo] = useState("");
  const [auth_session_id, setAuth_session_id] = useState("");
  const [aadhaarNo, setAadhaarNo] = useState("");
  const [CustomerMobile, setCustomerMobile] = useState("");
  const [bankName, setbankName] = useState("State Bank of India");
  const [amount, setAmount] = useState("");
  const [isDeviceReady, setDeviceStatus] = useState(false);
  const [is_provider_loading, setProviderLoading] = useState(true);
  const [latitude, setLatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setlongitude(position.coords.longitude);
      console.log("Longitude is :", position.coords.longitude);
      console.log("latitude is :", position.coords.latitude);
    });

    async function aeps_login() {
      setRunning(true);
      try {
        const res = await aeps_request.aeps_login();

        if (res) {
          if (res.isLoggedIn) {
            if (res.status === 1) {
              if (res.response.status === 1) {
                if (res.response.type === "native") {
                  console.log(res.response.type);
                  setAeps_twofactorauth(res.response.aeps_twofactorauth);
                  setAuth_session_id(res.response.auth_session_id);
                  setAgentAadhaarNo(res.response.agent_aadhaar_no);
                  setIs_aadhar_required(res.response.is_aadhar_required);
                  setProviderLoading(false);

                  getDeviceReady();
                } else if (res.response.type === "redirect") {
                  setError(
                    "Unknown provider or incorrect Aadhaar Pay service provider details."
                  );
                } else {
                  setError(
                    "Something went wrong. Unknown Aadhaar Pay service type."
                  );
                }
              } else {
                setError(res.message);
              }
            } else {
              setError(res.message);
            }
          } else {
            localStorage.clear();
            navigate("/login");
          }
        } else {
          setError("No response from the server");
        }
      } catch (e) {
        setError(e.message);
      }
    }

    !isRunning && aeps_login();
  }, [navigate, appData, isRunning]);

  const getDeviceReady = async () => {
    try {
      var port =
        localStorage.getItem("rd_port") &&
        localStorage.getItem("rd_port") !== ""
          ? localStorage.getItem("rd_port")
          : "11100"; //11120

      var deviceStatus = await checkDeviceStatus(port);

      localStorage.setItem("rd_port", deviceStatus.port);

      console.log("res received1 " + JSON.stringify(deviceStatus));
      console.log("RD Service: " + deviceStatus.attributes.status);
      setDeviceStatus(deviceStatus.attributes.status === "READY");

      return deviceStatus;
    } catch (e) {
      return {};
    }
  };

  const captureFinger = async (type) => {
    try {
      const deviceStatus = await getDeviceReady();
      setTxnPayload("");
      if (
        longitude === null ||
        longitude === "" ||
        latitude === null ||
        latitude === ""
      ) {
        open_snackbar(false);
        snackbar_severity("error");
        snackbar_message(
          "Please enable location before doing AEPS transactions."
        );
        open_snackbar(true);
      } else if (deviceStatus.attributes.status === "READY") {
        const port = localStorage.getItem("rd_port");
        var res = "";

        if (type === "Info") {
          res = await performDeviceAction(
            deviceStatus.children[0].attributes.id,
            port,
            deviceStatus.children[0].attributes.path,
            ""
          );
        } else {
          var doc =
            process.env.REACT_APP_NODE_ENV === "development"
              ? '<PidOptions><Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="PP"/></PidOptions>'
              : '<PidOptions><Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P"/></PidOptions>';

          res = await performDeviceAction("CAPTURE", port, "/rd/capture", doc);
        }

        const jsonRes = new XMLParser().parseFromString(res.data);

        if (
          jsonRes.children[0].attributes.errInfo !== "Success." &&
          jsonRes.children[0].attributes.errCode !== "0"
        ) {
          open_snackbar(false);
          snackbar_severity("error");
          snackbar_message(jsonRes.children[0].attributes.errInfo);
          open_snackbar(true);
        }

        console.log("Response returned ");

        return res;
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: `${appData.mainHeadingColor}`, mr: 1 }}
        >
          Aadhaar Pay -{" "}
        </Typography>{" "}
        {isDeviceReady ? (
          <Chip size="small" label={"Device Connected"} color="primary" />
        ) : (
          <Chip label="No device found" size="small" color="warning" />
        )}
      </Box>
      {is_provider_loading && error === "" ? (
        <Box
          mt={5}
          p={3}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignCenter: "center",
          }}
        >
          <CircularProgress />
          <Typography ml={1}>Checking Authentication. Please wait..</Typography>
        </Box>
      ) : is_provider_loading && error && error !== "" ? (
        <Box p={5}>{error}</Box>
      ) : (
        <Box mt={1} sx={{ display: "flex", flexWrap: "wrap" }}>
          <Paper
            sx={{
              flexGrow: 1,
              my: 1,
              py: 2,
              maxWidth: { xs: "100%", md: "48%" },
              overflow: "hidden",
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
              borderRadius: `${
                appData !== undefined && appData.cardRadius !== null
                  ? appData.cardRadius
                  : "6px"
              }`,
            }}
          >
            {!aeps_twofactorauth || is_aadhar_required ? (
              <StandardAepsAgentAuthForm
                {...props}
                captureFinger={captureFinger}
                bankName={bankName}
                setbankName={setbankName}
                setAadhaarNo={setAadhaarNo}
                aadhaarNo={aadhaarNo}
                AgentAadhaarNo={AgentAadhaarNo}
                setAgentAadhaarNo={setAgentAadhaarNo}
                CustomerMobile={CustomerMobile}
                setCustomerMobile={setCustomerMobile}
                isDeviceReady={isDeviceReady}
                setDeviceStatus={setDeviceStatus}
                serviceType={serviceType}
                setServiceType={setServiceType}
                error={error}
                setError={setError}
                isProcessing={isProcessing}
                setProcessing={setProcessing}
                longitude={longitude}
                setlongitude={setlongitude}
                latitude={latitude}
                setLatitude={setLatitude}
                auth_session_id={auth_session_id}
                setAuth_session_id={setAuth_session_id}
                aeps_twofactorauth={aeps_twofactorauth}
                setAeps_twofactorauth={setAeps_twofactorauth}
                is_aadhar_required={is_aadhar_required}
                setIs_aadhar_required={setIs_aadhar_required}
                txnPayload={txnPayload}
                setTxnPayload={setTxnPayload}
              />
            ) : (
              <StandardAadhaarPayForm
                {...props}
                captureFinger={captureFinger}
                bankName={bankName}
                setbankName={setbankName}
                setAadhaarNo={setAadhaarNo}
                aadhaarNo={aadhaarNo}
                AgentAadhaarNo={AgentAadhaarNo}
                setAgentAadhaarNo={setAgentAadhaarNo}
                CustomerMobile={CustomerMobile}
                setCustomerMobile={setCustomerMobile}
                isDeviceReady={isDeviceReady}
                setDeviceStatus={setDeviceStatus}
                serviceType={serviceType}
                error={error}
                setError={setError}
                amount={amount}
                setAmount={setAmount}
                isProcessing={isProcessing}
                setProcessing={setProcessing}
                longitude={longitude}
                setlongitude={setlongitude}
                latitude={latitude}
                setLatitude={setLatitude}
                auth_session_id={auth_session_id}
                setAuth_session_id={setAuth_session_id}
                is_aadhar_required={is_aadhar_required}
                setIs_aadhar_required={setIs_aadhar_required}
                aeps_twofactorauth={aeps_twofactorauth}
                setAeps_twofactorauth={setAeps_twofactorauth}
                txnPayload={txnPayload}
                setTxnPayload={setTxnPayload}
              />
            )}
          </Paper>

          <Box
            sx={{ width: "20px", display: { xs: "none", md: "block" } }}
          ></Box>
          {!isDeviceReady ||
          isProcessing ||
          error !== "" ||
          txnPayload !== "" ? (
            <Paper
              sx={{
                maxWidth: { xs: "100%", md: "48%" },
                flexGrow: 1,
                overflow: "hidden",
                mt: 1,
                boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.1)",
                borderRadius: `${
                  appData !== undefined && appData.cardRadius !== null
                    ? appData.cardRadius
                    : "6px"
                }`,
              }}
            >
              {isDeviceReady && (isProcessing || error !== "") ? (
                <Box
                  p={{ xs: 3, sm: 2 }}
                  sx={{
                    mb: { xs: 2, sm: 1 },
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  {isProcessing ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "red",
                      }}
                    >
                      <Typography sx={{ fontWeight: "600" }}>
                        Please scan your finger and wait...
                      </Typography>
                    </Box>
                  ) : error && error !== "" ? (
                    <Typography sx={{ fontWeight: "600", color: "red" }}>
                      Error Message: {error}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              ) : txnPayload !== "" ? (
                <Box>
                  <StandardAEPSReceiptBuilder
                    user={user}
                    payload={txnPayload}
                    keysid={2131}
                    ref={componentRef}
                  />

                  <Box sx={{ display: "flex", p: 1, justifyContent: "end" }}>
                    <StandardStyledButton
                      variant="contained"
                      customcolor={appData.buttonColor}
                      customfontcolor={appData.buttonFontColor}
                      p={"15px 16px"}
                      onClick={() => setTxnPayload("")}
                    >
                      Done
                    </StandardStyledButton>
                    &nbsp;&nbsp;&nbsp;
                    <StandardStyledButton onClick={handlePrint}>
                      Print
                    </StandardStyledButton>
                  </Box>
                </Box>
              ) : isDeviceReady === false ? (
                <Box
                  p={{ xs: 3, sm: 2 }}
                  sx={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <FingerprintOutlined fontSize="large" />
                    <Typography
                      sx={{ fontWeight: "500", wordWrap: "break-word" }}
                    >
                      Device is not ready
                    </Typography>
                    <Typography
                      sx={{ fontWeight: "500", wordWrap: "break-word" }}
                    >
                      OR
                    </Typography>
                    <Typography
                      sx={{ fontWeight: "500", wordWrap: "break-word" }}
                    >
                      Device is not connected
                    </Typography>
                    <IconButton onClick={() => getDeviceReady()}>
                      <RefreshIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Paper>
          ) : (
            ""
          )}
        </Box>
      )}
    </Box>
  );
}
