import _utlis from '../utils/netwk_utils'

const login = async (mobile, password) => {

    if (mobile && password) {
        const url = process.env.REACT_APP_NODE_ENV === 'development' ? '/auth/login' : '/api/auth/login';
        const res = await _utlis._post(url, { mobile: mobile, password: password });

        return res;

    } else {

        return { status: 2, message: 'Please enter valid mobile number & password' };

    }

}


const verify = async (mobile, token, otp) => {

    if (mobile && token && otp) {
        const url = process.env.REACT_APP_NODE_ENV === 'development' ? '/auth/verify' : 'api/auth/verify';
        const res = await _utlis._post(url, { mobile: mobile, token: token, otp: otp });

        return res;

    } else {

        return { status: 2, message: 'Please enter valid OTP details' };

    }

}


const authentication = { login, verify }

export default authentication;